.sidebar {
    display: block;
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    width: 320px;
    padding-top: 0;
    transform: translateX(100%);
    transition: transform 180ms ease-out, visibility 0.001ms 180ms;
    background: white;
    z-index: 1100;
    overflow: hidden;
    will-change: transform;
    visibility: hidden;
    box-shadow: 0 1px 3px 0 rgba(0,0,0,0.2);
}
.sidebar.is-open {
    transform: translateX(0);
    visibility: visible;
    transition: transform 180ms ease-out, visibility 0.001ms;
}
.sidebar__content {
    position: absolute;
    left: 0;
    right: 0;
    top: 60px;
    bottom: 0;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    padding: 20px 30px;
}
.sidebar__header {
    height: 60px;
    position: relative;
    z-index: 500;
    display: flex;
    align-items: center;
    font-size: 17px;
    font-family: var(--font-default-medium);
    font-weight: var(--font-default-medium-weight);
    background: var(--color-primary);
    color: white;
    padding-left: 30px;
}
.sidebar__header-item {
    padding: 20px;
    min-width: 0; /*to allow text-truncate*/
}
.sidebar__header .sidebar__header-item:first-child {
    padding-left: 0;
}

.sidebar-open:after {
    content: "";
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0,0,0,.7);
}