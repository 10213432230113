.lg-backdrop.lg-backdrop.in {
   opacity: 0.8;
}

.lightbox-top-bar {
    background: white;
    height: 80px;
    z-index: 1083;
    position: absolute;
    left: 0;
    right: 0;
    background: white;
    box-shadow: 0 1px 3px 0 rgba(0,0,0,0.2);
}

.lg-outer.lg-outer .lg-img-wrap {
    padding-top: 100px;
    padding-bottom: 120px;
}

/*.lg-actions .lg-next, .lg-actions .lg-prev {*/
    /*margin-top: calc(80 / 2 - 10px);*/
/*}*/

.lg-outer .lg-thumb-item {
    border: none;
    border-radius: 0;
    position: relative;
}

.lg-outer .lg-thumb-outer {
    background: rgba(0,0,0,.5);
    padding-left: 50px;
    padding-right: 50px;
}

.lg-outer .lg-thumb-item:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: black;
    opacity: 0.5;
    transition: opacity 240ms ease;
}

.lg-outer .lg-thumb-item:hover:after {
    opacity: 0.2;
}

.lg-outer .lg-thumb-item.active:after {
    opacity: 0;
}

.lightbox__close {
    position: absolute;
    top: 30px;
    right: 20px;
    font-size: 24px;
}

.lg-actions>.lg-prev:after, .lg-actions>.lg-next:before {
    content: none;
}

.lg-actions .lg-next, .lg-actions .lg-prev {
    background: none;
    color: #fff;
    font-size: 30px;
    text-shadow: 0 1px 3px rgba(0,0,0,0.2);
}

/* affiliate: demi is in iframe, add max-height in case of very high iframes */

.affiliate .lg-outer {
    max-height: 800px;
}
.lg-toolbar .lg-close:after {
    content: "\EA1C";
    font-family: demiIcons;
}

.lg-outer .lg-item {
    /* remove loading gif */
    background-image: none;
}
.lg-icon {
    font-family: inherit;
}