@media screen and (min-width: 768px) {
    .mega-nav--level-3 {
        margin-left: calc(16rem/16);
    }
    .mega-nav__item-content--level-3,  .mega-nav__title-link--level-3 {
        color: var(--color-text-default);
        font-size: 1.2rem;
        line-height: 1.5rem;
        text-transform: none;
        margin-bottom: 1.5rem;
        letter-spacing: 0;
        display: block;
        transition: color 0.2s ease;
    }
    .mega-nav__item-content--level-3.is-active {
        font-weight: 700;
    }
}
@media screen and (min-width: 992px) {
    .mega-nav--level-3 {
        margin-left: calc(16rem/16);
    }
    .mega-nav__item-content--level-3,  .mega-nav__title-link--level-3 {
        font-size: calc(12rem / 16);
        line-height: calc(16/12);
        margin-bottom: calc(12rem/16);
    }
}
@media screen and (min-width: 1400px) {
    .mega-nav__item-content--level-3,  .mega-nav__title-link--level-3 {
        font-size: calc(12rem / 16);
        line-height: calc(16/12);
    }
}
