.image-teaser {
    width: calc(303rem/16);
    position: relative;
    border-radius: calc(6rem/16);
    overflow: hidden;
    display: block;
    &:after {
        content: "";
        position: absolute;
        inset: 0;
        background: linear-gradient(190deg, transparent, black);
        opacity: .3;
    }
    @media screen and (min-width: 768px) {
        width: calc(402rem/16);
        box-shadow: 0 calc(30rem/16) calc(20rem/16) calc(-12rem/16) rgba(black, .4);
    }
}

.image-teaser:hover .image-teaser__icon:after {
    opacity: 1;
}

.image-teaser--full-width {
    width: 100%;
    @media screen and (min-width: 768px) {
        width: 100%;
    }
}

.image-teaser__title {
    position: absolute;
    bottom: calc(24rem/16);
    left: calc(24rem/16);
    font-family: var(--font-default-extra-bold);
    font-size: calc(20rem/16);
    line-height: 1;
    color: var(--color-beige);
    max-width: calc(200rem/16);
    z-index: 1;
    @media screen and (min-width: 768px) {
        font-size: calc(24rem/16);
    }
}

.image-teaser__title--big {
    @media screen and (min-width: 768px) {
        font-size: calc(32rem/16);
        left: calc(48rem/16);
        bottom: calc(86rem/16);
        max-width: 80%;
    }

    @media screen and (max-width: 768px) {
        bottom: 3rem;
    }
}

.image-teaser__subtitle {
    color: var(--color-beige);
    position: absolute;
    left: calc(48rem/16);
    bottom: calc(48rem/16);
    z-index: 1;

    @media screen and (max-width: 768px) {
        bottom: 1.5rem;
        left: 1.5rem;
    }
}

.image-teaser__icon {
    color: white;
    font-size: calc(12rem/16);
    position: absolute;
    bottom: calc(28rem/16);
    right: calc(24rem/16);
    z-index: 1;
    &:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: calc(86rem/16);
        width: calc(86rem/16);
        border-radius: 50%;
        background-color: var(--color-primary);
        z-index: -1;
        opacity: 0;
        transition: opacity .3s ease;
    }
}

.image-teaser__icon--big-padding {
    bottom: calc(56rem/16);
    right: calc(48rem/16);
}