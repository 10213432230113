/*___________ CTA Buttons _________*/
@media screen and (min-width: 768px) and (max-width: 991px){
    .mega-nav__cta-button-wrapper {
        top: auto;
        bottom: 2rem;
        right: 0;
        left: 0;
        display: grid;
        grid-template-rows: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
        grid-gap: 1rem;
        padding: 0 1rem;
    }
    .mega-nav__cta-button {
        padding: 1.5rem;
        width: auto;
        height: auto;
        min-height: 8rem;
        margin: 0;
    }
    .mega-nav__cta-button-title{
        font-size: 1.35rem;
        letter-spacing: calc(1rem/16);
        line-height: 2rem;
    }
    .mega-nav__cta-button-icon {
        font-size: 1.75rem;
    }
}