html {
    -ms-overflow-style: scrollbar; /*prevent scrollbar from overlapping content in IE*/
}
body {
    position: relative;
    background-color: var(--color-body-bg);
}
.body--freezed {
    overflow-y: hidden;
    &:before {
        content: "";
        position: absolute;
        inset: 0;
        backdrop-filter: blur(8px);
        z-index: 10;
    }
}

.body-cca--freezed {
    overflow: hidden;
    &:before {
        content: "";
        position: absolute;
        inset: 0;
        background-color: white;
        z-index: 10;
    }
}

@media screen and (max-width: 767px) {
    body {
        min-width: calc(320rem/16);
        max-width: 100%;
        overflow-x: hidden;
    }
}

//todo change when navi is ready
.main-content--no-hero {
    padding-top: calc(120rem/16);
}

.content-cca--small {
    padding: 0 calc(32rem / 16);

    @media (min-width: 768px) {
        padding: 0 calc(110rem / 16);
    }
}