.detail-intro__infos {
    display: flex;
    gap: calc(10rem/16);
    align-items: center;
    font-size: calc(16rem/16);
    flex-wrap: wrap;
    margin-bottom: calc(10rem/16);
    list-style-type: none;
    padding-left: 0;
    @media screen and (max-width: 767px) {
        flex-direction: column;
        align-items: flex-start;
        row-gap: calc(12rem/16);
    }
    @media screen and (min-width: 768px) {
        gap: calc(10rem/16) calc(24rem/16);
    }
}
.detail-intro__info {
    display: flex;
    align-items: center;
}
.detail-intro__location {
    font-weight: var(--font-weight-extra-bold);
    font-family: var(--font-default-extra-bold);
}
.detail-intro__title {
    font-size: calc(32rem/16);
    color: var(--color-default);
    letter-spacing: 0;
    margin-bottom: calc(12rem/16);
    @media screen and (min-width: 768px) {
        margin-bottom: calc(36rem/16);
        font-size: clamp(calc(28rem/16), 2.5vw, calc(48rem/16));
    }
}
.detail-intro__sub-title {
    font-weight: var(--font-weight-default);
    font-family: var(--font-default);
    font-size: calc(16rem/16);
    margin-bottom: calc(18rem/16);

    @media screen and (min-width: 768px) {
        margin-bottom: calc(48rem/16);
        font-size: 1.5rem;
    }
}

.detail-intro-image {
    position: relative;
}
.detail-intro-image__decorator {
    position: absolute;
    left: 0;
    bottom: 0;
    height: auto;
    top: inherit;
    width: 100%;
}
.detail-intro-image__decorator-img {
    width: calc(150rem/16);
    @media screen and (min-width: 768px) {
        width: calc(266rem/16);
    }
}
.detail-intro-image__decorator-text {
    &:before {
        content: var(--icon-card-inklusive);
        font-family: iconfont;
        color: #fff;
        font-size: calc(16rem/16);
        margin-left: calc(-10rem/16);
        width: calc(36rem/16);

        @media screen and (min-width: 768px) {
            font-size: calc(21rem/16);
            width: calc(52rem/16);
        }
    }

    position: absolute;
    font-size: calc(10rem/16);
    height: calc(24rem/16);
    color: #fff;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    bottom: calc(5rem/16);
    line-height: 1;
    font-family: var(--font-default-bold);
    font-weight: var(--font-weight-bold);
    @media screen and (min-width: 768px) {
        font-size: calc(16rem/16);
        bottom: calc(16rem/16);
    }
}
.detail-intro__wysiwyg .h3 {
    font-size: calc(22rem/16);
}
.detail-intro__wysiwyg h2 {
    font-size: calc(24rem/16);
}
.detail-intro__wysiwyg p {
    margin-bottom: calc(10rem/16);
    @media screen and (min-width: 768px) {
        margin-bottom: calc(30rem/16);
    }
}
.detail-intro__wysiwyg ul {
    margin-bottom: calc(10rem/16);
    @media screen and (min-width: 768px) {
        margin-bottom: calc(30rem/16);
    }
}