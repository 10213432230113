.main-navbar__toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    outline:none;
    background: transparent;
    border: none;
    color: var(--color-text-default);
    position: relative;
    .main-navbar--shadow & {
        color: white;
    }
}
.main-navbar__toggle--cca {
    color: black !important;
}

.main-navbar__toggle--cca.is-open {
    box-shadow: none;
}

.main-navbar__toggle:hover, .main-navbar__toggle:active, .main-navbar__toggle:focus {
    outline:none;
}
.main-navbar__toggle__text, .main-navbar__toggle__text--open {
    font-family: var(--font-default-extra-bold);
    font-weight: var(--font-weight-extra-bold);
    font-size: calc(14rem/16);
    letter-spacing: calc(2rem/16);
    text-transform: uppercase;
}
.main-navbar__toggle__text {
    margin-left: calc(11rem/16);
    @media screen and (max-width: 991px) {
        display: none;
    }
}
.main-navbar__toggle:hover:not(.is-open) .main-navbar__toggle__text {
    opacity:1;
}
.main-navbar__toggle__text--open {
    opacity:0;
    transition: opacity 0.2s ease, visibility 0.0001s ease, transform 0.3s ease;
    visibility:hidden;
    display: none;
}
.is-open .main-navbar__toggle__text--open {
    opacity: 1;
    visibility: visible;
    display: block;
}
.main-navbar__toggle.is-open:hover .main-navbar__toggle__text--open {
    opacity:1;
}
.is-open .main-navbar__toggle__text {
    opacity:0;
    transform: translateY(100%);
    display: none;
}
.main-navbar__toggle {
    z-index: 999;
    opacity: 1;
    transition: opacity 300ms ease;
}
.main-navbar__toggle.is-open {
    opacity: .3;
    column-gap: calc(10rem/16);
    line-height: 1;
    color: var(--color-text-default);
    @media screen and (max-width: 767px) {
        position: absolute;
        top: calc(20rem/16);
        left: calc(20rem/16);
        line-height: 1;
    }
}
.main-navbar__toggle .icon-x {
    display: none;
    @media screen and (max-width: 767px) {
        font-size: calc(12rem/16);
    }
}
.main-navbar__toggle.is-open .icon-burger, .main-navbar__toggle.is-open .icon-mobile-burger {
    display: none !important;
}
.main-navbar__toggle.is-open .icon-x {
    display: inline-block;
}
.main-navbar__search-overlay-toggle {
    outline:none;
    background: transparent;
    border: none;
    color: var(--color-text-default);
    @media screen and (max-width: 767px) {
        display: none;
    }
    .main-navbar--shadow & {
        color: white;
    }
}

.main-navbar__search-overlay-toggle--cca {
    color: black !important;
}

.main-navbar__toggle__icon {
    @media screen and (max-width: 767px) {
        font-size: calc(19rem/16);
    }
}