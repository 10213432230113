.container.container {
    max-width: calc(var(--container-max-width) + var(--container-padding) * 2);
    width: 100%;
}

.container {
    padding-left: var(--container-padding);
    padding-right: var(--container-padding);
}

.container--no-padding-mobile {
    @media screen and(max-width: 767px) {
        padding-left:0;
        padding-right:0;
    }
}

.container--fullwidth {
    max-width: 100vw;
    width: 100%;
    padding: 0;
}

.container--lg {
    margin: 0 auto;
    max-width: calc(var(--container-lg-max-width) + var(--container-padding) * 2);
    width: 100%;
    padding-left: var(--container-padding);
    padding-right: var(--container-padding);
}

.container--md {
    margin: 0 auto;
    max-width: calc(var(--container-md-max-width) + var(--container-padding) * 2);
    width: 100%;
    padding-left: var(--container-padding);
    padding-right: var(--container-padding);
}

.container--sm {
    margin: 0 auto;
    max-width: calc(var(--container-sm-max-width) + var(--container-padding) * 2);
    width: 100%;
    padding-left: var(--container-padding);
    padding-right: var(--container-padding);
}

.container--breadcrumbs {
    padding-left: var(--container-padding--mobile);
    padding-right: var(--container-padding--mobile);
    position: relative;
    z-index: 1;
    @media screen and (min-width: 768px) {
        padding-left: calc(60rem/16);
        padding-right: calc(60rem/16);
    }
}