.separator {
    text-align: center;
    overflow: hidden;
}
.separator__content {
    position: relative;
    display: inline-block;
    line-height: 1;
}
.separator__hr {
    position: absolute;
    left: -1000px;
    right: 100%;
    top: 50%;
    margin: 0;
    transform: translateX(-10px);
}
.separator__hr:last-child {
    right: -1000px;
    left: 100%;
    transform: translateX(10px);
}