.sidebar-alpbachtal-card {
    position: relative;
}

.sidebar-alpbachtal-card__content {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: calc(38rem/16);
}

.sidebar-alpbachtal-card__badge {
    position: absolute;
    top: calc(10rem / 16);
    right: 0;
    text-transform: uppercase;
    color: var(--color-secondary);
    padding: calc(12rem / 16);
    box-shadow: 20px 20px 40px 0 rgba(51, 51, 49, 0.24);
    border-radius: calc(6rem / 16) 0 0 calc(6rem / 16);
    background-color: #fff;
    font-size: calc(12rem / 16);
    letter-spacing: calc(2rem / 16);
    line-height: 1;
    font-weight: var(--font-weight-bold);
    font-family: var(--font-default-bold);
}

.sidebar-alpbachtal-card__card-img {
   max-width: calc(150rem/16);
}