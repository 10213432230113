.detail-grid {
    display: grid;
    gap: calc(24rem/16);
    grid-template-rows: 1fr auto auto;
    @media screen and (min-width: 768px) {
        grid-template-columns: 58.69% 1fr;
        grid-template-rows: 1fr auto auto;
        gap: calc(40rem/16);
    }

    @media screen and (min-width: 1500px) {
        gap: calc(40rem/16) calc(150rem/16);
    }
}

.info-grid{
    @media screen and (max-width: 767px) {
        grid-template-rows: 1fr auto auto;
    }
}
.detail-grid--acco {
    display: grid;
    gap: calc(24rem/16);
    grid-template-rows: 1fr auto auto;
    @media screen and (min-width: 992px) {
        grid-template-columns: 58.69% 1fr;
        grid-template-rows: 1fr auto auto;
        gap: calc(40rem/16);
    }

    @media screen and (min-width: 1500px) {
        gap: calc(40rem/16) clamp(3rem, 8vw, 9.375rem);;
    }
}

.detail-grid__intro-area {
    @media screen and (min-width: 768px) {
        grid-column: 1 / 2;
        grid-row: 1 / 2;
    }
}

.detail-grid__wysiwyg-area {
    grid-row: 3 / 4;
    @media screen and (min-width: 768px) {
        grid-column: 1 / 2;
        grid-row: 3 / 4;
    }
}

.detail-grid__card-area {
    grid-row: 2 / 3;
    @media screen and (min-width: 768px) {
        grid-column: 2 / 3;
        grid-row: 1 / 4;
    }
    @media screen and (max-width: 767px) {
        grid-row: 2 / 3;
    }
}

.info-grid ~.detail-grid__card-area{

    @media screen and (min-width: 768px) {
        grid-column: 2 / 3;
        grid-row: 1 / 4;
    }

    @media screen and (max-width: 767px) {
        grid-row: 3 / 4;
    }

}


.info-grid ~.detail-grid__image-area{

    grid-column:1 / 2;
    grid-row: 1 / 2;

    @media screen and (min-width: 768px) {
        grid-column: 2 / 3;
        grid-row: 1 / 4;
    }

}

.info-grid ~.detail-grid__content-area{

    @media screen and (max-width: 767px) {

        grid-row: 1 / 2;
    }

}

.detail-grid__image-area {
    @media screen and (min-width: 768px) {
        grid-column: 1 / 2;
        grid-row: 2 / 3;
        margin-bottom: calc(72rem/16);
    }
}

.detail-grid__content {

}

.detail-grid__image-areas {
    display: block;
}