:root {
    --datepicker-highlight-bg-color: var(--color-primary);
    --datepicker-highlight-color: #fff;
}

/*Datepicker*/
.ui-datepicker:not(.ui-datepicker-inline) {
    background: #ffffff;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.27);
    z-index: 20000;
}
.ui-datepicker-inline {
    width: auto;
    max-width: 100%;
    margin: auto;
}
.ui-datepicker-inline table {
    font-size: 1em;
}
.ui-datepicker table {
    table-layout: fixed;
}
.ui-datepicker .ui-datepicker-title {
    font-size: 17px;
}
@media (min-width: 768px) {
    .ui-datepicker .ui-datepicker-title {
        text-transform: uppercase;
        font-family: var(--font-default-bold);
        font-weight: var(--font-default-bold-weight);
    }
}
.ui-datepicker th {
    /* todo: color var?*/
    color: #8c8c8c;
    font-weight: normal;
    /*font-family: var(--font-default-medium);*/
    font-size: 13px;
}
.ui-datepicker td {
    padding: 0;
}
.ui-datepicker td {
    border-left: 1px solid white;
    border-bottom: 1px solid white;
}
.ui-datepicker td:first-child {
    border-left: none;
}
.ui-datepicker tr:last-child td {
    border-bottom: none;
}
.ui-datepicker td a,
.ui-datepicker td span {
    position: relative;
    text-align: center;
    background: none;
    padding: 9px 4px;
    /*todo: own color var? */
    color: #333333;
    font-size: 15px;
}
.ui-datepicker .ui-state-active {
    background-color: var(--datepicker-highlight-bg-color);
    border-radius: 50px;
    color: var(--datepicker-highlight-color);
}
.datepicker--range .ui-state-active {
    background-color: transparent;
    color: inherit;
}
.ui-datepicker .is-checkin > a,
.ui-datepicker .is-checkin > span {
    background-color: var(--datepicker-highlight-bg-color);
    border-radius: 20px 0 0 20px;
    color: var(--datepicker-highlight-color);
}
.ui-datepicker .is-in-range > a {
    background-color: color(var(--datepicker-highlight-bg-color) alpha(55%));
    color: var(--datepicker-highlight-color);
}
.ui-datepicker .is-checkout > a {
    background-color: var(--datepicker-highlight-bg-color);
    border-radius: 0 20px 20px 0;
    color: var(--datepicker-highlight-color);
}
.ui-datepicker .is-departure {
    position: relative;
}
.ui-datepicker .is-in-hover-range {
    background-color: color(var(--datepicker-highlight-bg-color) alpha(10%));
}
.ui-datepicker .is-hover-range-end:not(.is-checkin) > a {
    background-color: color(var(--datepicker-highlight-bg-color) alpha(10%));
    border-radius: 0 20px 20px 0;
}
.ui-datepicker .is-available > a {
    background-color: var(--color-success--dark);
    color: #ffffff;
    border-radius: 0;
}
.ui-datepicker .is-available.is-no-arrival > a {
    background-color: var(--color-success--medium-light);
}
.ui-datepicker .is-not-available > a {
    opacity: .35;
}

.ui-datepicker .is-arrival-only > a,
.ui-datepicker .is-departure-only > a {
    position: relative;
    z-index: 0;
    /*background-color: var(--color-danger);*/
    color: #ffffff;
    opacity: 1;
    background: transparent;
}
.ui-datepicker .is-arrival-only > a:after,
.ui-datepicker .is-departure-only > a:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--color-success--medium-light);
    border-radius: 20px 0 0 20px;
    z-index: -1;
}
.ui-datepicker .is-departure-only > a:after {
    border-radius: 0 20px 20px 0;
}

/*.ui-datepicker .ui-state-highlight {*/
/*background: rgba(140,15,0,0.5);*/
/*color: #ffffff;*/
/*}*/
/*.ui-datepicker .ui-state-arrival-date a,*/
/*.ui-datepicker .ui-state-arrival-date span,*/
/*.ui-datepicker .ui-state-hover,*/

.ui-datepicker-calendar .ui-state-disabled {
    opacity: 1;
}
.ui-datepicker-calendar .ui-state-disabled span,
.ui-datepicker-calendar .ui-state-disabled a {
    opacity: .35;
}
.ui-datepicker-other-month.ui-state-disabled {
    visibility: hidden;
}
.ui-datepicker-prev.ui-state-hover,
.ui-datepicker-next.ui-state-hover{
    background: none;
    color: #000000;
}
.ui-datepicker-prev span,
.ui-datepicker-next span{
    display: none!important;
}
.ui-datepicker-next:after {
    display: inline-block;
    speak: none;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
    content: var(--demi-icon-arrow-right);
    font-family: var(--demi-icon-arrow-right-font-family);
}
.ui-datepicker-prev:after{
    display: inline-block;
    speak: none;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: var(--demi-icon-arrow-right);
    font-family: var(--demi-icon-arrow-right-font-family);
    -webkit-transform: scale(-1, 1);
    -ms-transform: scale(-1, 1);
    transform: scale(-1, 1);
}
.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-next {
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    width: auto;
    height: auto;
    line-height: 0;
    color: var(--color-dark-gray);
}
.ui-datepicker .ui-datepicker-next.ui-state-disabled,
.ui-datepicker .ui-datepicker-prev.ui-state-disabled {
    opacity: .35;
}
.ui-datepicker.ui-datepicker-multi {
    width: auto!important;
    padding: 0 10px;
}
.ui-datepicker .ui-datepicker-today {
    position: relative;
}
.ui-datepicker .ui-datepicker-today:after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    border-bottom: 1px solid var(--datepicker-highlight-bg-color);
}
.date-range-selected > .ui-state-active,
.date-range-selected > .ui-state-default {
    background: none;
    background-color: #d4a29b;
}
@media (max-width: 767px) {
    .ui-datepicker-multi-2 .ui-datepicker-group {
        width: auto;
        float: none;
    }
    .ui-datepicker-multi .ui-datepicker-group table {
        width: 100%;
        margin: 0;
    }
    .ui-datepicker-group + .ui-datepicker-group {
        margin-top: 15px;
    }
}

.datepicker--group-loading .ui-datepicker-group {
    position: relative;
    z-index: 0;
}
.datepicker--group-loading .ui-datepicker-group:after {
    content: '';
    position: absolute;
    top: 115px;
    left: 50%;
    margin-left: -10px;
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border-top: 2px solid var(--color-primary);
    border-right: 2px solid transparent;
    animation: circle-spinner .6s linear infinite;
    z-index: -1;
}
.datepicker--group-loading td {
    background: #fff;
}
.datepicker--static .ui-datepicker-calendar a {
    cursor: default;
    border-radius: 4px;
}