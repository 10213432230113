/* popover styling*/
.popover {
  min-width: 280px;
  box-shadow: 20px 20px 40px 0 rgb(51 51 49 / 24%);
  border-radius: calc(4rem/16);
  border: none;
}
.popover.popover--md {
  min-width: 350px;
}
.popover-body {
  padding: 0px;
}