.center-container {
    text-align: center;
    font-size: 0;
}
.center-container:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}
.center-container__item {
    display: inline-block;
    vertical-align: middle;
    max-height: 100%;
    max-width: 100%;
}
.center-container__item-wrapper {
    display: inline-block;
    position: relative;
    max-height: 100%;
}