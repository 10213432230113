
.accordion-button{
    padding: calc(16rem/16) calc(16rem/16) calc(16rem/16) calc(58rem/16);
    min-height: calc(72rem/16);
    background-color: #fff;
    display: flex;
    align-items: center;
    position: relative;
}

.accordion-button:after{
    content: "+";
    font-size: calc(32rem/16);
    background-image: none;
    color: var(--color-primary);
    position: absolute;
    left: calc(30rem/16);
    top: calc(10rem/16);
    transform-origin: center;
    z-index: 2;
}

.accordion-button:not(.collapsed)::after {
    background-image: none;
    content: "-";
    font-size: calc(36rem/16);
    top: calc(6rem/16);
    color: #fff;
    transform: none;
}


.accordion-button:not(.collapsed)::before {
    background-color: var(--color-primary);
}

.accordion-button:before{
    content: "";
    background-color: transparent;
    position: absolute;
    transform: translateY(-50%);
    top: calc(36rem/16);
    left: calc(-10rem/16);
    width: calc(86rem/16);
    height: calc(86rem/16);
    border-radius: calc(53rem/16);
    transition: background-color 0.2s ease-in;
    overflow: hidden;
    z-index: 1;
}

.accordion-item{
    border: none;
    margin-bottom: calc(6rem/16);
    background-color: transparent;
}

.accordion-item:hover .accordion-button:after{
    color: #fff;
}
.accordion-item:hover .accordion-button:before{
    background-color: var(--color-primary);
    transition: background-color 0.2s ease-in;
}

.accordion-item + .accordion-item {
    margin-top: calc(10rem/16);
}

.accordion-header{
    border-radius: calc(24rem/16);
    overflow: hidden;
}

.accordion-header__title{
    z-index: 3;
    font-family: var(--font-default-bold);
}

.accordion-body{
    padding: calc(12rem/16) 0;
    @media screen and (min-width: 768px){
        padding: calc(48rem/16);
    }
}

.accordion-body__headline{
    @media screen and (min-width: 768px){
        margin-bottom: calc(24rem/16);
    }
}

.accordion-body__content{
    margin-bottom: calc(12rem/16);
    @media screen and (min-width: 768px){
        margin-bottom: calc(26rem/16);
    }
}


