@media screen and (min-width: 768px) {
    .mega-nav--level-1 {
        color: var(--color-text-muted);
        z-index: 10;
        position: absolute;
        right: auto;
        visibility: hidden;
        opacity: 0;
        min-width: 20rem;
        width: auto;
        max-width: 50%;
        left: 50%;
        top:0;
        height: 100vh;
        transform: translateX(-50px);
        transition: transform 300ms ease, visibility 0.001ms 300ms, opacity 300ms ease;
        padding: calc(60rem/16) calc(30rem/16);
        background-color: rgba(254,250,241, .9);
    }
    .mega-nav__item-content--level-1:hover .mega-nav__collapse-icon {
        opacity: 1;
    }
    .mega-nav__list--level-1 {
        display: block;
        overflow-y: auto;
        overflow-x: hidden;
        max-height: 100%;
        min-height: 50vh;
    }
    .mega-nav__item.is-open > .mega-nav--level-1,
    .mega-nav__item.is-open--only-desktop > .mega-nav--level-1 {
        opacity: 1;
        visibility: visible;
        transform: translateX(0);
        transition: transform 300ms ease, visibility 300ms 0.001ms, opacity 300ms ease;
    }
    .mega-nav--level-1__go-back {
        display:none;
    }
    .mega-nav__title-arrow {
        font-size: calc(12rem/16);
        margin-left: calc(12rem/16);
    }
    .mega-nav__title--level-1 {
        width: 100%;
        display: flex;
        flex: 0 0 100%;
        font-family: var(--font-default-bold);
        color: var(--color-text-default);
        font-size: calc(22rem/16);
        margin-bottom: calc(5rem/16);
    }
    .mega-nav__title-link--level-1,
    .mega-nav__item-content--level-1 {
        display: block;
    }
    .mega-nav__title-link--level-1 {
        font-family: var(--font-default-extra-bold);
        font-weight: 800;
        font-size: calc(18rem/16);
        line-height: calc(24rem/16);
        margin-bottom: calc(52rem/16);
        transition: transform 300ms ease;
    }
    .mega-nav__item-content--level-1 {
        display: flex;
        align-items: center;
        font-size: 1.25rem;
        line-height: calc(20rem/16);
        padding: 1rem 0;
        background-color: transparent;
        border: none;
        box-shadow: none;
        font-family: var(--font-default-extra-bold);
        color: var(--color-text-default);
        font-weight: 800;
        transition: transform 300ms ease;
    }
    .mega-nav__item--level-1 {
        display: flex;
        flex-grow: 1;
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
    }
    .mega-nav__title-link--level-1:hover,
    .mega-nav__title-link--level-1:focus,
    .mega-nav__title-link--level-1:active,
    .mega-nav__item-content--level-1:hover,
    .mega-nav__item-content--level-1:focus,
    .mega-nav__item-content--level-1:active {
        color: var(--color-text-default);
        outline:none;
        transform: translateX(calc(4rem/16));
    }
}
@media screen and (min-width: 992px) {
    .mega-nav--level-1 {
        left: calc(320rem/16);
        padding: calc(60rem/16) calc(70rem/16);
    }
    .mega-nav__title-link--level-1 {
        font-size: calc(16rem/16);
    }
    .mega-nav__item-content--level-1 {
        font-size: calc(16rem/16);
        padding: calc(12rem/16) 0;
    }
}@media screen and (min-width: 1200px) {
    .mega-nav--level-1 {
        left: calc(664/1920 * 100%);
        &.mag-nav--has-image-teaser  {
            width: auto;
            max-width: unset;
            right: 0;
        }
    }
}
@media screen and (min-width: 1400px) {
    .mega-nav__title-link--level-1 {
        font-size: calc(20rem/16);
    }
    left: calc(698rem/16);
}
@media screen and (min-width:1700px) {
    .mega-nav--level-1 {
        padding-top: calc(204rem/16);
        padding-left: calc(120rem/16);
        left: calc(664/1920 * 100%);
    }
    .mega-nav__title--level-1 {
        font-size: calc(22rem/16);
    }
}
