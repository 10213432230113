.link {
    font-size: calc(14rem/16);
    line-height: 1;
    letter-spacing: calc(2rem/16);
    text-transform: uppercase;
    color: var(--color-text-default);
    font-family: var(--font-default-extra-bold);
}

.link--with-icon {
    display: flex;
    align-items: center;
    column-gap: calc(12rem/16);
}

.link__icon {
    font-size: calc(10rem/16);
}