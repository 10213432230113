.filter-bar {
    background-color: var(--filter-bar-bg-color);
    overflow: hidden;
}

/* always show filters in filter bar on tablet*/
@media (max-width: 992px) {
    .filter-bar-alternative .filter-block {
        display: block;
    }
}

.filter-button {
    position: relative;
    width: var(--filter-button-width);
    border-right: 1px solid rgba(255, 255, 255, 0.5);
    color: #1D1D1B;
    background-color: var(--filter-bar-bg-color);
    padding: 15px;
    font-size: 13px;
    cursor: pointer;
    flex-shrink: 0;
}

.filter-button--has-filter:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 3px;
    background-color: var(--filter-highlight-border-color);
}

.filter-button__content {
    font-size: 16px;
    position: relative;
    padding-right: 30px;
    opacity: 0.7;
    margin-top: 3px;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
}


.filter-button--has-filter .filter-button__content {
    opacity: 1;
}

.filter-button__icon {
    font-size: 6px;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
}

.filter-button__text {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.filter-bar__more-button {
    margin: auto 15px;
    font-size: 16px;
}

.filter-bar__more-button__icon {
    font-size: 5px;
    vertical-align: middle;
}

.filter-box__content {
    padding: 20px;
}
.filter-box__action {
    box-shadow: inset 0 1px 0 0 #D8D8D8;
    text-align: right;
    font-size: 17px;
    font-family: var(--font-default-medium);
    font-weight: var(--font-default-medium-weight);
}

.filter-box__action-item {
    padding: 20px;
}

.filter-box__action-item:not(:first-child) {
    margin-left: 10px;
}