.offer-box__title {
    font-size: 18px;
}

.offer-box__img {
    min-height: 70px;
    width: 70px;
}

.offer-box__price {
    font-size: 24px;
    line-height: 1;
}