@media screen and (max-width: 767px) {
    .mega-nav {
        display: none;
    }
    .mega-nav__item.is-open > .mega-nav--level-1 {
        display: block;
    }
    .mega-nav {
        margin-bottom: .5rem;
    }
    .mega-nav__item {
        display: block;
        width: 100%;
    }
    .mega-nav__title-link,
    .mega-nav__item-content {
        display: block;
        width: 100%;
        padding: 0 0 calc(36rem/16) 0;
    }
    .mega-nav__item-content {
        display: flex;
        align-items: center;
    }

    /*______________level 0_____________ */
    .mega-nav--level-0 {
        display: flex;
        flex-direction: column;
        //justify-content: space-between;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: var(--color-beige);
        color: #fff;
        margin: 0;
        padding: calc(80rem/16) 0 calc(24rem/16);
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        transition: 120ms ease;
    }
    .mega-nav__list--level-0 {
        padding: 0 calc(36rem/16);
    }
    .mega-nav__item-content--level-0 {
        background-color: transparent;
        font-size: calc(18rem/16);
        line-height: calc(24/18);
        color: var(--color-text-default);
        font-family: var(--font-default-extra-bold);
        font-weight: 800;
        border:none;
        text-align:left;
    }
    .mega-nav__item-content--level-0:focus {
        color: var(--color-text-default);
    }
    .is-open > .mega-nav__item-content--level-0 {
        color: var(--color-primary);
    }
    .mega-nav__collapse-icon--level-0 {
        color: var(--color-text-default);
        font-size: calc(10rem/16);
        margin-left: calc(12rem/16);
    }
    .mega-nav__item--level-0.is-open .mega-nav__collapse-icon--level-0 {
        opacity:1;
    }

    .mega-nav__list--level-0 .main-navbar__item__link {
        color: var(--color-text-default);
        line-height: 1;
        padding: 0;
    }

    .mega-nav__list--level-0 .main-navbar__item__link + .main-navbar__item__link {
        margin-top: calc(36rem/16);
    }

    /*______________level 1_____________ */
    .mega-nav--level-1 {
        position: fixed;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        display: block;
        visibility: hidden;
        background-color: var(--color-beige);
        padding: calc(150rem/16) calc(36rem/16) calc(65rem/16);
        transform: translateX(100%);
        transition: transform 0.3s ease, visibility 0.3s 0.001s;
        overflow-y: auto;
    }
    .is-open > .mega-nav--level-1 {
        visibility: visible;
        transition: transform 0.3s ease, visibility 0.3s;
        transform: translateX(0);
        z-index: 99;
    }
    .mega-nav__title-link--level-1 {
        font-family: var(--font-default-extra-bold);
        color: var(--color-text-default);
        font-size: calc(18rem/16);
        line-height: calc(24/18);
        padding-bottom:calc(48rem/16);
    }
    .mega-nav__title-link--level-1:hover, .mega-nav__title-link--level-1:focus {
        color: var(--color-text-default);
    }
    .mega-nav__list--level-1 {
        display: flex;
        flex-direction: column;
    }
    .mega-nav__item-content--level-1 {
        background-color: transparent;
        color: var(--color-text-default);
        font-size: calc(16rem/16);
        line-height: calc(22/16);
        font-family: var(--font-default-extra-bold);
        font-weight: 800;
        border: none;
        padding-bottom: calc(36rem/16);
    }
    .mega-nav__item-content--level-1:focus, .mega-nav__item-content--level-1:active {
        outline: none;
        color: var(--color-text-default);
    }
    .mega-nav__item-content--level-1.is-active {
        color: var(--color-primary);
    }
    .mega-nav--level-1__go-back {
        text-transform: uppercase;
        background-color: transparent;
        color: var(--color-text-muted);
        opacity: .3;
        border: none;
        font-size: calc(14rem/16);
        letter-spacing: calc(2rem/16);
        font-family:var(--font-default-extra-bold);
        display: flex;
        align-items: center;
        padding: 0;
        margin-bottom: calc(12rem/16);
    }
    .mega-nav--level-1__go-back .icon {
        font-size: calc(9rem/16);
        margin-right: calc(12rem/16);
    }

    /*______________level 2_____________ */
    .mega-nav--level-2 {
        display: block;
        padding-left: calc(24rem/16);
        margin-top: calc(-12rem/16);
        margin-bottom: calc(24rem/16);
    }
    .mega-nav__item-content--level-2 {
        background-color: transparent;
        border: none;
    }
    .mega-nav__title-link--level-2, .mega-nav__item-content--level-2 {
        font-size: calc(16rem/16);
        line-height: calc(22/16);
        color:var(--color-text-default);
        padding-bottom: calc(12rem/16);
        font-family: var(--font-default);
    }
    .mega-nav__item-content--level-2.is-active {
        font-family: var(--font-default-bold);
    }

    /*______________level 3_____________ */
    .mega-nav--level-3 {
        display: block;
        padding-left: calc(16rem/16);
    }
    .mega-nav__item-content--level-3 {
        background-color: transparent;
        border: none;
    }
    .mega-nav__title-link--level-3, .mega-nav__item-content--level-3 {
        font-size: calc(14rem/16);
        line-height: calc(18rem/16);
        color:var(--color-text-muted);
        padding-bottom: calc(10rem/16);
        font-family: var(--font-default);
    }
    .mega-nav__item-content--level-3.is-active {
        font-weight: 700;
    }

    /*___________ CTA Buttons _________*/
    .mega-nav__cta-button-wrapper {
        padding: 0 calc(12rem/16);
        display: grid;
        grid-template-rows: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
        grid-gap: calc(12rem/16);
        padding: 0 calc(12rem/16);
    }
    .mega-nav__cta-button {
        padding: calc(12rem/16);
        width: auto;
        height: auto;
        min-height: calc(76rem/16);
        margin: 0;
    }
    .mega-nav__cta-button--highlight {
        background-color: var(--color-primary);
        color: white;
    }
    .mega-nav__cta-button--emphasize {
        color: var(--color-primary);
    }
    .mega-nav__cta-button-title{
        font-size: calc(14rem/16);
        letter-spacing: calc(1rem/16);
        line-height: calc(20rem/16);
    }

    .mega-nav__item-subtext {
        font-size: calc(14rem/16);
        line-height: calc(19/14);
        letter-spacing: calc(6rem/16);
        margin-top: calc(6rem/16);
    }

    .mega-nav__mobile-search {
        margin: 0 calc(24rem/16) calc(60rem/16);
        color: var(--color-text-muted);
    }
    .mega-nav__mobile-search-input {
        border-radius: calc(53rem/16);
        border: 0;
        box-shadow: 0 0 calc(60rem/16) rgba(black, .24);
        width: 100%;
        padding: calc(12rem/16);
    }
    .mega-nav__mobile-search-icon {
        margin-right: calc(10rem/16);
    }
    .mega-nav__title-arrow {
        font-size: calc(10rem/16);
    }
}
.collapsed .mega-nav__collapse-icon {
    transform: rotate(0deg);
}
.mega-nav__collapse-icon {
    transform: rotate(180deg);
    transition: 0.2s ease;
    font-size: calc(6rem/16);
    margin-left: calc(14rem/16);
    color: var(--color-text-default);
    @media screen and (max-width: 767px) {
        display: block;
        font-size: calc(6rem/16);
    }
}