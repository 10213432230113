.twitter-typeahead {
    width: 100%;
}
.tt-menu {
    padding: 5px 0;
    right: 0;
    background: #ffffff;
    box-shadow: 0 0 1px rgba(0,0,0,.2);
    margin-bottom: 10px;
    max-height: 400px;
    overflow-y: auto;
}

@media screen and (max-width: 767px) {
    .tt-menu {
        max-height: 200px;
    }
}
.tt-suggestion.tt-selectable,
.tt-dataset-heading,
.tt-empty-message {
    padding: 6px 12px;
}
.tt-suggestion:hover {
    cursor: pointer;
}
.tt-cursor,
.tt-suggestion:hover{
    background: var(--color-light-gray);
}
.tt-hint {
    color: #999
}

.tt-dataset-heading {
    font-weight: bold;
    border-bottom: 1px solid var(--color-light-gray);
}

@media (min-width: 768px) {
    .nearby-search-autocomplete {
        width: 210px;
    }
}

@media (min-width: 1199px) {
    .nearby-search-autocomplete {
        width: 250px;
    }
}

@media (min-width: 1300px) {
    .nearby-search-autocomplete {
        width: 320px;
    }
}

.nearby-search-autocomplete .tt-suggestion {
    padding-left: 20px;
}