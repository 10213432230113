/* Add your font-face rules here e.g: */
@font-face {
    font-family: 'nunito-sans-regular';
    src: url('/static/fonts/nunitosans-regular.woff2') format('woff2');
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: 'nunito-sans-bold';
    src: url('/static/fonts/nunitosans-bold.woff2') format('woff2');
    font-weight: 700;
    font-display: swap;
}

@font-face {
    font-family: 'nunito-sans-extrabold';
    src: url('/static/fonts/nunitosans-extrabold.woff2') format('woff2');
    font-weight: 800;
    font-display: swap;
}