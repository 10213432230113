.detail-header--full {
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
    height: 300px;
    position: relative;
}

@media (min-width: 992px) {
    .detail-header--full {
        height: 500px;
    }
}

@media (min-width: 1200px) {
    .detail-header--full {
        height: 540px;
    }
}

.gallery-buttons {
    position: absolute;
    left: 30px;
    bottom: 30px;
}

.gallery-button {
    color: white;
    text-align: center;
    padding: 15px;
    background-color: rgba(255,255,255,0.5);
    margin-right: 20px;
    position: relative;
    z-index: 1;
    backdrop-filter: blur(10px);
    border: none;
    display: inline-flex;
    gap: 10px;
    align-items: center;
    border-radius: calc(6rem/16);
}

.gallery-button:hover {
    color: var(--color-light-gray);
    text-decoration: none;
}

.gallery-button:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    z-index: -1;
    transition: opacity 120ms ease-in-out;
    box-shadow: var(--box-shadow);
}

.gallery-button:hover:after {
    opacity: 1;
}

.gallery-button__icon {
    font-size: 22px;
}

.detail-header {
    display: flex;
    overflow: hidden;
    position: relative;
}

.thumb {
    margin: 0;
    border-right: 1px solid white;
    flex-shrink: 0;
}

.thumb img {
    max-width: 100%;
    height: 300px;
}

@media (min-width: 992px) {
    .thumb img {
        height: 350px;
    }
}

@media (min-width: 1200px) {
    .thumb img {
        height: 400px;
    }
}


/*detail-header--multi-images*/
.detail-header--multi-images {
    position: relative;
    height: 0;
    padding-top: calc(400 / 1900 * 100%);
    overflow: hidden;
}
.detail-header__img-bar {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    background: #000;
}
.detail-header__img-bar-item {
    flex: 1 1 0;
    height: 100%;
    min-width: 0;
    position: relative;
    overflow: hidden;
    background: #000;
    cursor: pointer;
}
.detail-header__img {
    height: 100%;
    min-width: 0;
    background-size: cover;
    background-position: center center;
    overflow: hidden;
    transition: opacity 150ms ease-out, transform 190ms ease-in;
    transform: none;
    transform-origin: center center;
}
.detail-header:hover .detail-header__img {
    opacity: .7;
}
.detail-header .detail-header__img:hover {
    opacity: 1;
    transform: scale(1.05);
}