:root {
    --availability-status-high: var(--color-success);
    --availability-status-medium: var(--color-status-medium);
    --availability-status-low: var(--color-danger);
}
.availability-status--high {
    color: var(--availability-status-high);
}
.availability-status--medium {
    color:  var(--availability-status-medium);
}
.availability-status--low {
    color: var(--availability-status-low);
}