.sorting-bar {
    background: white;
    border-bottom: 1px solid var(--color-gray-border);
    padding: 0 15px;
    font-size: 16px;
    color: var(--color-dark-gray);
}

.sorting-bar-btn {
    position: relative;
    display: inline-block;
    padding: 10px 0;
    margin-left: 12px;
    margin-right: 12px;
    cursor: pointer;
    line-height: 28px;
}

.sorting-bar-btn--has-icon {
    padding-right: 20px;
}

.sorting-bar-btn__icon {
    font-size: 6px;
    position: absolute;
    top: 50%;
    right: 4px;
    transform: translateY(-50%);
    color: var(--color-primary);
}

/*active button*/
input:checked + .sorting-bar-btn__content {
    color: var(--color-primary);
}

input:checked + .sorting-bar-btn__content:after {
    content: "";
    background: var(--color-primary);
    height: 3px;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
}

.sorting-bar__order-icon {
    font-size: 12px;
}

.sorting-order-btn input:checked ~ .sorting-order-btn__content--unchecked {
    display: none;
}

.sorting-order-btn input:not(:checked) ~ .sorting-order-btn__content--checked {
    display: none;
}