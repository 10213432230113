.nav-overlay {
    opacity:0;
    visibility: hidden;
    position: fixed;
    width: 100%;
    height: 100%;
    inset: 0;
    color: var(--color-text-default);
    transform: translateX(-100%);
    transform-origin: left top;
    z-index: 99;
    transition: opacity 0.1s 0.2s, visibility 0.6s 0.0001s, ease-in-out transform 0.3s;
    @media screen and (max-width: 767px) {
        background-color: var(--color-beige);
        top: 0;
        height: 100vh;
        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: calc(50rem/16);
            background-color: var(--color-beige);
            z-index: 1;
        }
    }
    @media screen and (min-width: 768px) {
        &:before {
            content: '';
            position: absolute;
            left: 0;
            z-index: -2;
            height: 100vh;
            width: 50%;
            background: var(--color-beige);
            display: block;
        }
    }
    @media screen and (min-width: 1200px) {
        &:before {
            width: calc(664/1920 * 100%);
        }
    }
}
.nav-overlay.show-overlay {
    opacity:1;
    visibility: visible;
    transform: translateX(0%);
    transition: opacity 0.1s, visibility 0.0001s, ease-in-out transform 0.3s;
}
