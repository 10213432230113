.list-inline--gutter-6 {
    margin-top: -6px;
    margin-right: -3px;
    margin-left: -3px;
    font-size: 0; /*remove font-size*/
}
.list-inline--gutter-6 > .list-inline-item {
    margin-top: 6px;
    margin-right: 6px;
    margin-left: 6px;
    font-size: 1rem;
    max-width: 100%;
    max-width: calc(100% - 6px);
    @media screen and (max-width: 767px) {
        font-size: var(--font-size-default);
    }
}
.list-inline--gutter-3 {
    margin-top: -3px;
    margin-right: -2px;
    margin-left: -1px;
    font-size: 0; /*remove font-size*/
}
.list-inline--gutter-3 > .list-inline-item {
    margin-top: 3px;
    margin-right: 2px;
    margin-left: 1px;
    font-size: 1rem;
    @media screen and (max-width: 767px) {
        font-size: var(--font-size-default);

    }
}

.list-inline--dots > .list-inline-item:after {
    content: '•';
    display: inline-block;
    margin-left: 6px;
}

.list-inline--dots > .list-inline-item:last-child:after {
    display: none;
}