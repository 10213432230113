:root {
    --mega-nav-level-0-item-spacing: calc(16rem / 16);
}

@media (hover: none) {
    .mega-nav__item-content--level-0 {
        display: flex;
    }
}

@media screen and (min-width: 768px) {
    .mega-nav--level-0 {
        display: flex;
        flex: auto;
        height: 100%;
        flex-direction: column;
        justify-content: space-between;
    }
    .mega-nav__list--level-0 {
        display: flex;
        width: 30rem;
        flex-direction: column;
        padding-left: 4rem;
    }
    .mega-nav__item--level-0 {
        display: flex;
        flex-direction: column;
        flex: auto;
        position: static;
    }
    .mega-nav__item--level-0.is-active {
        color: var(--color-text-default);
    }
    .mega-nav__item--level-0 + .mega-nav__item--level-0 {
        margin-top: calc(60rem/16);
    }
    .mega-nav__item-content {
        transition: transform 0.2s ease;
        background-color: transparent;
        border: none;
        box-shadow: none;
        color: var(--color-text-default);
        padding:0;
        line-height:1;
    }
    .mega-nav__item-content--level-0 {
        //opacity:0.2;
        display: flex;
        flex-direction: row;
        align-items: center;
        transform: translateX(0);
        transition: opacity 0.2s ease, transform .2s ease;
        position: relative;
        flex: auto;
        font-size: calc(24rem/16);
        line-height: calc(32/24);
        font-family: var(--font-default-extra-bold);
        font-weight: 800;
        justify-content: flex-start;
    }
    .mega-nav__item-content--level-0:hover,
    .mega-nav__item-content--level-0:focus,
    .mega-nav__item-content--level-0:active,
    .mega-nav__item.is-open > .mega-nav__item-content--level-0,
    .mega-nav__item.is-open--only-desktop > .mega-nav__item-content--level-0 {
        opacity: 1;
        outline:none;
        transition: opacity 0.2s ease, transform .2s ease;
        transform: translateX(calc(24rem/16));
    }
    .mega-nav__collapse-icon--level-0 {
        z-index: -1;
        margin-left: calc(26rem/16);
        font-size: calc(14rem/16);
        &:after {
            content: "";
            position: absolute;
            top: calc(-30rem/16);
            right: calc(-31rem/16);
            width: calc(72rem/16);
            height: calc(72rem/16);
            background-color: var(--color-primary);
            border-radius: 50%;
            z-index: -2;
            opacity: 0;
            transition: opacity .3s ease;
        }
    }
    .mega-nav__item--level-0.is-open .mega-nav__collapse-icon--level-0,
    .mega-nav__item--level-0.is-open--only-desktop .mega-nav__collapse-icon--level-0 {
        opacity: 1;
        color: white;
        margin-left: calc(12rem/16);
        &:after {
            opacity: 1;
        }
    }
}
@media screen and (min-width: 992px) {
    .mega-nav--level-0 {
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
    }
    .mega-nav__item-content--level-0 {
        font-size: calc(16rem/16);
    }
    .mega-nav__list--level-0 {
        width: calc(350rem/16);
        padding-left: 0;
    }
    .mega-nav__collapse-icon--level-0 {
        margin-left: calc(20rem/16);
        font-size: calc(12rem/16);
    }
}
@media screen and (min-width: 1400px) {
    .mega-nav__item-content--level-0 {
        font-size: calc(18rem/16);
        line-height: calc(22rem/16);
    }
    .mega-nav__list--level-0 {
        width: calc(400rem/16);
    }
    .mega-nav__collapse-icon--level-0 {
        margin-left: calc(22rem/16);
        font-size: calc(13rem/16);
    }
}
@media screen and (min-width: 1700px) {
    .mega-nav__item-content--level-0 {
        font-size: calc(20rem / 16);
        line-height: calc(24rem/16);
    }
    .mega-nav__list--level-0 {
        width: calc(400rem/16);
    }
    .mega-nav__collapse-icon--level-0 {
        margin-left: calc(36rem/16);
        font-size: calc(12rem/16);
    }
}