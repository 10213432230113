.step-nav {
    justify-content: center;
    align-items: center;
    display: flex;
    margin: 0 auto;
    max-width: calc(853rem/16);
}
.step-nav__counter {
    height: calc(24rem/16);
    width: calc(24rem/16);
    display: flex;
    justify-content: center;
    align-items:center;
    font-size: calc(14rem/16);
    margin: 0 auto;
    text-align:center;
    background-color: var(--color-secondary);
    border-radius: 50%;
    transition: 0.4s ease border-color, 0.4s ease color, 0.4s ease background-color;
    will-change: border-color, color, background-color;
}
.step-nav__item.active .step-nav__counter {
    animation-delay: 0.2s;
    transition: 0.4s ease border-color, 0.4s ease color, 0.4s ease background-color;
    animation: bounce 2s cubic-bezier(0.280, 0.840, 0.420, 1) forwards;
    border-color: var(--color-secondary);
    background-color: var(--color-secondary);
    color: var(--color-secondary);
}
.step-nav__item.is-previous .step-nav__counter {
    border-color: var(--color-secondary);
    background-color: var(--color-secondary);
    color:#fff;
    font-size: calc(8rem/16);
}
.step-nav__item:not(:last-of-type):after {
    content:'';
    height: calc(4rem/16);
    background-color: rgba(152, 150, 145, 0.1);
    width: 100%;
    display: flex;
    justify-content: center;
    align-self: center;
    transition: 0.2s ease background-color;
    will-change: background-color;
}
.step-nav__item.is-previous:not(:last-of-type):after {
    background-color: var(--color-secondary);
}
.step-nav.has-error .step-nav__item.is-previous:after  {
    background-color: var(--color-danger);
}
.step-nav__text {
    font-size: calc(12rem/16);
    position: absolute;
    left: 0;
    text-align:center;
    width: calc(150rem/16);
    color: var(--color-grey);
    margin-top: calc(51rem/16);
    line-height: 1;
    transform: translateX(-50%);
    text-align: center;
    margin-left: calc(12rem/16);
    @media screen and (max-width: 767px) {
        display: none;
    }
}
.step-nav__item {
    position: relative;
}
.step-nav__legend {
    align-self:center;
    border-top: 1px solid var(--color-dark-grey);
}
.step-nav__item:last-of-type .step-nav__counter {
    color: rgba(152, 150, 145, 0.1);
    background-color:transparent;
    padding: calc(5rem/16);
    font-size: calc(16rem/16);
    width: calc(20rem/16);
    height: calc(20rem/16);
}
.step-nav__item:not(:last-of-type) > div:nth-child(1) { -ms-grid-column: 1; }
.step-nav__item:not(:last-of-type) > div:nth-child(2) { -ms-grid-column: 2; }

.step-nav__item:not(:last-of-type) {
    display: grid;
    grid-template-columns: calc(32rem/16) calc(70rem/16);
    @media screen and (min-width: 768px) {
        grid-template-columns: calc(24rem/16) minmax(calc(150rem/16),calc(260rem/16));
    }
}
.step-nav__item:first-of-type {
    grid-template-columns: calc(32rem/16) calc(70rem/16);
    @media screen and (min-width: 768px) {
        grid-template-columns: calc(24rem/16) minmax(calc(150rem/16),calc(520rem/16));
    }
}

.step-nav__item.active .step-nav__text {
    color: var(--color-default);
    font-family: var(--font-default-extra-bold);
    font-weight: var(--font-weight-extra-bold);
}
.step-nav__item.active:last-of-type .step-nav__counter{
    color: var(--color-secondary);
}
