.prospect-teaser {
    padding: calc(24rem/16);
    border-radius: calc(6rem/16);
    background-color: #fff;
    box-shadow: 0 0 40px 0 rgba(51,51,49,0.24);
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    @media screen and (max-width: 767px) {
        padding: calc(12rem/16);
    }
}
.prospect-teaser__title {
    font-size: calc(16rem/16);
}
.prospect-teaser__body {
    flex: auto;
    padding-top: calc(24rem/16);
}
.prospect-teaser__toggle-btn {
    width: calc(24rem/16);
    height: calc(24rem/16);
    border-radius: calc(4rem/16);
    position: absolute;
    top: calc(10rem/16);
    left: calc(25rem/16);
    border: 0.5px solid #989691;
    background-color: #fff;
    display: flex;
    @media screen and (max-width: 767px) {
        left: calc(10rem/16);
        top: calc(5rem/16);
    }
}
input:checked+ .prospect-teaser__toggle-btn {
    background-color: var(--color-success);
}
.is-active .prospect-teaser {
    border: 1px solid var(--color-success);
}
.prospect-teaser__toggle-btn-icon {
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.prospect-teaser__download{
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    white-space: nowrap;
}

