:root {
    --ribbon-bg-color: var(--color-primary);
    --ribbon-bg-color-dark: var(--color-primary--dark);
    --ribbon-success-bg-color: var(--color-success);
    --ribbon-success-bg-color-dark: var(--color-success--dark);
}
.ribbon {
    background: var(--ribbon-bg-color-dark);
}

.ribbon__item {
    position: relative;
    background: var(--ribbon-bg-color);
    color: white;
    padding: 11px 12px;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    @media (min-width: 768px) {
        padding: 20px 30px;
    }
}

@media screen and (min-width: 768px) {
    .ribbon__item.ribbon__item--date,
    .ribbon__item.ribbon__item--nights {
        padding: 11px 12px;
    }
}

.ribbon__title {
    font-size: 18px;
    font-weight: 500;
}

.ribbon__item--has-flag:before {
    content:"";
    bottom: -15px;
    display:block;
    position:absolute;
    left: 0;
    border-width: 15px 0 0 15px;
    border-style: solid;
    border-color: var(--ribbon-bg-color-dark) transparent transparent transparent;
    z-index:-1;
}
.ribbon__item--flap {
    margin-left: calc(-7rem / 16);
    margin-right: calc(-7rem / 16);

    @media screen and (min-width: 768px){
        margin-left: calc(-10rem / 16);
        margin-right: calc(-10rem / 16);
        font-size: calc(20rem / 16);
    }
}
.ribbon__item--flap:after, .ribbon__item--flap:before {
    content: "";
    position: absolute;
    bottom: 100%;
    width: 0;
    height: 0;
    border-style: solid;
}
.ribbon__item--flap:before {
    left: 0;
    border-width: 0 0 calc(7rem / 16) calc(7rem / 16);
    border-color: transparent transparent var(--ribbon-bg-color-dark);

    @media screen and (min-width: 768px) {
        border-width: 0 0 calc(10rem / 16) calc(10rem / 16);
    }
}
.ribbon__item--flap:after {
    right: 0;
    border-width: calc(7rem / 16) 0 0 calc(7rem / 16);
    border-color: transparent transparent transparent var(--ribbon-bg-color-dark);

    @media screen and (min-width: 768px) {
        border-width: calc(10rem / 16) 0 0 calc(10rem / 16);
    }
}