.cookie-bar {
    z-index: 1060;
    background-color: var(--color-beige);
}

.cookie-bar__text {
    color: var(--color-text-default);
}

.cookie-modal {
    color: var(--color-dark);
    z-index: 1070;
}

.cookie-modal__content {
    border-radius: 0;
    padding: calc(20rem / 16);
    @media screen and(min-width: 768px) {
        padding: calc(30rem / 16);
    }
}

.cookie-modal__header,
.cookie-modal__footer {
    border: none;
    padding: 0;
}

.cookie-modal__body {
    padding: 1rem 0;
}

.cookie-modal__title {
    font-family: var(--font-default-extra-bold);
    text-transform: uppercase;
    font-size: calc(22rem / 16);
    @media screen and(min-width: 768px) {
        font-size: calc(26rem / 16);
    }
}

.cookie-toggle__input:checked + .cookie-toggle__switch-toggle {
    background: var(--color-primary);
}

.cookie-modal__close {
    border: none;
    background: none;
    text-align: left;
    padding: 0;
    position: relative;
    cursor: pointer;

    &:before {
        font-family: iconfont;
        content: var(--icon-x);
        color: var(--color-text-default);
    }
}

.cookie-toggle__label {
    font-size: calc(14rem / 16);
}
