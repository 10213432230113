.prospect-grid {
    display: grid;
    gap: calc(20rem/16);

    @media screen and (min-width: 768px) {
        grid-template-columns: 66.191% 1fr;
        gap: 0 calc(40rem/16);
    }

    @media screen and (min-width: 1500px) {
        gap: 0 calc(150rem/16);
    }
}

.card-body--sticky{
    position: sticky;
    top: 0;
    height: min-content;
}