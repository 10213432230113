@media (min-width: 768px) {
    .additional-service__block {
        margin-top:1.5rem;
        padding-bottom:1.5rem;
        border-bottom:1px solid;
        border-bottom-color: var(--color-gray-border);
    }
    .additional-service__hl {
        margin-bottom:1rem;
        color:var(--color-primary);
    }
    .additional-offer-card--sticky {
        position: sticky;
        top:1rem;
        right:0;
        z-index:50;
    }
}



@media (max-width: 767px) {
    .additional-offer-card--sticky {
        margin:1rem auto;
    }
    .container--additional-service-related {
        border-top:1.5rem solid var(--color-light-gray);
    }
    .container--additional-service-detail {
        background: var(--color-light-gray);
    }
    .additional-offer-card.additional-offer-card,
    .additional-service__block {
        margin-bottom:1.5rem;
        border:0;
    }
    .additional-service__hl {
        font-size:17px;
        margin-bottom:.25rem;
    }
    .additional-offer-card__body,
    .additional-service__body {
        padding: 1rem;
        background:#fff;
        box-shadow: 0 1px 3px 0 rgba(0,0,0,.2);
    }
}