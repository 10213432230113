.btn,
button {
    cursor: pointer;
}

.btn-default {
    color: #333;
    background-color: var(--color-dark-medium-light);
}
.btn-default.active {
    color: #ffffff;
    background-color: var(--color-primary);
}
.btn-link:hover {
    text-decoration: underline;
}
.btn__icon {
    font-size: 16px;
    vertical-align: -.2em;
}
.btn__icon.demi-icon-arrow-right {
    font-size: 0.94em;
    vertical-align: -0.1em;
}
.btn__icon.demi-icon-facebook {
    font-size: 20px;
}
.btn__icon.demi-icon-person {
    vertical-align: -.15em;
}
.btn__icon.demi-icon-cart {
    vertical-align: -.1em;
}
.btn-white {
    background: white;
    color: var(--color-dark-gray);
}
.btn-white:hover,
.btn-white:focus,
.btn-white:active {
    color: black;
}

.icon-btn {
    line-height: 1;
}

.close-btn {
    position: absolute;
    right: 15px;
    top: 15px;
    z-index: 1;
    line-height: 1;
}

.btn-facebook,
.btn-facebook:hover,
.btn-facebook:focus {
    background: #3B5998;
    color: #ffffff;
}
.btn-google,
.btn-google:hover,
.btn-google:focus {
    background: #d34836;
    color: #ffffff;
}

.btn-show-gallery-container {
    position: relative;
}

.btn-show-gallery {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 40px;
    height: 40px;
    background: rgb(255 255 255 / 60%);
    backdrop-filter: blur(20px);
    border-radius: 4px;
    color: var(--color-primary);
    font-size: 16px;
}
.btn-show-gallery__icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
.btn-show-gallery--small {
    width: 30px;
    height: 30px;
    font-size: 16px;
}

.btn-breakable {
    white-space: normal;
}
.btn {
    border-radius: calc(53rem/16);
    letter-spacing: calc(2rem/16);
    padding: calc(16rem/16) calc(23rem/16);
    line-height:1;
    text-transform: uppercase;
    font-size: calc(14rem/16);
    font-weight: var(--font-weight-bold);
}
.btn-outline-dark {
    border: 1px solid #000;
    color: #333;
    background-color:#fff;
}
.btn-sm {
    padding-top: .25rem;
    padding-bottom: .25rem;
    font-size: calc(12rem/16);
    letter-spacing: 1px;
}
.btn-lg {
    padding-top: 20px;
    padding-bottom: 20px;
}
.btn-lg {
    padding-top: 20px;
    padding-bottom: 20px;
}
.btn-xs {
    font-size: 10px;
    letter-spacing: 0.5px;
    padding: calc(10rem/16);
}