.offer-teaser {
    border: 1px solid var(--color-gray-border);
    background: #fff;
}
.offer-teaser .offer-teaser {
    padding: .5rem 1rem;
    border: none;
    margin-bottom: 1rem;
}

ul li:not(:last-child) .offer-teaser {
    border-bottom: 0;
}
