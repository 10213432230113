.language-dropdown {

}
.language-dropdown__btn {
    background-color: transparent;
    text-align: left;
    box-shadow: none;
    border: none;
    display: flex;
    align-items: center;
    padding-left: 0;
    color: var(--color-text-muted);
    font-size: calc(12rem/16);
    .landingpage-nav & {
        color: var(--color-text-default);
    }
    .landingpage-nav.main-navbar--shadow & {
        color: var(--color-beige);
    }
}

.language-dropdown__btn::after {
    content: var(--icon-chevron);
    font-family: iconfont;
    border: none;
    font-size: calc(5rem/16);
    margin-left: calc(6rem/16);
    @media screen and (max-width: 767px) {
        font-size: calc(4rem/16);
    }
}

.language-dropdown__icon {
    margin-right: calc(6rem/16);
}

.language-dropdown--nav-overlay {
    margin-top: calc(72rem/16);
    @media screen and (min-width: 768px) {
        margin-top: calc(108rem/16);
    }
}

.language-dropdown__menu {
    border: none;
    box-shadow: calc(20rem/16) calc(20rem/16) calc(40rem/16) rgba(black, .12);
    font-size: calc(12rem/16);
}