hr {
    border-top-color: var(--color-gray);
}
.hr--bold {
    border-top-width: 5px;
}
.hr--light {
    border-top-color: var(--color-light-gray);
}
.hr--border-gray {
    border-top-color: var(--color-gray-border);
}