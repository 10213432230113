.switch {
    position: relative;
    display: inline-block;
    width: 3rem;
    height: 1.75rem;
    vertical-align: middle;
}
.switch__toggle {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--color-gray);
    transition: .25s;
    border-radius: 2rem;
}
.switch__toggle:before {
    position: absolute;
    content: "";
    height: 1.25rem;
    width: 1.25rem;
    left: .25rem;
    bottom: .25rem;
    background-color: white;
    transition: .25s;
    border-radius: 2rem;
}
input:checked + .switch__toggle {
    background-color: var(--color-primary);
}
input:focus + .switch__toggle {
    box-shadow: 0 0 2px var(--color-primary);
}
input:checked + .switch__toggle:before {
    transform: translateX(1.25rem);
}