.form-control {
    border-top: none;
    border-left: none;
    border-right: none;
    font-size: 17px;
    font-family: var(--font-default-medium);
    font-weight: var(--font-default-medium-weight);
}
.form-control:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px white inset !important;
}

/* gray */
.form-control--gray,
.form-control--gray:focus {
    /*todo vars*/
    background-color: #f2f2f2;
    border-top: 1px solid #d8d8d8;
    border-left: 1px solid #d8d8d8;
    border-right: 1px solid #d8d8d8;
}
.form-control--gray:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px #f2f2f2 inset !important;
}

/*Input groups*/
.input-group > .form-control {
    background-color: inherit;
}

/*Gray Input groups*/
.input-group--gray {
    background-color: #f2f2f2;
    border-top: 1px solid #d8d8d8;
    border-left: 1px solid #d8d8d8;
    border-right: 1px solid #d8d8d8;
}

/*input group add ons*/
.input-group-addon {
    border-top: none;
    border-left: none;
    border-right: none;
}
.input-group > .input-group-addon {
    background-color: inherit;
}
.form-control:focus ~ .input-group-addon {
    border-bottom-color: var(--color-primary);
}
.form-control:focus ~ .input-group-addon {
    border-bottom-color: var(--color-primary);
}

.has-error .input-group-addon.input-group-addon {
    border-bottom-color: var(--color-danger);
}
.has-success .input-group-addon.input-group-addon {
    border-bottom-color: var(--color-success);
}

.form-select-wrapper {
    display: flex;
    position: relative;
}
.form-select {
    -webkit-appearance: none;
    -moz-appearance: none;
    cursor: pointer;
    background-image: url(data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3E%3C/svg%3E);
    background-repeat: no-repeat;
    background-position: right 1.25rem center;
    background-size: 16px 12px;

}
.form-control, .form-select {
    font-size: calc(14rem/16);
    padding: calc(11rem/16) calc(20rem/16);
    border-radius: calc(53rem/16);
    width: 100%;
    line-height: 1.357;
    box-shadow: 20px 20px 40px 0 rgb(51 51 49 / 12%);
    background-color: #fff;
    border: none;
    height: 48px;
}
.form-select__select-icon {
    position: absolute;
    font-size: 7px;
    right: 14px;
    top: 18px;
}

/*form errors*/
.form-errors {
    color: var(--color-danger);
}
.form-errors .parsley-errors-list {
    display: block;
    list-style-type: none;
    padding: 0;
    margin: 0;
    text-align: left;
    margin-top: 4px;
    font-size:12px;
}
.form-errors .parsley-errors-list:not(.filled) {
    display: none;
}

.form-label {
    font-weight: var(--font-weight-bold-extra-bold);
    color: #989691;
    text-transform: uppercase;
    letter-spacing: .25rem;
    margin-left: 0.75rem;
    margin-bottom: 0.375rem;
    font-size: .75rem;
    font-family: var(--font-default-bold);
    @media screen and (max-width: 767px) {
        margin-left: 0px;
        letter-spacing: 0.1rem;
    }

}
textarea.form-control {
    height: auto;
    display:block;
    border-radius: 24px;
}

.form-group {
    @media screen and (max-width: 767px) {
        margin-bottom: calc(10rem/16);
    }
}

.nearby-search-form-group__label {
    padding: calc(17rem/16) calc(20rem/16) 0 calc(20rem/16);
    letter-spacing: 0;
    color: var(--color-text-default);
    font-size: calc(10rem/16);
    display: block;
}
.nearby-search-form-group .nearby-search-form-group__input {
    box-shadow: none;
    color: #000;
    margin-left: calc(20rem/16);
    background-color: transparent;
    font-weight: var(--font-weight-bold);
    font-size: calc(12rem/16);
    padding-left: 0;
}
.nearby-search-form-group .nearby-search-form-group__input::placeholder {
    color: #000;
}