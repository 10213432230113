.card-teaser {
    display: flex;
    flex-direction: column;
}
.card-teaser__title {
    //font-size: 22px;
}
.card-teaser__img {
    background-color: var(--color-light-gray);
    flex: auto;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}
.card-teaser__label {
    flex: none;
    /*font-size small*/
    font-size: 84.61538%;
    padding: .25rem;
}
.card-teaser__price {
    font-size: 17px;
}
.card-teaser__show-on-map {
    color: var(--color-primary);
}
.card-teaser__detail-btn {
    position: relative;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    transition: transform 100ms ease-out;
}
.card-teaser__detail-btn .demi-icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    color: var(--color-dark-gray);
    font-size: 16px;
}