:root {
    --teaser-label-bg: var(--color-warning);
}

.teaser {
    line-height: 1.1;
    padding-right: 15px;
}
.teaser__img {
    background-color: var(--color-light-gray);
    flex: auto;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}
@media screen and (min-width: 768px) {
    .teaser__img {
        min-height: calc(160rem/16);
    }
}
.teaser__label {
    flex: none;
    /*font-size small*/
    font-size: 84.61538%;
    padding: .25rem;
}
.teaser__body {
    position: relative;
    min-height: 83px;
}
.teaser__main-body {
    padding-right: 5px;
}
.teaser__arrow {
    color: var(--color-primary);
    position: absolute;
    top: 50%;
    right: -5px;
    transform: translateY(-50%);
    font-size: 16px;
}
.teaser__collapse-arrow {
    color: var(--color-primary);
    position: absolute;
    top: 17px;
    right: -5px;
    font-size: 6px;
    transform: rotate(180deg);
    transition: 120ms ease-out;
}
.teaser__collapse-arrow:hover,
.teaser__collapse-arrow:focus {
    text-decoration: none;
}
.teaser__collapse-arrow.collapsed {
    transform: rotate(0deg);
}
.teaser__price {
    font-size: 17px;
}

.teaser--body-padding .teaser__body {
    padding-top: 10px;
    padding-bottom: 10px;
}

.teaser__detail-btn {
    position: relative;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    transition: transform 100ms ease-out;
    padding: 0;
    background-color: var(--color-secondary); 
    color:#fff;
}

.teaser__detail-btn .demi-icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    color: #fff;
    font-size: 12px;
}
.teaser__detail-btn .demi-icon.demi-icon-chevron-down,
.teaser__detail-btn .demi-icon.demi-icon-chevron-up {
    font-size: 9px;
}
.teaser.collapsed .teaser__detail-btn {
    transform: rotate(180deg);
}

.teaser__arrow.demi-icon.demi-icon-chevron-down,
.teaser__arrow.demi-icon.demi-icon-chevron-up {
    font-size: 8px;
}
.teaser__arrow {
    transition: transform 100ms ease-out;
}
.teaser.collapsed .teaser__arrow {
    transform: rotate(180deg);
}


.teaser__show-on-map {
    font-size:12px;
}

.teaser__show-on-map:hover .media-body {
   text-decoration: underline;
}

@media (min-width: 768px) {
    .teaser {
        background-color: #fff;
        transition: background-color 320ms ease;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .teaser:hover,
    .teaser:focus {
        background-color: #f7f7f7;
    }

    .teaser__acco-title{
        font-size: 22px;
    }

    .teaser__price {
        font-size: 24px;
    }

    .teaser__price-body--big.teaser__price-body--big {
        border-left: 1px solid var(--color-gray-border);
        padding-left: 20px;
    }

    .teaser__label {
        /*position: absolute;*/
        /*top: -10px;*/
        /*left: -5px;*/
        /*padding: 8px 10px;*/
        font-size: 16px;
    }

    /*.teaser__label:before {*/
        /*content:"";*/
        /*bottom: -10px;*/
        /*display:block;*/
        /*position:absolute;*/
        /*left: 0;*/
        /*border-width: 10px 0 0 10px;*/
        /*border-style: solid;*/
        /*border-color: #CC6114 transparent transparent transparent;*/
        /*z-index:-1;*/
    /*}*/
}

.teaser__main-title {
    word-break: break-word;
}