.datepicker-legend {

}

.datepicker-legend__cell {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    vertical-align: -.1em;
}
.datepicker-legend__cell--available {
    background-color: var(--color-success--dark);
}
.datepicker-legend__cell--available-no-arrival {
    background-color: var(--color-success--medium-light);
}
.datepicker-legend__cell--not-available {
    border: 1px dashed var(--color-dark-gray);
}
.datepicker-legend__cell--departure-only {
    background-color: var(--color-success--medium-light);
    border-radius: 0 20px 20px 0;
}