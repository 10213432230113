:root {
    --corridor-bg-gray: var(--color-light-gray);
    --corridor-bg-dark-gray: #E6E6E6;
}

.corridor__head {
    position: relative;
    z-index: 1;
    background-color: #fff;
    border-radius: calc(30rem/16);
    box-shadow: 0 0 40px 0 rgba(51,51,49,0.12);
    overflow: hidden;
}
.corridor-head__item {

}

.corridor__matrix {
    padding: 0 5px;
    display: flex;
    text-align: center;
}
.corridor__left {
    /*todo name   */
    float: none;
    width: 40px;
}
.corridor__body {
    flex: auto;
    min-width: 0;

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        /*IE 11 fix*/
        flex-basis: 100%;
    }
}

.corridor__col {
    width: 80px;
}
.corridor__matrix-head {
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 21px;
}
.corridor__cell {
    display: flex;
    height: 50px;
    justify-content: center;
    align-items: center;
    padding: calc(4rem/16);
    font-weight: 800;
}

.corridor__cell-btn {
    padding: 4px 12px;
    border-radius: 6px;
    border: none;
    height: 100%;
    white-space: normal;
    background: none;
    flex-direction: column;
    background-color: #fff;
    width: 100%;
    letter-spacing: 0;
}
@media screen and (min-width: 768px) {
    .corridor__cell-btn {
        text-align: left;
    }
}
.corridor__cell-btn.is-selected {
    border: 1px solid var(--color-success);
}

@media screen and (min-width: 768px) {
    .corridor__cell,
    .corridor__matrix-head {
        padding: calc(2rem/16);
    }

    .corridor__cell {
        min-height: 58px;
        padding: calc(1rem/16);
    }

    .corridor__matrix {
        padding: 0;
    }

    .corridor__left {
        white-space: nowrap;
        width: auto;
        min-width: 108px;
    }

    .corridor__left .corridor__cell {
        padding-left: 15px;
        padding-right: 15px;
    }

    .corridor__matrix-head {
        height: 48px;
        padding: 6px;
        display: flex;
        justify-content: center;
        align-items:center;
    }

    .corridor__matrix-head-day {
        font-size: 14px;
        line-height: 1;
    }

    .corridor__cell-btn.is-selected {
        color: var(--color-success);
    }

    .corridor__from {
        display: block;
        letter-spacing: 0;
        color: var(--color-dark-gray);
        font-size: calc(10rem/16);
    }

    .is-selected .corridor__from {
        color: var(--color-success);
    }
}

/*.corridor .slick-track {*/
    /*display: inline-block; !*to trigger block formatting context*!*/
/*}*/
