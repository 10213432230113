.detail-gallery__row {
    height: 100%;
}

.detail-gallery__img {
    flex: 1;
    background: var(--color-light-gray);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}