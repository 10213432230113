.content-block + .content-block,
.content-block + iframe + .content-block,
.pimcore_area_content + iframe + .content-block,
.content-block + .pimcore_area_content > .content-block,
.pimcore_area_content + iframe + .pimcore_area_content > .content-block,
.pimcore_area_content + .pimcore_area_content > .content-block,
.pimcore_area_content + .footer-top,
.parallax-area__content > .title-block.content-block {
    margin-top: calc(48rem/16);

    @media screen and (min-width: 768px) {
        margin-top: calc(80rem/16);
    }
    @media screen and (min-width: 1400px) {
        margin-top: calc(80rem/16);
    }
}


.content-block + .content-block--sm,
.content-block + .pimcore_area_content > .content-block--sm,
.content-block--sm + .content-block--sm,
.pimcore_area_content + .pimcore_area_content > .content-block--sm {
    margin-top: calc(50rem/16);
    @media screen and (min-width: 768px) {
        margin-top: calc(80rem/16);
    }
    @media screen and (min-width: 1400px) {
        margin-top: calc(80rem/16);
    }
}

.content-block + .content-block--xs,
.content-block + .pimcore_area_content > .content-block--xs,
.content-block--xs + .content-block--xs,
.pimcore_area_content + .pimcore_area_content > .content-block--xs {
    margin-top: calc(40rem/16);
    @media screen and (min-width: 768px) {
        margin-top: calc(60rem/16);
    }
    @media screen and (min-width: 1400px) {
        margin-top: calc(72rem/16);
    }
}

.content-block + .content-block--xl,
.content-block + .pimcore_area_content > .content-block--xl,
.content-block--xl + .content-block--xl,
.pimcore_area_content + .pimcore_area_content > .content-block--xl {
    margin-top: calc(100rem/16);
    @media screen and (min-width: 768px) {
        margin-top: calc(120rem/16);
    }
    @media screen and (min-width: 1400px) {
        margin-top: calc(180rem/16);
    }
}

/* this should remove the space between this (last) element and the page-footer */
.main-content > .content-block:last-child.content-block--no-footer-margin,
.pimcore_area_content:last-child .content-block.content-block--no-footer-margin {
    margin-bottom: calc(-60rem/16);

    @media screen and (min-width: 768px) {
        margin-bottom: calc(-120rem/16);
    }
}

.content-visibility--auto .content-block {
    contain-intrinsic-size: 1px;
    content-visibility: auto;
}
