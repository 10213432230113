.custom-radio {
    display: flex;
    align-items: baseline;
    cursor: pointer;
}
.custom-radio__input {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}
.custom-radio__box {
    position: relative;
    float: left;
    margin-right: 7px;
    overflow: hidden;
    border: 1px solid #c4c6c7;
    background: #ffffff;
    width: 16px;
    height: 16px;
    margin-top: .1em;
    border-radius: 50%;
}
.custom-radio__box:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
    height: 60%;
    border-radius: 50%;
    background: var(--color-primary);
    visibility: hidden;
}
.custom-radio__input:checked ~ .custom-radio__box:before {
    visibility: visible;
}
.custom-radio__input:checked ~ .custom-radio__text {
    color: var(--color-primary);
}
.custom-radio__input:focus ~ .custom-radio__box {
    border-color: var(--color-primary);
}
.has-error .custom-radio__box {
    border-color: var(--color-danger)
}
.custom-radio__text {
    flex: 1 1 0%;
    line-height: 1;
    min-width: 0;
}
.custom-radio.custom-radio--full-width label{
    display: block;
}

.custom-radio-wrapper label {
    cursor: pointer;
}