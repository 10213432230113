.main-navbar__overlay {
    opacity:0;
    visibility: hidden;
    position: fixed;
    top:0;
    width: 100%;
    left:0;
    right:0;
    z-index: -1;
    transform: translateY(-100%);
    color: var(--color-text-default);
    transform-origin: top;
    transition: opacity 0.25s 0.15s, visibility 0.0001s 0.4s, transform 0.4s;
    background-color: white;
    @media screen and (max-width: 1199px) {
        background: white;
        overflow-y: auto;
        overflow-x: hidden;
        bottom:0;
        height: 100%;
    }
}
.main-navbar__overlay--full-height {
    height: 100vh;
}
.main-navbar__overlay.show-overlay {
    opacity:1;
    visibility: visible;
    transform: translateY(0);
    z-index: 9999;
    transition: opacity 0.2s, visibility 0.0001s, transform 0.4s;
}
.main-navbar__overlay-container {
    max-width: calc(828rem/16);
    padding-top: calc(208/1080 * 100vh);
    margin: 0 auto;
    opacity:0;
    height: 100%;
    transform: translateY(-100%) scale(0.5);
    transition: transform 0.7s 0.1s ease-out, opacity 0.3s 0.1s ease;
    @media screen and (max-width: 767px) {
        max-width: 80vw;
        padding-top: calc(100rem/16);
        padding-bottom: calc(40rem/16);
    }
}
.show-overlay .main-navbar__overlay-container {
    opacity:1;
    transform: translateY(0) scale(1);
}
.main-nav__search-overlay__input {
    height: calc(48rem/16);
    border: none;
    display: block;
    width: 100%;
    color: var(--color-text-default);
    font-size: calc(16rem/16);
    background-color: transparent;
    padding: calc(20rem/16) calc(35rem/16);
    box-shadow: 0 0 calc(12rem/16) rgba(black, .3);
    @media screen and (max-width: 767px) {
        padding: calc(5rem/16);
    }
}
.main-nav__search-overlay__input-icon {
    font-size: calc(20rem/16);
    margin-right: calc(8rem/16);
}
@media screen and (min-width: 768px) {
    .main-nav__search-overlay__input:focus {
        outline: none;
        border-width: calc(2rem/16);
    }
    .main-nav__search-overlay__input:focus + .main-nav__search-overlay__btn {
        transform: translateX(calc(416rem/16)) rotate(260deg);
        box-shadow: 0 0 calc(10rem/16) calc(5rem/16) rgba(0,0,0,0.5);
    }
    .main-nav__search-overlay__input::placeholder {
        transform: translateX(calc(50rem/16));
        transition: transform 0.2s ease;
    }
    .main-nav__search-overlay__input:focus::placeholder {
        transform: translateX(0);
    }
}
.main-nav__search-overlay__input::placeholder {
    color:#fff;
}
.main-nav__search-overlay__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.6s ease-in-out;
    @media screen and (max-width: 767px) {
        left: initial;
        right:0;
        font-size: calc(18rem/16);
    }
}
.main-nav__search-overlay__form {
    width: calc(475rem/16);
    margin: 0 auto;
    position: relative;
    @media screen and (max-width: 767px) {
        width: auto;
    }
}
.main-navbar__overlay-close {
    position: absolute;
    left: 50%;
    top: calc(20rem/16);
    transform: translateX(-50%);
    background-color: transparent;
    font-size: calc(14rem/16);
    letter-spacing: calc(1rem/16);
    color: var(--color-text-muted);
    padding: calc(20rem/16) calc(30rem/16);
    border: none;
    z-index: 999;
    box-shadow: none;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (min-width: 768px) and (max-width: 991px) {
        font-size: 1.25rem;
        top: calc(100rem/16);
    }
}
.main-navbar__overlay-close__text {
    line-height: 1;
}
.main-navbar__overlay-close__icon {
    font-size: calc(12rem/16);
    padding-right: calc(7rem/16);
    color: var(--color-text-muted);
    @media screen and (min-width: 768px) and (max-width: 991px) {
        font-size: 1rem;
    }
}
.main-navbar__overlay-close:hover .main-navbar__overlay-close__text {
    opacity:1;
}
.main-navbar__overlay-title {
    font-family: var(--font-default-bold);
    font-weight: 700;
    font-size: calc(16rem/16);
    display: block;
    margin-bottom: calc(6rem/16);
    @media screen and (min-width: 768px) and (max-width: 991px) {
        text-align: center;
        font-size: 1.5rem;
        margin-bottom: 1rem;
    }
}

.main-navbar__overlay-teaser {
    display: flex;
    column-gap: calc(24rem/16);
    @media screen and (max-width: 767px) {
        flex-direction: column;
        row-gap: calc(24rem/16);
    }
}