.cookie-first__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--color-grey);
    color: var(--color-white);
}

.cookie-first__overlay__inner {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 100%;
}

.cookie-first__overlay__inner.cookie-first__overlay__inner--map {
    display: block;
    text-align: center;
    margin: calc(200rem / 16) calc(20rem / 16);
}

.cookie-first__accept-category {
    font-size: calc(12rem/16);
    padding: calc(16rem/16) calc(14rem/16);
    @media (min-width: 768px) {
        font-size: calc(14rem/16);
        padding: calc(16rem/16) calc(30rem/16);
    }
}