.nav-border-tabs {
    border-bottom: 1px solid var(--color-gray);
    font-size: 15px;
    font-family: var(--font-default-medium);
    font-weight: var(--font-default-medium-weight);
    @media (min-width: 768px) {
        border-bottom-color: var(--color-gray-border);
    }
}
.nav-border-tabs .nav-link {
    position: relative;
    color: var(--color-dark-gray);
    padding: .5rem;
}
.nav-border-tabs .nav-link:after {
    content: '';
    display: block;
    position: absolute;
    bottom: -3px;
    right: 0;
    left: 0;
    height: 5px;
    background: var(--color-primary);
    transform: scaleY(0);
    opacity: 0;
    transition: transform 120ms ease, opacity 140ms;
}
.nav-border-tabs .nav-link.active {
    color: var(--color-primary)
}
.nav-border-tabs .nav-link.active:after {
    transform: scaleY(1);
    opacity: 1;
    transition: transform 120ms ease, opacity 100ms;
}
@media (min-width: 768px) {
    .nav-border-tabs .nav-link:after {
        height: 3px;
        bottom: 0;
    }

    .nav-border-tabs {
        font-size: 16px;
        text-transform: uppercase;
    }

    .nav-border-tabs .nav-link {
        padding: 14px 20px;
    }

    .nav-border-tabs .nav-link:before {
        content: '';
        display: block;
        position: absolute;
        right: 0;
        width: 1px;
        height: 50%;
        top: 20%;
        background: var(--color-gray-border);
    }
    .nav-border-tabs .nav-item:last-child .nav-link:before {
        display: none;
    }
}