.success-icon {
    text-align: center;
    color: var(--color-success);
}
.success-icon__icon {
    font-size: 60px;
    vertical-align: middle;
}
.success-icon__bubble {
    display: inline-block;
    width: 8px;
    height: 8px;
    background: var(--color-success);
    border-radius: 50%;
}
.success-icon__bubble,
.success-icon__icon {
    margin-left: 4px;
}