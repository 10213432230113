.card {
    box-shadow: 20px 20px 40px 0 rgba(51,51,49,0.24);
    border-radius: 6px;
    overflow: hidden;
}
.card-body {
    padding: calc(20rem/16);

    @media screen and (min-width: 768px) {
        padding: clamp(1.7rem, 2.4vw, 3rem);
    }

    @media screen and (max-width: 768px) {
        font-size: calc(14rem/16);
    }
}
.card-title {
    font-size: clamp(1.2rem, 1.2vw, 1.5rem);

    @media screen and (max-width: 1600px) {
        margin-bottom: calc(15rem/16);
    }
}

.card-title--margin-bottom{
    margin-bottom: calc(24rem/16);
}
.card-list-item {
    padding-top: calc(12rem/16);
    padding-bottom: calc(12rem/16);
    border-top: calc(1rem/16) solid rgba(152, 150, 145, 0.2);
    font-size: calc(14rem/16);

    @media screen and (min-width: 1600px) {
        padding-top: calc(24rem/16);
        font-size: 1rem;
        padding-bottom: calc(24rem/16);
    }
}
.card-list-item:last-of-type {
    border-bottom: calc(1rem/16) solid rgba(152, 150, 145, 0.2);
}

.card-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: calc(24rem/16) calc(12rem/16) 0 calc(24rem/16);
    margin-bottom: calc(34rem/16);
    @media screen and (min-width: 768px) {
        padding: calc(24rem/16) calc(36rem/16) 0 calc(24rem/16);
    }
}

.card-price__background {
    width: calc(180rem/16);
    position: absolute;
    top: calc(-30rem/16);
    left: 0;
}
.card-price__background--wildschoenau {
    width: calc(170rem/16);
    top: calc(-20rem/16);
}
.card-price__background path {
    fill: var(--color-primary) !important;
}

.card-price__price {
    color: #000;
    line-height: 1;
    position: relative;
}
.card-price__amount {
    font-family: var(--font-default-extra-bold);
    font-size: calc(20rem/16);
    @media screen and (min-width: 768px) {
        font-size: calc(24rem/16);
    }
}
.card-price__from {
    font-size: calc(12rem/16);
    @media screen and (min-width: 768px) {
        font-size: calc(14rem/16);
    }
}
.card-price__text {
    font-size: calc(12rem/16);
    font-family: var(--font-default-extra-bold);
    @media screen and (min-width: 768px) {
        font-size: calc(14rem/16);
    }
}
.card__shortlist-button {
    width: calc(48rem/16);
    height: calc(48rem/16);
    border: calc(1rem/16) solid var(--color-middle-grey);
    border-radius: 50%;
    color: var(--color-middle-grey);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: calc(15rem/16);
    background-color: white;
    flex-shrink: 0;
    &.is-active {
        border: calc(1rem/16) solid var(--color-secondary);
        color: var(--color-secondary);
    }
}

.card-body--sticky{
    position: sticky;
    top: 0;
    height: min-content;
    padding-top: 50px;
}
