/* Text Helper */
.text-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


/* Text Color Helper */
.text-white { color: #ffffff; }
.text-color-default { color: var(--color-text-default); }
.text-middle-grey { color: var(--color-middle-grey); }

/* Background Color Helper */
.bg-wildschoenau {
    background-color: var(--color-wildschoenau);
}
.bg-skijuwel {
    background-color: var(--color-skijuwel);
}
.bg-alpbachtal {
    background-color: var(--color-alpbachtal);
}


.increased-click-area {
    padding: calc(8rem/16);
    margin: calc(-8rem/16);
}

.fz12--mobile {
    @media screen and (max-width: 767px) {
        font-size: calc(12rem/16);
    }
}
.fz12 {
    font-size: calc(12rem/16);
}
.fz14 {
    font-size: calc(14rem/16);
}
.fz16 {
    font-size: calc(16rem/16);
}
.fz20 {
    font-size: calc(20rem/16);
}
.fz24 {
    font-size: calc(24rem/16);
}
.w-20 {
    width: calc(20rem/16);
}
.w-30 {
    width: calc(30rem/16);
}
.font-default {
    font-family: var(--font-default);
}
.font-extra-bold {
    font-family: var(--font-default-extra-bold);
}
.box-shadow {
    box-shadow: calc(20rem/16) calc(20rem/16) calc(40rem/16) rgba(black, .24);
}
.cursor-pointer {
    cursor: pointer;
}
.cookie-overlay-btn {
    position: absolute;
    z-index: 99;
    height: auto;
    width: auto;
    margin: 0 auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.interactive-map__btn{
    @media screen and (max-width: 767px) {
        width: 80%;
    }
}

.text-line-through {
    text-decoration: line-through;
}

.text-shadow {
    text-shadow: 0 0 calc(24rem/16) rgba(black, .5);
}

.object-fit-cover {
    object-fit: cover;
}

html {
    scroll-padding-top: 260px;
}


.bg-light-gray {
    background: var(--color-dark-grey)
}

.designstudio-button{
    display: none !important;
}

.filter-form__apply {
    height: 46px;
}


