.row--gutter-width-0 {
     margin-left: 0;
     margin-right: 0;
}
.row--gutter-width-0 > [class^="col"],
.row--gutter-width-0 > [class*=" col"] {
    padding-left: 0;
    padding-right: 0;
}

.row--gutter-width-1 {
    margin-left: -1px;
    margin-right: 0;
}
.row--gutter-width-1 > [class^="col"],
.row--gutter-width-1 > [class*=" col"] {
    padding-left: 1px;
    padding-right: 0;
}

.row--gutter-width-6 {
    margin-left: -3px;
    margin-right: -3px;
}
.row--gutter-width-6 > [class^="col"],
.row--gutter-width-6 > [class*=" col"] {
    padding-left: 3px;
    padding-right: 3px;
}

.row--gutter-width-10 {
     margin-left: -5px;
     margin-right: -5px;
}
.row--gutter-width-10 > [class^="col"],
.row--gutter-width-10 > [class*=" col"] {
    padding-left: 5px;
    padding-right: 5px;
}

.row--gutter-width-14 {
     margin-left: -7px;
     margin-right: -7px;
}
.row--gutter-width-14 > [class^="col"],
.row--gutter-width-14 > [class*=" col"] {
    padding-left: 7px;
    padding-right: 7px;
}

.row--gutter-width-20 {
     margin-left: -10px;
     margin-right: -10px;
}
.row--gutter-width-20 > [class^="col"],
.row--gutter-width-20 > [class*=" col"] {
    padding-left: 10px;
    padding-right: 10px;
}
.row--gutter-width-30 {
     margin-left: -15px;
     margin-right: -15px;
}
.row--gutter-width-30 > [class^="col"],
.row--gutter-width-30 > [class*=" col"] {
    padding-left: 15px;
    padding-right: 15px;
}

/*use a media query for bigger row gutters; otherwise the page is scrollable on mobile devices */
@media (min-width: 768px) {
    .row--gutter-width-40 {
        margin-left: -20px;
        margin-right: -20px;
    }
    .row--gutter-width-40 > [class^="col"],
    .row--gutter-width-40 > [class*=" col"] {
        padding-left: 20px;
        padding-right: 20px;
    }

    .row--gutter-width-60 {
        margin-left: -30px;
        margin-right: -30px;
    }
    .row--gutter-width-60 > [class^="col"],
    .row--gutter-width-60 > [class*=" col"] {
        padding-left: 30px;
        padding-right: 30px;
    }
}

@media (min-width: 1200px) {
    .row--gutter-width-xl-60 {
        margin-left: -30px;
        margin-right: -30px;
    }
    .row--gutter-width-xl-60 > [class^="col"],
    .row--gutter-width-xl-60 > [class*=" col"] {
        padding-left: 30px;
        padding-right: 30px;
    }

}

/*vertical gutter*/
.row--vertical-gutter-1 {
    margin-top: -1px;
}
.row--vertical-gutter-1 > [class^="col"],
.row--vertical-gutter-1 > [class*=" col"] {
    margin-top: 1px;
}

.row--vertical-gutter-6 {
    margin-top: -6px;
}
.row--vertical-gutter-6 > [class^="col"],
.row--vertical-gutter-6 > [class*=" col"] {
    margin-top: 6px;
}

.row--vertical-gutter-30 {
    margin-top: -30px;
}
.row--vertical-gutter-30 > [class^="col"],
.row--vertical-gutter-30 > [class*=" col"] {
    margin-top: 30px;
}

.row--vertical-gutter-20 {
    margin-top: -20px;
}
.row--vertical-gutter-20 > [class^="col"],
.row--vertical-gutter-20 > [class*=" col"] {
    margin-top: 20px;
}
