.font-default {
    font-family: var(--font-default);
    font-weight: var(--font-default-weight);
}
.font-medium {
    font-family: var(--font-default-medium);
    font-weight: var(--font-default-medium-weight);
}
.font-bold {
    font-family: var(--font-default-bold);
    font-weight: var(--font-default-bold-weight);
}


.lh0 { line-height:0; }

.fz11 { font-size: 11px; }
.fz14 { font-size: 14px; }
.fz15 { font-size: 15px; }
.fz16 { font-size: 16px; }
.fz17 { font-size: 17px; }
.fz18 { font-size: 18px; }
.fz20 { font-size: 20px; }
.fz23 { font-size: 23px; }