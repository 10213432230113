.vertical-gutter {
    margin-top: calc(-1 * var(--bs-gutter-y));
}
.vertical-gutter__item {
    margin-top: var(--bs-gutter-y);
}

.vertical-gutter,
.vertical-gutter--1,
.vertical-gutter--2,
.vertical-gutter--3,
.vertical-gutter--4,
.vertical-gutter--5,
.vertical-gutter--6,
.vertical-gutter--24{
    display: flex;
    flex-direction: column;
    grid-gap: var(--bs-gutter-y);
}
.vertical-gutter--1 {
    --bs-gutter-y: $gutters [1];
}
.vertical-gutter--2 {
    --bs-gutter-y: $gutters [2];
}
.vertical-gutter--3 {
    --bs-gutter-y: $gutters [3];
}
.vertical-gutter--4 {
    --bs-gutter-y: $gutters [4];
}
.vertical-gutter--5 {
    --bs-gutter-y: $gutters [5];
}
.vertical-gutter--6 {
    margin-top: calc(-6rem / 16);
}

.vertical-gutter--6 > .vertical-gutter__item,
.vertical-gutter--6 > [class^="col"],
.vertical-gutter--6 > [class*=" col"] {
    margin-top: calc(6rem / 16);
}

.vertical-gutter--24 {
    margin-top: calc(-6rem / 16);
}

.vertical-gutter--24 > .vertical-gutter__item,
.vertical-gutter--24 > [class^="col"],
.vertical-gutter--24 > [class*=" col"] {
    margin-top: calc(24rem / 16);
}

.vertical-gutter--24-xs {
    @media screen and (max-width: 991px) {
        margin-top: calc(-12rem / 16);
    }
}
.vertical-gutter--24-xs > .vertical-gutter__item,
.vertical-gutter--24-xs > [class^="col"],
.vertical-gutter--24-xs > [class*=" col"] {
    @media screen and (max-width: 991px) {
        margin-top: calc(24rem / 16);
    }
}

.vertical-gutter--48-xs {
    @media screen and (max-width: 991px) {
        margin-top: calc(-24rem / 16);
    }
}
.vertical-gutter--48-xs > .vertical-gutter__item,
.vertical-gutter--48-xs > [class^="col"],
.vertical-gutter--48-xs > [class*=" col"] {
    @media screen and (max-width: 991px) {
        margin-top: calc(48rem / 16);
    }
}

