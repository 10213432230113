.wysiwyg p:last-child,
.wysiwyg ul:last-child,
.wysiwyg ol:last-child {
    margin-bottom: 0;
}
.wysiwyg ul,
.list-default {
    text-indent: -15px;
    padding-left: 15px;
    list-style-type: none;
}
.wysiwyg ul > li + li,
.list-default > li + li {
    margin-top: 3px;
}
.wysiwyg ul > li:before,
.list-default > li:before {
    content: '';
    display: inline-block;
    width: 5px;
    height: 5px;
    background: #8C8C8C;
    border-radius: 50%;
    padding-left: 0;
    margin-right: 10px;
    list-style-type: none;
    vertical-align: 0.2em;
}

.wysiwyg a:not(.btn) {
    color: var(--color-primary);
}

.wysiwyg a:not(.btn):focus,
.wysiwyg a:not(.btn):active,
.wysiwyg a:not(.btn):hover {
    color: var(--color-primary--dark);
}