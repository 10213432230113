.demi-icon {
    /* reset inline-layout icon placeholder */
    min-width: 0;
}
.icon-in-text {
    font-size: 1.1em;
    vertical-align: -.15em;
}
.icon-in-text-width {
    min-width: 33px;
    font-size: 0.9em;
    text-align: center;
}
.icon-in-text.demi-icon-filter {
    font-size: .7em;
    vertical-align: .05em;
}
.icon-in-text.demi-icon-map {
    font-size: .95em;
    vertical-align: -.1em;
}
.icon-in-text.demi-icon-chevron-down {
    font-size: calc(6 / 13 * 1em);
    vertical-align: .3em;
}
.icon-in-text.demi-icon-edit {
    font-size: 0.9em;
    vertical-align: 0;
}
.icon-in-text.demi-icon-check-circle {
    font-size: .95em;
}
.icon-in-text.demi-icon-link {
    font-size: .8em;
}

.circle-border-icon {
    border: 2px solid white;
    border-radius: 50%;
    padding: 12px;
}

.collapse-icon {
    display: inline-block;
    font-size: 6px;
    transform: rotate(180deg);
    transition: 120ms ease-out;
}
.collapsed .collapse-icon {
    transform: rotate(0deg);
}

.icon-list .demi-icon {
    min-width: 26px;
    text-align: center;
}