.row--bordered > [class^="col"]:not(:first-child),
.row--bordered > [class*=" col"]:not(:first-child) {
    /*todo var*/
    border-left: 1px solid #D8D8D8;
}
.row--bordered-grid {
    border: 1px solid #D8D8D8;
    border-left: none;
}
.row--bordered-grid > [class^="col"],
.row--bordered-grid > [class*=" col"] {
    /*todo var*/
    border-left: 1px solid #D8D8D8;
    border-bottom: 1px solid #D8D8D8;
    margin-bottom: -1px;
}
.row--bordered-grid > [class^="col"]:last-child,
.row--bordered-grid > [class*=" col"]:last-child {
    position: relative;
}
.row--bordered-grid > [class^="col"]:last-child::after,
.row--bordered-grid > [class*=" col"]:last-child::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: -1px;
    bottom: 0;
    border-right: 1px solid #D8D8D8;
}