@import '../../../../../../../../public/static/css/style/variables.scss';
/*@import '../../../../../../../../public/static/css/style/icons.scss';*/
@import '../../../../../../../../public/static/css/style/mediaqueries.scss';

@import '../../../../../../../../public/static/css/style/base.scss';
@import '../../../../../../../../public/static/css/style/base-layout.scss';

@import '../../../../../../../../public/static/css/style/main-navbar.scss';
@import '../../../../../../../../public/static/css/style/main-navbar__overlay.scss';
@import '../../../../../../../../public/static/css/style/main-navbar__toggle.scss';
@import '../../../../../../../../public/static/css/style/nav-overlay.scss';
@import '../../../../../../../../public/static/css/style/mega-nav/mega-nav.scss';
@import '../../../../../../../../public/static/css/style/mega-nav/mega-nav--level-0-desktop.scss';
@import '../../../../../../../../public/static/css/style/mega-nav/mega-nav--level-1-desktop.scss';
@import '../../../../../../../../public/static/css/style/mega-nav/mega-nav--level-2-desktop.scss';
@import '../../../../../../../../public/static/css/style/mega-nav/mega-nav--level-3-desktop.scss';
@import '../../../../../../../../public/static/css/style/mega-nav/mega-nav-mobile.scss';
@import '../../../../../../../../public/static/css/style/mega-nav/mega-nav-ipad.scss';

@import '../../../../../../../../public/static/css/style/container.scss';
@import '../../../../../../../../public/static/css/style/title-block.scss';
@import '../../../../../../../../public/static/css/style/prospect-grid.scss';
@import '../../../../../../../../public/static/css/style/card.scss';
@import '../../../../../../../../public/static/css/style/language-dropdown.scss';
@import '../../../../../../../../public/static/css/style/region-dropdown.scss';
@import '../../../../../../../../public/static/css/style/image-teaser.scss';

@import '../../../../../../../../public/static/css/style/btn.scss';
@import '../../../../../../../../public/static/css/style/link.scss';
@import '../../../../../../../../public/static/css/style/footer.scss';
@import '../../../../../../../../public/static/css/style/content-block.scss';

@import '../../../../../../../../public/static/css/style/row--gutter.scss';
@import '../../../../../../../../public/static/css/style/vertical-gutter.scss';
@import '../../../../../../../../public/static/css/style/step-nav.scss';
@import '../../../../../../../../public/static/css/style/cookiebar.scss';

@import '../../../../../../../../public/static/css/style/detail-grid.scss';
@import '../../../../../../../../public/static/css/style/detail-intro.scss';

@import '../../../../../../../../public/static/css/style/utils.scss';

.title-block__title {
    font-size: calc(30rem/16);
    @media screen and (min-width:768px) {
        font-size: calc(48rem/16);
    }
}

h2, .h2 {
    font-size: calc(30rem/16);
    @media screen and (min-width:768px) {
        font-size: calc(40rem/16);
    }
}
h3, .h3 {
    font-size: calc(22rem/16);
    @media screen and (min-width:768px) {
        font-size: calc(28rem/16);
    }
}
h4, .h4 {
    font-size: calc(20rem/16);
    @media screen and (min-width:768px) {
        font-size: calc(24rem/16);
    }
}
h5, .h5 {
    font-size: calc(18rem/16);
    @media screen and (min-width:768px) {
        font-size: calc(20rem/16);
    }
}
h6, .h6 {
    font-size: calc(14rem/16);
    @media screen and (min-width:768px) {
        font-size: calc(16rem/16);
    }
}
.overlay .ribbon__item {
    color: black !important;
}