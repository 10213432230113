.static-map {
    position: relative;
}
.static-map:after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    background: url(/static/demi/debug/img/map/marker-active.svg) no-repeat;
    background-size: contain;
    background-position: bottom center;
    transform: translate(-50%, -100%);
    width: 46px;
    height: 55px;
}