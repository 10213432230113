.increased-click-area.increased-click-area {
    padding: 10px;
    margin: -10px;
}

.bg-white { background: #ffffff; }
.bg-light-gray { background: var(--color-light-gray) }
.bg-primary-dark {
    background: var(--color-primary--dark);
    color: #ffffff;
}

.tfoot-gray tr{
    background-color: rgba(0,0,0,.05);
}

.tfoot-gray tr th,
.tfoot-gray tr td{
    border-top: 2px solid white;
}

.text-underline {
    text-decoration: underline;
}
.text-default { color: var(--color-text-default) }
.text-gray { color: var(--color-gray) }
.text-gray-medium { color: var(--color-dark-medium) }
.text-success-dark { color: var(--color-success--dark) }
.text-muted {color: var(--color-text-muted) !important;}
.text-black { color: #000;}

.img-full-width {
    width: 100%;
    height: auto;
}

.isClickable {cursor: pointer;}

.box-shadow { box-shadow: var(--box-shadow); }

.bordered { border: 1px solid var(--color-gray); }

.border-left {border-left: 1px solid var(--color-gray-border);}
.border-right {border-right: 1px solid var(--color-gray-border);}

@media (min-width: 768px) {
    .bordered { border: 1px solid var(--color-gray-border); }
}

.order-1 {
    order: 1;
}

.order-12 {
    order: 12;
}

@media (min-width: 992px) {
    .order-lg-1 {
        order: 1;
    }

    .order-lg-12 {
        order: 12;
    }
}

.z-1 {
    z-index: 1;
}

.mb30 {
    margin-bottom: 30px;
}

.flex-auto { flex:auto }

.lazy-img-placeholder:not([src]) {
    height: 0;
    padding-top: 75%;
    background-color: var(--color-gray);
}

.lazy-img {
    transition: opacity 120ms ease-out;
}
.lazy-img:not([src]) {
    opacity: 0;
}

.overflow-hidden {
    overflow: hidden;
}
 /* Negative margins */
.mx--4 {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
}


.container-break {
    margin-left: -15px;
    margin-right: -15px;
}

/*
.show-content-tracking [data-track-content] {
    outline: 2px solid lightskyblue;
}
.show-content-tracking [data-content-name]:before {
    content: attr(data-content-name);
    background: lightskyblue;
}
*/

.flex-fill {
    flex: auto;
}

.fz9 {
    font-size: calc(9rem/16);
}
.fz12 {
    font-size: calc(12rem/16);
}
.fz32--desktop {
    @media screen and (min-width: 768px) {
        font-size: calc(32rem/16) !important;
    }
}
.rounded-circle {
    border-radius: 50%;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
}
.w-30 {
    width: calc(30rem/16);
}
.gap--10 {
    gap: calc(10rem/16);
}
.lh1 {
    line-height:1;
}
.h-100 {
    height: 100%;
}
.object-fit-cover {
    object-fit: cover;
}