.payment-details {
    padding: .5rem;
}

.payment-details__item {
    margin-bottom: .5rem;
}

@media (min-width: 768px) {
    .payment-details {
        padding: 1rem;
    }

    .payment-details__item {
        margin-bottom: 1rem;
    }

    .payment-card-icon {
        font-size: 32px;
    }
}
