.merchshop__badge {
    display: inline-block;
    padding:.25rem 1.5rem;
    text-align:center;
    color:#fff;
    font-family:var(--font-default-bold);
    background: var(--color-primary);
}
.merchshop__price {
    font-size: 1.875rem;
}


/* slider */
.merch-slider-nav__item {
    cursor: pointer;
    opacity: .7;
}
.merch-slider-nav__item:focus,
.merch-slider-nav__item:hover,
.slick-current .merch-slider-nav__item {
    opacity: 1;
}
.slick-arrow--merch-slider {
    color:#fff;
    text-shadow: 0px 0px 8px rgba(0,0,0,0.6);
    font-size: 1.25rem;
    padding:1rem;
    position: absolute;
    top:50%;
    background: transparent;
    transform: translateY(-50%);
    z-index:5;
    opacity: .7;
}
.slick-arrow--merch-slider.slick-prev {
    left:0;
}
.slick-arrow--merch-slider.slick-next {
    right:0;
}
.slick-arrow--merch-slider:hover,
.slick-arrow--merch-slider:focus {
    opacity: 1;
}



/* product size radio buttons */
.merch-size__radio>label,
.merch-size__item{
    width: calc(50rem/16);
    height: calc(50rem/16);
    border: 1px solid #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: calc(16rem/16);
    font-family: var(--font-default-bold);
    transition: all 200ms ease;
    cursor: pointer;

    @media screen and (min-width: 768px) and (max-width: 992px){
        width: calc(36rem/16);
        font-size: calc(14rem/16);
    }
}

.merch-size__radio--disabled {
    pointer-events: none;
}
.merch-size__radio.merch-size__radio--disabled > label{
    pointer-events: none;
    opacity: 0.3;
    cursor: not-allowed;
}

.merch-size__radio > label{
    border-color: grey;
    width: auto;
    padding:0 .5rem;
    height: calc(30rem/16);
}

.merch-size__input:checked ~ label,
.merch-size__radio:not(.merch-size__radio--disabled) >label:hover{
    color: #fff;
    background-color: var(--color-text-default);
    border-color: var(--color-text-default);
}

.merch-size__input {
    display: none;
}