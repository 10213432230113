.facility-list__content {
    padding-top: 36px;
    padding-bottom: 30px;
}

.facility-list__item:last-child .facility-list__content {
    padding-bottom: 0;
}

.facility-list__content--bordered {
    border-top: 1px solid var(--color-gray-border);
}
.facility-list {
    padding-left: calc(25rem/16);
}