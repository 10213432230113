.nav-tabs--custom {
    background-color:#fff;
    border-radius: calc(30rem/16);
    box-shadow: 0 0 40px 0 rgba(51,51,49,0.12);
    border: none;
    padding-left: calc(20rem/16);
    padding-right: calc(20rem/16);
}
.nav-tabs--custom .nav-link {
    font-weight: var(--font-weight-bold);
    font-family: var(--font-default-bold);
    border: none;
    color: var(--color-text-default);
    padding: calc(19rem/16) calc(24rem/16);
}
.nav-tabs--custom .nav-item.show .nav-link, .nav-tabs--custom .nav-link.active {
    border: none;
}
.nav-tabs--custom .nav-item.show .nav-link:after, .nav-tabs--custom .nav-link.active:after {
    content:'';
    width: 100%;
    height: calc(3rem/16);
    background-color: var(--color-primary);
    display: block;
}