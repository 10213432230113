.list-toggle {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 20px;
}
body.has-cookie-bar .list-toggle {
    bottom: 120px;
}

.list-toggle__btn {
    color: white;
    background-color: var(--color-dark-gray);
}

.btn-group.list-toggle>.btn:last-child:not(:first-child) {
    border-left-color: #8C8C8C;
}