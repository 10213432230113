/* Global variables and settings */
/* general base styling & reset (mostly plain element styling; very low specificity ) */
/* Add your font-face rules here e.g: */
@font-face {
    font-family: 'nunito-sans-regular';
    src: url('/static/fonts/nunitosans-regular.woff2') format('woff2');
    font-weight: 400;
    font-display: swap;
}
@font-face {
    font-family: 'nunito-sans-bold';
    src: url('/static/fonts/nunitosans-bold.woff2') format('woff2');
    font-weight: 700;
    font-display: swap;
}
@font-face {
    font-family: 'nunito-sans-extrabold';
    src: url('/static/fonts/nunitosans-extrabold.woff2') format('woff2');
    font-weight: 800;
    font-display: swap;
}
a,
a:hover,
a:focus {
    color: inherit;
    text-decoration: none;
}
a[data-toggle="collapse"]:hover,
a[data-toggle="collapse"]:focus {
    text-decoration: none;
}
address, dl, ol, ul, label, dd, figure {
    margin-bottom: 0;
}
dt {
    font-weight: inherit;
}
textarea {
    resize: none;
}
abbr, abbr[title] {
    text-decoration: none;
}
mark {
    background: none;
    font-family: "nunito-sans-extrabold", sans-serif;
    padding: 0;
}
/*tood move*/
.btn-no-styling {
    border: none;
    background: none;
    text-align: left;
    padding: 0;
    color: inherit;
    font-family: inherit;
    font-weight: inherit;
}
.btn-no-styling.d-block {
    width: 100%;
}
body {
    color: #333331;
    font-family: "nunito-sans-regular", sans-serif;
    font-weight: 400;
    font-size: 13px;
}
b,
strong,
.strong {
    font-family: "nunito-sans-extrabold", sans-serif;
    font-weight: 800;
}
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    margin-top: 0;
    display: block;
    font-family: "nunito-sans-bold", sans-serif;
    font-weight: 700;
}
h1, .h1 {
    font-size: 24px;
    line-height: 1.16667;
}
h2, .h2 {
    font-size: 20px;
    line-height: 1.1;
    color: #333331 !important;
}
h3, .h3 {
    font-size: 17px;
}
h4, .h4 {
    font-size: 15px;
    line-height: 1.13333;
}
h5, .h5 {
    font-size: 13px;
}
h6, .h6 {
    font-size: 1em; /*todo*/
}
small, .small {
    font-size: 84.61538%;
}
.main-content--no-hero {
    padding-top: 2.5rem;
}
hr {
    border-top-color: #bfbfbf;
}
.hr--bold {
    border-top-width: 5px;
}
.hr--light {
    border-top-color: #f2f2f2;
}
.hr--border-gray {
    border-top-color: #D8D8D8;
}
@font-face {
     font-family: "demiIcons";
     src: url('/cache-buster-1741682608952/static/demi/debug/icons/font/demiIcons.eot?#iefix') format('eot'),
         url('/cache-buster-1741682608952/static/demi/debug/icons/font/demiIcons.woff') format('woff'),
         url('/cache-buster-1741682608952/static/demi/debug/icons/font/demiIcons.ttf') format('ttf');
     font-weight: normal;
     font-style: normal;
 }
.demi-icon {
    display: inline-block;
    font-family: "demiIcons";
    speak: none;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
}
.demi-icon-lg {
    font-size: 1.3333333333333333em;
    line-height: 0.75em;
    vertical-align: -15%;
}
.demi-icon-2x { font-size: 2em; }
.demi-icon-3x { font-size: 3em; }
.demi-icon-4x { font-size: 4em; }
.demi-icon-5x { font-size: 5em; }
.demi-icon-fw {
    width: 1.2857142857142858em;
    text-align: center;
}
.demi-icon-ul {
    padding-left: 0;
    margin-left: 2.14285714em;
    list-style-type: none;
}
.demi-icon-ul > li {
    position: relative;
}
.demi-icon-li {
    position: absolute;
    left: -2.14285714em;
    width: 2.14285714em;
    top: 0.14285714em;
    text-align: center;
}
.demi-icon-li.demi-icon-lg {
    left: -1.85714286em;
}
.demi-icon-rotate-90 {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}
.demi-icon-rotate-180 {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}
.demi-icon-rotate-270 {
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg);
}
.demi-icon-flip-horizontal {
    -webkit-transform: scale(-1, 1);
            transform: scale(-1, 1);
}
.demi-icon-flip-vertical {
    -webkit-transform: scale(1, -1);
            transform: scale(1, -1);
}
:root .demi-icon-rotate-90,
:root .demi-icon-rotate-180,
:root .demi-icon-rotate-270,
:root .demi-icon-flip-horizontal,
:root .demi-icon-flip-vertical {
    -webkit-filter: none;
            filter: none;
}
.demi-icon-add-circle:before {
    content: "\EA01";
     font-family: "demiIcons";
}
.demi-icon-add:before {
    content: "\EA02";
     font-family: "demiIcons";
}
.demi-icon-arrow-from-to:before {
    content: "\EA03";
     font-family: "demiIcons";
}
.demi-icon-arrow-left:before {
    content: "\EA04";
     font-family: "demiIcons";
}
.demi-icon-arrow-right:before {
    content: "\EA05";
     font-family: "demiIcons";
}
.demi-icon-assignment:before {
    content: "\EA06";
     font-family: "demiIcons";
}
.demi-icon-bars:before {
    content: "\EA07";
     font-family: "demiIcons";
}
.demi-icon-bed:before {
    content: "\EA08";
     font-family: "demiIcons";
}
.demi-icon-bell:before {
    content: "\EA09";
     font-family: "demiIcons";
}
.demi-icon-buildings:before {
    content: "\EA0A";
     font-family: "demiIcons";
}
.demi-icon-business:before {
    content: "\EA0B";
     font-family: "demiIcons";
}
.demi-icon-calendar-insert:before {
    content: "\EA0C";
     font-family: "demiIcons";
}
.demi-icon-call:before {
    content: "\EA0D";
     font-family: "demiIcons";
}
.demi-icon-camping:before {
    content: "\EA0E";
     font-family: "demiIcons";
}
.demi-icon-cancel:before {
    content: "\EA0F";
     font-family: "demiIcons";
}
.demi-icon-cart:before {
    content: "\EA10";
     font-family: "demiIcons";
}
.demi-icon-check-circle:before {
    content: "\EA11";
     font-family: "demiIcons";
}
.demi-icon-check:before {
    content: "\EA12";
     font-family: "demiIcons";
}
.demi-icon-chevron-down:before {
    content: "\EA13";
     font-family: "demiIcons";
}
.demi-icon-chevron-left:before {
    content: "\EA14";
     font-family: "demiIcons";
}
.demi-icon-chevron-right:before {
    content: "\EA15";
     font-family: "demiIcons";
}
.demi-icon-chevron-up:before {
    content: "\EA16";
     font-family: "demiIcons";
}
.demi-icon-child-friendly:before {
    content: "\EA17";
     font-family: "demiIcons";
}
.demi-icon-classification-blume:before {
    content: "\EA18";
     font-family: "demiIcons";
}
.demi-icon-classification-edelweiss:before {
    content: "\EA19";
     font-family: "demiIcons";
}
.demi-icon-classification-star:before {
    content: "\EA1A";
     font-family: "demiIcons";
}
.demi-icon-close-circle:before {
    content: "\EA1B";
     font-family: "demiIcons";
}
.demi-icon-close:before {
    content: "\EA1C";
     font-family: "demiIcons";
}
.demi-icon-closed:before {
    content: "\EA1D";
     font-family: "demiIcons";
}
.demi-icon-delete:before {
    content: "\EA1E";
     font-family: "demiIcons";
}
.demi-icon-download:before {
    content: "\EA1F";
     font-family: "demiIcons";
}
.demi-icon-draw-on-map:before {
    content: "\EA20";
     font-family: "demiIcons";
}
.demi-icon-edit:before {
    content: "\EA21";
     font-family: "demiIcons";
}
.demi-icon-education:before {
    content: "\EA22";
     font-family: "demiIcons";
}
.demi-icon-elements:before {
    content: "\EA23";
     font-family: "demiIcons";
}
.demi-icon-email:before {
    content: "\EA24";
     font-family: "demiIcons";
}
.demi-icon-euro:before {
    content: "\EA25";
     font-family: "demiIcons";
}
.demi-icon-facebook:before {
    content: "\EA26";
     font-family: "demiIcons";
}
.demi-icon-filter:before {
    content: "\EA27";
     font-family: "demiIcons";
}
.demi-icon-fitness:before {
    content: "\EA28";
     font-family: "demiIcons";
}
.demi-icon-flickr:before {
    content: "\EA29";
     font-family: "demiIcons";
}
.demi-icon-flower-alpbachtal:before {
    content: "\EA2A";
     font-family: "demiIcons";
}
.demi-icon-foursquare:before {
    content: "\EA2B";
     font-family: "demiIcons";
}
.demi-icon-fullscreen-exit:before {
    content: "\EA2C";
     font-family: "demiIcons";
}
.demi-icon-fullscreen:before {
    content: "\EA2D";
     font-family: "demiIcons";
}
.demi-icon-globe:before {
    content: "\EA2E";
     font-family: "demiIcons";
}
.demi-icon-group:before {
    content: "\EA2F";
     font-family: "demiIcons";
}
.demi-icon-image:before {
    content: "\EA30";
     font-family: "demiIcons";
}
.demi-icon-info-circle-outline:before {
    content: "\EA31";
     font-family: "demiIcons";
}
.demi-icon-info-circle:before {
    content: "\EA32";
     font-family: "demiIcons";
}
.demi-icon-info-outline:before {
    content: "\EA33";
     font-family: "demiIcons";
}
.demi-icon-info:before {
    content: "\EA34";
     font-family: "demiIcons";
}
.demi-icon-instagram:before {
    content: "\EA35";
     font-family: "demiIcons";
}
.demi-icon-landscape:before {
    content: "\EA36";
     font-family: "demiIcons";
}
.demi-icon-link:before {
    content: "\EA37";
     font-family: "demiIcons";
}
.demi-icon-live-cam:before {
    content: "\EA38";
     font-family: "demiIcons";
}
.demi-icon-location-on:before {
    content: "\EA39";
     font-family: "demiIcons";
}
.demi-icon-map:before {
    content: "\EA3A";
     font-family: "demiIcons";
}
.demi-icon-marker:before {
    content: "\EA3B";
     font-family: "demiIcons";
}
.demi-icon-minus:before {
    content: "\EA3C";
     font-family: "demiIcons";
}
.demi-icon-mobile-phone-2:before {
    content: "\EA3D";
     font-family: "demiIcons";
}
.demi-icon-more-horiz:before {
    content: "\EA3E";
     font-family: "demiIcons";
}
.demi-icon-navigation:before {
    content: "\EA3F";
     font-family: "demiIcons";
}
.demi-icon-near-me:before {
    content: "\EA40";
     font-family: "demiIcons";
}
.demi-icon-nights:before {
    content: "\EA41";
     font-family: "demiIcons";
}
.demi-icon-pano:before {
    content: "\EA42";
     font-family: "demiIcons";
}
.demi-icon-payment:before {
    content: "\EA43";
     font-family: "demiIcons";
}
.demi-icon-person:before {
    content: "\EA44";
     font-family: "demiIcons";
}
.demi-icon-pets:before {
    content: "\EA45";
     font-family: "demiIcons";
}
.demi-icon-pinterest:before {
    content: "\EA46";
     font-family: "demiIcons";
}
.demi-icon-play-circle-fill:before {
    content: "\EA47";
     font-family: "demiIcons";
}
.demi-icon-play-circle:before {
    content: "\EA48";
     font-family: "demiIcons";
}
.demi-icon-plus:before {
    content: "\EA49";
     font-family: "demiIcons";
}
.demi-icon-pool:before {
    content: "\EA4A";
     font-family: "demiIcons";
}
.demi-icon-remove-circle:before {
    content: "\EA4B";
     font-family: "demiIcons";
}
.demi-icon-restaurant:before {
    content: "\EA4C";
     font-family: "demiIcons";
}
.demi-icon-search:before {
    content: "\EA4D";
     font-family: "demiIcons";
}
.demi-icon-share:before {
    content: "\EA4E";
     font-family: "demiIcons";
}
.demi-icon-sort-asc:before {
    content: "\EA4F";
     font-family: "demiIcons";
}
.demi-icon-sort-desc:before {
    content: "\EA50";
     font-family: "demiIcons";
}
.demi-icon-sort:before {
    content: "\EA51";
     font-family: "demiIcons";
}
.demi-icon-star:before {
    content: "\EA52";
     font-family: "demiIcons";
}
.demi-icon-target:before {
    content: "\EA53";
     font-family: "demiIcons";
}
.demi-icon-tel:before {
    content: "\EA54";
     font-family: "demiIcons";
}
.demi-icon-thumb-up:before {
    content: "\EA55";
     font-family: "demiIcons";
}
.demi-icon-ticket-cta:before {
    content: "\EA56";
     font-family: "demiIcons";
}
.demi-icon-tractor:before {
    content: "\EA57";
     font-family: "demiIcons";
}
.demi-icon-tumbler:before {
    content: "\EA58";
     font-family: "demiIcons";
}
.demi-icon-twitter:before {
    content: "\EA59";
     font-family: "demiIcons";
}
.demi-icon-vimeo:before {
    content: "\EA5A";
     font-family: "demiIcons";
}
.demi-icon-visibility-off:before {
    content: "\EA5B";
     font-family: "demiIcons";
}
.demi-icon-visibility:before {
    content: "\EA5C";
     font-family: "demiIcons";
}
.demi-icon-warning:before {
    content: "\EA5D";
     font-family: "demiIcons";
}
.demi-icon-wellness:before {
    content: "\EA5E";
     font-family: "demiIcons";
}
.demi-icon-youtube:before {
    content: "\EA5F";
     font-family: "demiIcons";
}
/* Generic helper classes (mostly concerning layout) */
.row--bordered > [class^="col"]:not(:first-child),
.row--bordered > [class*=" col"]:not(:first-child) {
    /*todo var*/
    border-left: 1px solid #D8D8D8;
}
.row--bordered-grid {
    border: 1px solid #D8D8D8;
    border-left: none;
}
.row--bordered-grid > [class^="col"],
.row--bordered-grid > [class*=" col"] {
    /*todo var*/
    border-left: 1px solid #D8D8D8;
    border-bottom: 1px solid #D8D8D8;
    margin-bottom: -1px;
}
.row--bordered-grid > [class^="col"]:last-child,
.row--bordered-grid > [class*=" col"]:last-child {
    position: relative;
}
.row--bordered-grid > [class^="col"]:last-child::after,
.row--bordered-grid > [class*=" col"]:last-child::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: -1px;
    bottom: 0;
    border-right: 1px solid #D8D8D8;
}
.row--gutter-width-0 {
     margin-left: 0;
     margin-right: 0;
}
.row--gutter-width-0 > [class^="col"],
.row--gutter-width-0 > [class*=" col"] {
    padding-left: 0;
    padding-right: 0;
}
.row--gutter-width-1 {
    margin-left: -1px;
    margin-right: 0;
}
.row--gutter-width-1 > [class^="col"],
.row--gutter-width-1 > [class*=" col"] {
    padding-left: 1px;
    padding-right: 0;
}
.row--gutter-width-6 {
    margin-left: -3px;
    margin-right: -3px;
}
.row--gutter-width-6 > [class^="col"],
.row--gutter-width-6 > [class*=" col"] {
    padding-left: 3px;
    padding-right: 3px;
}
.row--gutter-width-10 {
     margin-left: -5px;
     margin-right: -5px;
}
.row--gutter-width-10 > [class^="col"],
.row--gutter-width-10 > [class*=" col"] {
    padding-left: 5px;
    padding-right: 5px;
}
.row--gutter-width-14 {
     margin-left: -7px;
     margin-right: -7px;
}
.row--gutter-width-14 > [class^="col"],
.row--gutter-width-14 > [class*=" col"] {
    padding-left: 7px;
    padding-right: 7px;
}
.row--gutter-width-20 {
     margin-left: -10px;
     margin-right: -10px;
}
.row--gutter-width-20 > [class^="col"],
.row--gutter-width-20 > [class*=" col"] {
    padding-left: 10px;
    padding-right: 10px;
}
.row--gutter-width-30 {
     margin-left: -15px;
     margin-right: -15px;
}
.row--gutter-width-30 > [class^="col"],
.row--gutter-width-30 > [class*=" col"] {
    padding-left: 15px;
    padding-right: 15px;
}
/*use a media query for bigger row gutters; otherwise the page is scrollable on mobile devices */
/*vertical gutter*/
.row--vertical-gutter-1 {
    margin-top: -1px;
}
.row--vertical-gutter-1 > [class^="col"],
.row--vertical-gutter-1 > [class*=" col"] {
    margin-top: 1px;
}
.row--vertical-gutter-6 {
    margin-top: -6px;
}
.row--vertical-gutter-6 > [class^="col"],
.row--vertical-gutter-6 > [class*=" col"] {
    margin-top: 6px;
}
.row--vertical-gutter-30 {
    margin-top: -30px;
}
.row--vertical-gutter-30 > [class^="col"],
.row--vertical-gutter-30 > [class*=" col"] {
    margin-top: 30px;
}
.row--vertical-gutter-20 {
    margin-top: -20px;
}
.row--vertical-gutter-20 > [class^="col"],
.row--vertical-gutter-20 > [class*=" col"] {
    margin-top: 20px;
}
.demi-icon {
    /* reset inline-layout icon placeholder */
    min-width: 0;
}
.icon-in-text {
    font-size: 1.1em;
    vertical-align: -.15em;
}
.icon-in-text-width {
    min-width: 33px;
    font-size: 0.9em;
    text-align: center;
}
.icon-in-text.demi-icon-filter {
    font-size: .7em;
    vertical-align: .05em;
}
.icon-in-text.demi-icon-map {
    font-size: .95em;
    vertical-align: -.1em;
}
.icon-in-text.demi-icon-chevron-down {
    font-size: 0.46154em;
    vertical-align: .3em;
}
.icon-in-text.demi-icon-edit {
    font-size: 0.9em;
    vertical-align: 0;
}
.icon-in-text.demi-icon-check-circle {
    font-size: .95em;
}
.icon-in-text.demi-icon-link {
    font-size: .8em;
}
.circle-border-icon {
    border: 2px solid white;
    border-radius: 50%;
    padding: 12px;
}
.collapse-icon {
    display: inline-block;
    font-size: 6px;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    -webkit-transition: 120ms ease-out;
    -o-transition: 120ms ease-out;
    transition: 120ms ease-out;
}
.collapsed .collapse-icon {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
}
.icon-list .demi-icon {
    min-width: 26px;
    text-align: center;
}
.list-inline--gutter-6 {
    margin-top: -6px;
    margin-right: -3px;
    margin-left: -3px;
    font-size: 0; /*remove font-size*/
}
.list-inline--gutter-6 > .list-inline-item {
    margin-top: 6px;
    margin-right: 6px;
    margin-left: 6px;
    font-size: 1rem;
    max-width: 100%;
    max-width: calc(100% - 6px);
}
.list-inline--gutter-3 {
    margin-top: -3px;
    margin-right: -2px;
    margin-left: -1px;
    font-size: 0; /*remove font-size*/
}
.list-inline--gutter-3 > .list-inline-item {
    margin-top: 3px;
    margin-right: 2px;
    margin-left: 1px;
    font-size: 1rem;
}
.list-inline--dots > .list-inline-item:after {
    content: '•';
    display: inline-block;
    margin-left: 6px;
}
.list-inline--dots > .list-inline-item:last-child:after {
    display: none;
}
.center-container {
    text-align: center;
    font-size: 0;
}
.center-container:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}
.center-container__item {
    display: inline-block;
    vertical-align: middle;
    max-height: 100%;
    max-width: 100%;
}
.center-container__item-wrapper {
    display: inline-block;
    position: relative;
    max-height: 100%;
}
/*@import '../../../../../../../../public/static/css/style/icons.scss';*/
/* Defines the order in which media queries should be ordered. This is required for the mqpacker postcss plugin: https://www.npmjs.com/package/mqpacker)*/
/*Base-Styling for HTML-Elements*/
a,
a:hover,
a:focus {
    color: inherit;
    text-decoration: none;
}
th {
    font-family: "nunito-sans-extrabold", sans-serif;
    font-weight: normal;
}
textarea {
    resize: vertical;
}
label {
    font-weight: normal;
    margin-bottom: 0;
}
ul,
ol,
dl {
    margin-bottom: 0;
}
dt {
    font-weight: normal;
}
*::-moz-selection {
    color: #ffffff;
    background-color:  #ECD7A3;
}
*::selection {
    color: #ffffff;
    background-color: #ECD7A3;
}
video {
    background: #000000;
}
html {
    -ms-overflow-style: scrollbar; /*prevent scrollbar from overlapping content in IE*/
}
body {
    position: relative;
    background-color: #FEFAF1;
}
.body--freezed {
    overflow-y: hidden;
}
.body--freezed:before {
        content: "";
        position: absolute;
        inset: 0;
        -webkit-backdrop-filter: blur(8px);
                backdrop-filter: blur(8px);
        z-index: 10;
    }
.body-cca--freezed {
    overflow: hidden;
}
.body-cca--freezed:before {
        content: "";
        position: absolute;
        inset: 0;
        background-color: white;
        z-index: 10;
    }
//todo change when navi is ready
.main-content--no-hero {
    padding-top: 7.5rem;
}
.content-cca--small {
    padding: 0 2rem;
}
.main-navbar {
    width: 100%;
    z-index: 10;
    position: relative;
}
.main-navbar-nav {
    background: rgba(254, 250, 241, .8);
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.main-navbar--fixed {
    position: fixed;
    left:0;
    right:0;
    top:0;
}
.main-navbar--shadow:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        pointer-events: none;
        height: 16.5rem;
        background: -webkit-gradient(linear, left top, left bottom, from(black), to(transparent));
        background: -webkit-linear-gradient(top, black, transparent);
        background: -o-linear-gradient(top, black, transparent);
        background: linear-gradient(to bottom, black, transparent);
        opacity: .3;
    }
.main-navbar--shadow .main-navbar-nav {
        background-color: transparent;
        -webkit-backdrop-filter: none;
                backdrop-filter: none;
        color: white;
        min-height: unset;
    }
.main-navbar--shadow .main-navbar__brand {
        width: 6.6875rem;
        height: 7.5rem;
    }
.main-navbar--shadow .main-navbar__brand--skijuwel {
        width: 16.875rem;
        height: auto;
        aspect-ratio: 220 / 96;
        position: relative;
    }
.main-navbar--shadow .main-navbar__brand--wildschoenau {
        width: 10.3125rem;
        height: 6.25rem;
    }
.main-navbar--shadow .main-navbar__brand--cca {
        width: 18.75rem;
        height: auto;
        aspect-ratio: 254 / 90;
        padding-top: 0.5rem;
    }
.main-navbar--shadow .main-navbar__brand--brixlegg {
        width: 9.375rem;
        height: auto;
    }
.main-navbar__container {
    z-index: 10;
    position: relative;
    width: 100%;
    display: grid;
    grid-template-rows: 0 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas:
    "area-meta-nav area-meta-nav area-meta-nav"
    "area-menu-search area-logo area-regions-shop";
}
.main-navbar__brand {
    width: 5rem;
    height: 5.625rem;
    -o-object-fit: cover;
       object-fit: cover;
    -webkit-transition: width .3s ease, height .3s ease;
    -o-transition: width .3s ease, height .3s ease;
    transition: width .3s ease, height .3s ease;
}
.main-navbar__brand--skijuwel {
    width: 9.375rem;
    height: auto;
    aspect-ratio: 220 / 96;
}
.main-navbar__brand--cca {
    width: 10rem;
    height: auto;
    aspect-ratio: 254 / 90;
}
.main-navbar__brand--brixlegg {
    width: 9.375rem;
    height: auto;
}
.main-navbar__area-logo, .main-navbar__area-toggle {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.main-navbar__area-logo {
    grid-area: area-logo;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    opacity: 1;
    -webkit-transition: opacity 300ms ease;
    -o-transition: opacity 300ms ease;
    transition: opacity 300ms ease;
}
.body--freezed .main-navbar__area-logo {
        opacity: 0;
    }
.main-navbar__area-toggle {
    grid-area: area-toggle;
}
.main-navbar__area-nav-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    grid-area: area-nav-list;
    -webkit-box-align:center;
        -ms-flex-align:center;
            align-items:center;
    justify-self: end;
}
.main-navbar__area-lang-switch {
    grid-area: area-lang-switch;
    justify-self: flex-end;
}
.main-navbar__area-meta-nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    grid-area: area-meta-nav;
    opacity: 1;
    -webkit-transition: opacity 300ms ease;
    -o-transition: opacity 300ms ease;
    transition: opacity 300ms ease;
}
.body--freezed .main-navbar__area-meta-nav {
        opacity: 0;
    }
.main-navbar__area-regions-shop {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    grid-area: area-regions-shop;
    opacity: 1;
    -webkit-transition: opacity 300ms ease;
    -o-transition: opacity 300ms ease;
    transition: opacity 300ms ease;
}
.body--freezed .main-navbar__area-regions-shop {
        opacity: 0;
    }
.main-navbar__area-menu-search {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    grid-area: area-menu-search;
}
.main-navbar__list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-column-gap: 2.25rem;
       -moz-column-gap: 2.25rem;
            column-gap: 2.25rem;
}
.main-navbar__item__link {
    font-size: 0.875rem;
    line-height: 1.35714;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    background-color: transparent;
    border: none;
    -webkit-box-shadow: none;
            box-shadow: none;
    overflow: hidden;
    position: relative;
    z-index: 1;
    padding: 0.1875rem 0;
}
.main-navbar__item__link::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 0.0625rem;
    z-index: -1;
    -webkit-transition: width 0.3s ease;
    -o-transition: width 0.3s ease;
    transition: width 0.3s ease;
    background-color: #D34442;
}
.main-navbar__item__link:hover {
    color: #D34442;
}
.main-navbar__item__link:hover::before {
    width: 100%;
}
.main-navbar__item__link.is-active {
    color: #D34442;
}
.main-navbar__item:not(.main-navbar__item--search) {
    -webkit-transition: -webkit-transform 0.2s ease;
    transition: -webkit-transform 0.2s ease;
    -o-transition: transform 0.2s ease;
    transition: transform 0.2s ease;
    transition: transform 0.2s ease, -webkit-transform 0.2s ease;
    -webkit-backface-visibility: hidden;
}
.main-navbar__item--search {
    margin-left: 3.75rem;
    color: #989691;
}
.main-navbar__item-icon {
    font-size: 1rem;
    font-weight: 400;
    margin-right: 0.375rem;
}
.main-navbar__item-icon--small {
    font-size: 0.625rem;
}
.main-navbar__shop-link {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-column-gap: 0.75rem;
       -moz-column-gap: 0.75rem;
            column-gap: 0.75rem;
    color: #D34442;
    -webkit-transition: color 300ms ease;
    -o-transition: color 300ms ease;
    transition: color 300ms ease;
}
.main-navbar--shadow .main-navbar__shop-link {
        color: white;
    }
.main-navbar--shadow .main-navbar__shop-link--btn {
        background-color: transparent;
    }
.main-navbar__shop-link--btn:hover {
        color: white;
    }
.main-navbar__shop-link--btn-white {
    background-color: white;
    color: #D34442;
    border-radius: 3.3125rem;
    -webkit-transition: background-color 300ms ease;
    -o-transition: background-color 300ms ease;
    transition: background-color 300ms ease;
}
.main-navbar--shadow .main-navbar__shop-link--btn-white {
        background-color: transparent;
    }
.main-navbar__shop-link--btn-white:hover {
        color: #D34442;
        background-color: #F5F5F5;
    }
.main-navbar__shop-link-text {
    text-transform: uppercase;
    letter-spacing: 0.125rem;
    font-size: 0.875rem;
    font-weight: 800;
}
.main-navbar__shop-link-icon {
    font-size: 1.125rem;
}
.main-navbar__area-contact-login {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    grid-area: area-regions-shop;
    opacity: 1;
    -webkit-transition: opacity 300ms ease;
    -o-transition: opacity 300ms ease;
    transition: opacity 300ms ease;
}
.body--freezed .main-navbar__area-contact-login {
        opacity: 0;
    }
.main-navbar__link {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-column-gap: 0.75rem;
       -moz-column-gap: 0.75rem;
            column-gap: 0.75rem;
    color: #D34442;
    -webkit-transition: color 300ms ease;
    -o-transition: color 300ms ease;
    transition: color 300ms ease;
}
.main-navbar--shadow .main-navbar__link {
        color: white;
    }
.main-navbar__link-text {
    text-transform: uppercase;
    letter-spacing: 0.125rem;
    font-size: 0.875rem;
    font-weight: 800;
}
.main-navbar__link-icon {
    font-size: 1.125rem;
}
.main-navbar--checkout {
    padding: 0.9375rem 0.9375rem;
}
.main-navbar__account-menu {
    padding: 1.5rem;
    text-align: center;
    background-color: rgba(255, 255, 255, .8);
    border: none;
    -webkit-box-shadow: 0 0 2rem rgba(0, 0, 0, .5);
            box-shadow: 0 0 2rem rgba(0, 0, 0, .5);
    margin-top: 2rem;
}
.main-navbar__account-menu-btn {
    margin-bottom: 2rem;
}
.main-navbar__account-menu-title {
    margin-bottom: .5rem;
}
.main-navbar__account-menu-link {
    font-size: .75rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-column-gap: 0.25rem;
       -moz-column-gap: 0.25rem;
            column-gap: 0.25rem;
}
.main-navbar__account-menu-link .icon {
        font-size: .5rem;
    }
.main-navbar__overlay {
    opacity:0;
    visibility: hidden;
    position: fixed;
    top:0;
    width: 100%;
    left:0;
    right:0;
    z-index: -1;
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
    color: #333331;
    -webkit-transform-origin: top;
            transform-origin: top;
    -webkit-transition: opacity 0.25s 0.15s, visibility 0.0001s 0.4s, -webkit-transform 0.4s;
    transition: opacity 0.25s 0.15s, visibility 0.0001s 0.4s, -webkit-transform 0.4s;
    -o-transition: opacity 0.25s 0.15s, visibility 0.0001s 0.4s, transform 0.4s;
    transition: opacity 0.25s 0.15s, visibility 0.0001s 0.4s, transform 0.4s;
    transition: opacity 0.25s 0.15s, visibility 0.0001s 0.4s, transform 0.4s, -webkit-transform 0.4s;
    background-color: white;
}
.main-navbar__overlay--full-height {
    height: 100vh;
}
.main-navbar__overlay.show-overlay {
    opacity:1;
    visibility: visible;
    -webkit-transform: translateY(0);
            transform: translateY(0);
    z-index: 9999;
    -webkit-transition: opacity 0.2s, visibility 0.0001s, -webkit-transform 0.4s;
    transition: opacity 0.2s, visibility 0.0001s, -webkit-transform 0.4s;
    -o-transition: opacity 0.2s, visibility 0.0001s, transform 0.4s;
    transition: opacity 0.2s, visibility 0.0001s, transform 0.4s;
    transition: opacity 0.2s, visibility 0.0001s, transform 0.4s, -webkit-transform 0.4s;
}
.main-navbar__overlay-container {
    max-width: 51.75rem;
    padding-top: 19.25926vh;
    margin: 0 auto;
    opacity:0;
    height: 100%;
    -webkit-transform: translateY(-100%) scale(0.5);
            transform: translateY(-100%) scale(0.5);
    -webkit-transition: opacity 0.3s 0.1s ease, -webkit-transform 0.7s 0.1s ease-out;
    transition: opacity 0.3s 0.1s ease, -webkit-transform 0.7s 0.1s ease-out;
    -o-transition: transform 0.7s 0.1s ease-out, opacity 0.3s 0.1s ease;
    transition: transform 0.7s 0.1s ease-out, opacity 0.3s 0.1s ease;
    transition: transform 0.7s 0.1s ease-out, opacity 0.3s 0.1s ease, -webkit-transform 0.7s 0.1s ease-out;
}
.show-overlay .main-navbar__overlay-container {
    opacity:1;
    -webkit-transform: translateY(0) scale(1);
            transform: translateY(0) scale(1);
}
.main-nav__search-overlay__input {
    height: 3rem;
    border: none;
    display: block;
    width: 100%;
    color: #333331;
    font-size: 1rem;
    background-color: transparent;
    padding: 1.25rem 2.1875rem;
    -webkit-box-shadow: 0 0 0.75rem rgba(black, .3);
            box-shadow: 0 0 0.75rem rgba(black, .3);
}
.main-nav__search-overlay__input-icon {
    font-size: 1.25rem;
    margin-right: 0.5rem;
}
.main-nav__search-overlay__input::-webkit-input-placeholder {
    color:#fff;
}
.main-nav__search-overlay__input::-moz-placeholder {
    color:#fff;
}
.main-nav__search-overlay__input::-ms-input-placeholder {
    color:#fff;
}
.main-nav__search-overlay__input::placeholder {
    color:#fff;
}
.main-nav__search-overlay__btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-transition: -webkit-transform 0.6s ease-in-out;
    transition: -webkit-transform 0.6s ease-in-out;
    -o-transition: transform 0.6s ease-in-out;
    transition: transform 0.6s ease-in-out;
    transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
}
.main-nav__search-overlay__form {
    width: 29.6875rem;
    margin: 0 auto;
    position: relative;
}
.main-navbar__overlay-close {
    position: absolute;
    left: 50%;
    top: 1.25rem;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    background-color: transparent;
    font-size: 0.875rem;
    letter-spacing: 0.0625rem;
    color: #989691;
    padding: 1.25rem 1.875rem;
    border: none;
    z-index: 999;
    -webkit-box-shadow: none;
            box-shadow: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.main-navbar__overlay-close__text {
    line-height: 1;
}
.main-navbar__overlay-close__icon {
    font-size: 0.75rem;
    padding-right: 0.4375rem;
    color: #989691;
}
.main-navbar__overlay-close:hover .main-navbar__overlay-close__text {
    opacity:1;
}
.main-navbar__overlay-title {
    font-family: "nunito-sans-extrabold", sans-serif;
    font-weight: 700;
    font-size: 1rem;
    display: block;
    margin-bottom: 0.375rem;
}
.main-navbar__overlay-teaser {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-column-gap: 1.5rem;
       -moz-column-gap: 1.5rem;
            column-gap: 1.5rem;
}
.main-navbar__toggle {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    outline:none;
    background: transparent;
    border: none;
    color: #333331;
    position: relative;
}
.main-navbar--shadow .main-navbar__toggle {
        color: white;
    }
.main-navbar__toggle--cca {
    color: black !important;
}
.main-navbar__toggle--cca.is-open {
    -webkit-box-shadow: none;
            box-shadow: none;
}
.main-navbar__toggle:hover, .main-navbar__toggle:active, .main-navbar__toggle:focus {
    outline:none;
}
.main-navbar__toggle__text, .main-navbar__toggle__text--open {
    font-family: "nunito-sans-extrabold", sans-serif;
    font-weight: 800;
    font-size: 0.875rem;
    letter-spacing: 0.125rem;
    text-transform: uppercase;
}
.main-navbar__toggle__text {
    margin-left: 0.6875rem;
}
.main-navbar__toggle:hover:not(.is-open) .main-navbar__toggle__text {
    opacity:1;
}
.main-navbar__toggle__text--open {
    opacity:0;
    -webkit-transition: opacity 0.2s ease, visibility 0.0001s ease, -webkit-transform 0.3s ease;
    transition: opacity 0.2s ease, visibility 0.0001s ease, -webkit-transform 0.3s ease;
    -o-transition: opacity 0.2s ease, visibility 0.0001s ease, transform 0.3s ease;
    transition: opacity 0.2s ease, visibility 0.0001s ease, transform 0.3s ease;
    transition: opacity 0.2s ease, visibility 0.0001s ease, transform 0.3s ease, -webkit-transform 0.3s ease;
    visibility:hidden;
    display: none;
}
.is-open .main-navbar__toggle__text--open {
    opacity: 1;
    visibility: visible;
    display: block;
}
.main-navbar__toggle.is-open:hover .main-navbar__toggle__text--open {
    opacity:1;
}
.is-open .main-navbar__toggle__text {
    opacity:0;
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
    display: none;
}
.main-navbar__toggle {
    z-index: 999;
    opacity: 1;
    -webkit-transition: opacity 300ms ease;
    -o-transition: opacity 300ms ease;
    transition: opacity 300ms ease;
}
.main-navbar__toggle.is-open {
    opacity: .3;
    -webkit-column-gap: 0.625rem;
       -moz-column-gap: 0.625rem;
            column-gap: 0.625rem;
    line-height: 1;
    color: #333331;
}
.main-navbar__toggle .icon-x {
    display: none;
}
.main-navbar__toggle.is-open .icon-burger, .main-navbar__toggle.is-open .icon-mobile-burger {
    display: none !important;
}
.main-navbar__toggle.is-open .icon-x {
    display: inline-block;
}
.main-navbar__search-overlay-toggle {
    outline:none;
    background: transparent;
    border: none;
    color: #333331;
}
.main-navbar--shadow .main-navbar__search-overlay-toggle {
        color: white;
    }
.main-navbar__search-overlay-toggle--cca {
    color: black !important;
}
.nav-overlay {
    opacity:0;
    visibility: hidden;
    position: fixed;
    width: 100%;
    height: 100%;
    inset: 0;
    color: #333331;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    -webkit-transform-origin: left top;
            transform-origin: left top;
    z-index: 99;
    -webkit-transition: opacity 0.1s 0.2s, visibility 0.6s 0.0001s, ease-in-out transform 0.3s;
    -o-transition: opacity 0.1s 0.2s, visibility 0.6s 0.0001s, ease-in-out transform 0.3s;
    transition: opacity 0.1s 0.2s, visibility 0.6s 0.0001s, ease-in-out transform 0.3s;
}
.nav-overlay.show-overlay {
    opacity:1;
    visibility: visible;
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    -webkit-transition: opacity 0.1s, visibility 0.0001s, ease-in-out transform 0.3s;
    -o-transition: opacity 0.1s, visibility 0.0001s, ease-in-out transform 0.3s;
    transition: opacity 0.1s, visibility 0.0001s, ease-in-out transform 0.3s;
}
.mega-nav__item-content--toggle {
    display: none;
}
.mega-nav__teaser-list {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
}
.mega-nav__container {
    padding-top: 12.96296vh;
    height: 100%;
}
.mega-nav__item-subtext {
    font-size: 1rem;
    line-height: 1.375;
    letter-spacing: 0.5rem;
    color: #ECD7A3;
    text-transform: uppercase;
    margin-top: 0.375rem;
    width: 100%;
    text-align: left;
}
.mega-nav__item--opacity-toggle {
    opacity: .2;
}
.mega-nav__image-teaser {
    position: absolute;
    top: 0;
    right: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    row-gap: 2rem;
}
.collapsed .mega-nav__collapse-icon {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
}
.mega-nav__collapse-icon {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    -webkit-transition: 0.2s ease;
    -o-transition: 0.2s ease;
    transition: 0.2s ease;
    font-size: 0.375rem;
    margin-left: 0.875rem;
    color: #333331;
}
/*___________ CTA Buttons _________*/
.container.container {
    max-width: 108.75rem;
    width: 100%;
}
.container {
    padding-left: 1.875rem;
    padding-right: 1.875rem;
}
.container--fullwidth {
    max-width: 100vw;
    width: 100%;
    padding: 0;
}
.container--lg {
    margin: 0 auto;
    max-width: 88.4375rem;
    width: 100%;
    padding-left: 1.875rem;
    padding-right: 1.875rem;
}
.container--md {
    margin: 0 auto;
    max-width: 71.25rem;
    width: 100%;
    padding-left: 1.875rem;
    padding-right: 1.875rem;
}
.container--sm {
    margin: 0 auto;
    max-width: 53.75rem;
    width: 100%;
    padding-left: 1.875rem;
    padding-right: 1.875rem;
}
.container--breadcrumbs {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    position: relative;
    z-index: 1;
}
.title-block__top-title {
    color: #ECD7A3;
    text-transform: uppercase;
    letter-spacing: 0.5rem;
    margin-bottom: 0.375rem;
    font-size: 1rem;
    line-height: 1.375rem;
    font-family: "nunito-sans-extrabold", sans-serif;
}
.title-block__title {
    color: #333331;
    margin-bottom: 1.5rem;
}
.title-block--centered {
    text-align: center;
}
.title-block__sub-title {
    font-size: 1rem;
    line-height: 1.375;
    font-family: "nunito-sans-regular", sans-serif;
    font-weight: 400;
}
.title-block__image-divider {
    width: 1.5rem;
    height: auto;
    margin-bottom: 0.75rem;
}
.prospect-grid {
    display: grid;
    gap: 1.25rem;
}
.card-body--sticky{
    position: sticky;
    top: 0;
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
}
.card {
    -webkit-box-shadow: 20px 20px 40px 0 rgba(51,51,49,0.24);
            box-shadow: 20px 20px 40px 0 rgba(51,51,49,0.24);
    border-radius: 6px;
    overflow: hidden;
}
.card-body {
    padding: 1.25rem;
}
.card-title {
    font-size: clamp(1.2rem, 1.2vw, 1.5rem);
}
.card-title--margin-bottom{
    margin-bottom: 1.5rem;
}
.card-list-item {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    border-top: 0.0625rem solid rgba(152, 150, 145, 0.2);
    font-size: 0.875rem;
}
.card-list-item:last-of-type {
    border-bottom: 0.0625rem solid rgba(152, 150, 145, 0.2);
}
.card-top {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 1.5rem 0.75rem 0 1.5rem;
    margin-bottom: 2.125rem;
}
.card-price__background {
    width: 11.25rem;
    position: absolute;
    top: -1.875rem;
    left: 0;
}
.card-price__background--wildschoenau {
    width: 10.625rem;
    top: -1.25rem;
}
.card-price__background path {
    fill: #ECD7A3 !important;
}
.card-price__price {
    color: #000;
    line-height: 1;
    position: relative;
}
.card-price__amount {
    font-family: "nunito-sans-extrabold", sans-serif;
    font-size: 1.25rem;
}
.card-price__from {
    font-size: 0.75rem;
}
.card-price__text {
    font-size: 0.75rem;
    font-family: "nunito-sans-extrabold", sans-serif;
}
.card__shortlist-button {
    width: 3rem;
    height: 3rem;
    border: 0.0625rem solid #7F7F7F;
    border-radius: 50%;
    color: #7F7F7F;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    font-size: 0.9375rem;
    background-color: white;
    -ms-flex-negative: 0;
        flex-shrink: 0;
}
.card__shortlist-button.is-active {
        border: 0.0625rem solid #D34442;
        color: #D34442;
    }
.card-body--sticky{
    position: sticky;
    top: 0;
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
    padding-top: 50px;
}
.language-dropdown {

}
.language-dropdown__btn {
    background-color: transparent;
    text-align: left;
    -webkit-box-shadow: none;
            box-shadow: none;
    border: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding-left: 0;
    color: #989691;
    font-size: 0.75rem;
}
.landingpage-nav .language-dropdown__btn {
        color: #333331;
    }
.landingpage-nav.main-navbar--shadow .language-dropdown__btn {
        color: #FEFAF1;
    }
.language-dropdown__btn::after {
    content: var(--icon-chevron);
    font-family: iconfont;
    border: none;
    font-size: 0.3125rem;
    margin-left: 0.375rem;
}
.language-dropdown__icon {
    margin-right: 0.375rem;
}
.language-dropdown--nav-overlay {
    margin-top: 4.5rem;
}
.language-dropdown__menu {
    border: none;
    -webkit-box-shadow: 1.25rem 1.25rem 2.5rem rgba(black, .12);
            box-shadow: 1.25rem 1.25rem 2.5rem rgba(black, .12);
    font-size: 0.75rem;
}
.region-dropdown__btn {
    background-color: transparent;
    text-align: left;
    font-size: 0.75rem;
    -webkit-box-shadow: none;
            box-shadow: none;
    border: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding-left: 0;
}
.main-navbar--shadow .region-dropdown__btn {
        color: white;
    }
.region-dropdown__btn::after {
    content: var(--icon-chevron);
    font-family: iconfont;
    border: none;
    font-size: 0.3125rem;
    margin-left: 0.375rem;
}
.region-dropdown__icon {
    font-size: 1rem;
    margin-right: 0.375rem;
}
.image-teaser {
    width: 18.9375rem;
    position: relative;
    border-radius: 0.375rem;
    overflow: hidden;
    display: block;
}
.image-teaser:after {
        content: "";
        position: absolute;
        inset: 0;
        background: -webkit-linear-gradient(260deg, transparent, black);
        background: -o-linear-gradient(260deg, transparent, black);
        background: linear-gradient(190deg, transparent, black);
        opacity: .3;
    }
.image-teaser:hover .image-teaser__icon:after {
    opacity: 1;
}
.image-teaser--full-width {
    width: 100%;
}
.image-teaser__title {
    position: absolute;
    bottom: 1.5rem;
    left: 1.5rem;
    font-family: "nunito-sans-extrabold", sans-serif;
    font-size: 1.25rem;
    line-height: 1;
    color: #FEFAF1;
    max-width: 12.5rem;
    z-index: 1;
}
.image-teaser__subtitle {
    color: #FEFAF1;
    position: absolute;
    left: 3rem;
    bottom: 3rem;
    z-index: 1;
}
.image-teaser__icon {
    color: white;
    font-size: 0.75rem;
    position: absolute;
    bottom: 1.75rem;
    right: 1.5rem;
    z-index: 1;
}
.image-teaser__icon:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        height: 5.375rem;
        width: 5.375rem;
        border-radius: 50%;
        background-color: #ECD7A3;
        z-index: -1;
        opacity: 0;
        -webkit-transition: opacity .3s ease;
        -o-transition: opacity .3s ease;
        transition: opacity .3s ease;
    }
.image-teaser__icon--big-padding {
    bottom: 3.5rem;
    right: 3rem;
}
.btn-no-styling {
    border: none;
    background: none;
    text-align: left;
    padding: 0;
    color: inherit;
    font-family: inherit;
    font-weight: inherit;
    cursor: pointer;
}
.btn {
    letter-spacing: 0.125rem;
    text-transform: uppercase;
    -webkit-box-shadow: 1.25rem 1.25rem 2.5rem rgba(black, .12);
            box-shadow: 1.25rem 1.25rem 2.5rem rgba(black, .12);
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-column-gap: 0.75rem;
       -moz-column-gap: 0.75rem;
            column-gap: 0.75rem;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}
.btn-sm {
    line-height: 1;
    letter-spacing: 0.125rem;
    font-family: "nunito-sans-bold", sans-serif;
    font-weight: var(--font-weight-medium);
}
.btn-booking {
    background-color: #D34442;
    color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.btn__icon {
    font-size: 1.1875rem;
}
.btn__icon--small {
    font-size: 0.625rem;
}
.btn-white {
    background-color: white;
}
.btn-white:focus, .btn-white:active {
        color: black;
    }
.btn-back {
    font-family: "nunito-sans-extrabold", sans-serif;
    font-weight: 800;
    color: #7F7F7F;
    font-size: 0.875rem;
    letter-spacing: 0.125rem;
    text-transform: uppercase;
}
.btn-back--margin-bottom {
    margin-bottom: 1.5rem;
}
.btn-link {
    color: #333331;
    text-transform: uppercase;
    letter-spacing: 0.125rem;
    font-size: 0.875rem;
    font-family: "nunito-sans-extrabold", sans-serif;
    font-weight: 700;
    text-decoration: none;
}
.btn-link:hover {
        color: #D34442;
    }
.btn-no-shadow {
    -webkit-box-shadow: none;
            box-shadow: none;
}
.btn-alpbachtal {
    background-color: #ECD7A3;
    color: #333331;
}
.btn-wildschoenau {
    background-color: #014B94;
    color: white;
}
.btn-skijuwel {
    background-color: #008BD0;
    color: white;
}
.link {
    font-size: 0.875rem;
    line-height: 1;
    letter-spacing: 0.125rem;
    text-transform: uppercase;
    color: #333331;
    font-family: "nunito-sans-extrabold", sans-serif;
}
.link--with-icon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-column-gap: 0.75rem;
       -moz-column-gap: 0.75rem;
            column-gap: 0.75rem;
}
.link__icon {
    font-size: 0.625rem;
}
.footer-top.container{
    padding-bottom: 3rem;
}
.footer-top__header{
    margin-bottom: 3rem;
    color: #ECD7A3;
}
.footer-top__subtitle{
    font-size: 0.875rem;
    line-height: 1.1875rem;
    letter-spacing: 0.5rem;
    margin-bottom: 0.375rem;
    text-transform: uppercase;
    color: #ECD7A3;
}
.footer-top__text-muted{
    color: #989691;
}
.footer-top__header-title{
    font-size: 2.625rem;
    line-height: 2.625rem;
    font-family: "nunito-sans-extrabold", sans-serif;
}
.footer-top__header-icon{
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
}
.footer-top__title{
    font-size: 1rem;
    line-height: 1rem;
    font-family: "nunito-sans-extrabold", sans-serif;
    margin-bottom: 1.5rem;
}
.footer-top__title--big{
    font-size: 1.5rem;
    line-height: 1.5rem;
    font-family: "nunito-sans-extrabold", sans-serif;
    margin-bottom: 1.5rem;
}
.footer-top__newsletter-btn{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}
.footer-top__service-chat {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-column-gap: 0.75rem;
       -moz-column-gap: 0.75rem;
            column-gap: 0.75rem;
}
.footer-top__service-chat-text{
    font-family: "nunito-sans-regular", sans-serif;
}
.footer-top__social-media{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-column-gap: 1.875rem;
       -moz-column-gap: 1.875rem;
            column-gap: 1.875rem;
}
.footer-top__social-media .icon{
    font-size: 1.875rem;
}
.footer-top__contact{
    margin-top: 1.5rem;
    font-family: "nunito-sans-extrabold", sans-serif;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    row-gap: 0.75rem;
}
.footer-top__contact-item {
    cursor: pointer;
}
.footer-top__links{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.footer-top__links .icon{
    font-size: 0.75rem;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
}
.footer-top__image-divider {
    max-height: 1.5rem;
    -o-object-fit: contain;
       object-fit: contain;
}
.footer-bottom{
    background-color: #fff;
}
.footer-bottom__chat{
    position: fixed;
    bottom: 0;
    right: 0;
    background-color: #fcf8f2;
    margin-right: 20px;
    z-index: 100003;


}
.footer-bottom__chat .icon::before{
         font-size: 20px;

    }
.footer-bottom__chat--animation{

    -webkit-animation: 0.3s ease-out 0s 1 slideInFromDown;

            animation: 0.3s ease-out 0s 1 slideInFromDown;

}
.footer-bottom__chat--disappear{
    visibility: hidden;
    opacity: 0;
    -webkit-transition: visibility 0s 0.1s, opacity 0.1s linear;
    -o-transition: visibility 0s 0.1s, opacity 0.1s linear;
    transition: visibility 0s 0.1s, opacity 0.1s linear;
}
@-webkit-keyframes slideInFromDown {
     0% {
         -webkit-transform: translateY(100%);
                 transform: translateY(100%);
     }
     100% {
         -webkit-transform: translateY(0);
                 transform: translateY(0);
     }
 }
@keyframes slideInFromDown {
     0% {
         -webkit-transform: translateY(100%);
                 transform: translateY(100%);
     }
     100% {
         -webkit-transform: translateY(0);
                 transform: translateY(0);
     }
 }
.btn.footer-bottom__chat{
    -webkit-box-shadow: 0 0 0.688em 0 rgba(0,0,0,0.5);
            box-shadow: 0 0 0.688em 0 rgba(0,0,0,0.5);
}
#warning-static-release{
    display: none;
}
.sf-minitoolbar{
    display:none;
}
.footer-bottom__list{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}
.footer-bottom__list li {
    -webkit-transition: .25s;
    -o-transition: .25s;
    transition: .25s;
    font-size: 0.75rem;
}
.footer-bottom__list li:hover{
        color: #ECD7A3;
    }
.footer-bottom__elements{
    width: 6.9375rem;
    height: 0.75rem;
}
.footer-bottom__tyrol{
    margin-right: 0.75rem;
}
.footer-bottom__copyright{
    font-size: clamp(12rem/16, 2vw, 14rem/16);
    color: #989691;
}
.content-block + .content-block,
.content-block + iframe + .content-block,
.pimcore_area_content + iframe + .content-block,
.content-block + .pimcore_area_content > .content-block,
.pimcore_area_content + iframe + .pimcore_area_content > .content-block,
.pimcore_area_content + .pimcore_area_content > .content-block,
.pimcore_area_content + .footer-top,
.parallax-area__content > .title-block.content-block {
    margin-top: 3rem;
}
.content-block + .content-block--sm,
.content-block + .pimcore_area_content > .content-block--sm,
.content-block--sm + .content-block--sm,
.pimcore_area_content + .pimcore_area_content > .content-block--sm {
    margin-top: 3.125rem;
}
.content-block + .content-block--xs,
.content-block + .pimcore_area_content > .content-block--xs,
.content-block--xs + .content-block--xs,
.pimcore_area_content + .pimcore_area_content > .content-block--xs {
    margin-top: 2.5rem;
}
.content-block + .content-block--xl,
.content-block + .pimcore_area_content > .content-block--xl,
.content-block--xl + .content-block--xl,
.pimcore_area_content + .pimcore_area_content > .content-block--xl {
    margin-top: 6.25rem;
}
/* this should remove the space between this (last) element and the page-footer */
.main-content > .content-block:last-child.content-block--no-footer-margin,
.pimcore_area_content:last-child .content-block.content-block--no-footer-margin {
    margin-bottom: -3.75rem;
}
.content-visibility--auto .content-block {
    contain-intrinsic-size: 1px;
    content-visibility: auto;
}
/* rem gutters */
.row-gutter--0 {
    margin-left: 0;
    margin-right: 0;
}
.row-gutter--0 > [class^="col"],
.row-gutter--0 > [class*=" col"],
.row-gutter--0.slick-slider [class^="col"],
.row-gutter--0.slick-slider [class*=" col"] {
    padding-left: 0;
    padding-right: 0;
}
.row-gutter--1 {
    margin-left: -.125rem;
    margin-right: -.125rem;
}
.row-gutter--1 > [class^="col"],
.row-gutter--1 > [class*=" col"],
.row-gutter--1.slick-slider [class^="col"],
.row-gutter--1.slick-slider [class*=" col"] {
    padding-left: .125rem;
    padding-right: .125rem;
}
.row-gutter--2 {
    margin-left: -.25rem;
    margin-right: -.25rem;
}
.row-gutter--2 > [class^="col"],
.row-gutter--2 > [class*=" col"],
.row-gutter--2.slick-slider [class^="col"],
.row-gutter--2.slick-slider [class*=" col"] {
    padding-left: .25rem;
    padding-right: .25rem;
}
.row-gutter--3 {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
}
.row-gutter--3 > [class^="col"],
.row-gutter--3 > [class*=" col"],
.row-gutter--3.slick-slider [class^="col"],
.row-gutter--3.slick-slider [class*=" col"] {
    padding-left: .5rem;
    padding-right: .5rem;
}
.row-gutter--4 {
    margin-left: -.75rem;
    margin-right: -.75rem;
}
.row-gutter--4 > [class^="col"],
.row-gutter--4 > [class*=" col"],
.row-gutter--4.slick-slider [class^="col"],
.row-gutter--4.slick-slider [class*=" col"] {
    padding-left: .75rem;
    padding-right: .75rem;
}
/*use a media query for bigger row gutters; otherwise the page is scrollable on mobile devices */
/***********************************************************
               ATTENTION: Deprecated
    Pixel Gutters should not be used any more.
    They are included for backward compatibility only.
    Please use rem gutters instead

 ***********************************************************/
/* pixel gutters */
.row.row--gutter-width-0 {
    margin-left: 0;
    margin-right: 0;
}
.row--gutter-width-0 > [class^="col"],
.row--gutter-width-0 > [class*=" col"],
.row--gutter-width-0.slick-slider [class^="col"],
.row--gutter-width-0.slick-slider [class*=" col"] {
    padding-left: 0;
    padding-right: 0;
}
.row.row--gutter-width-10 {
    margin-left: -5px;
    margin-right: -5px;
}
.row--gutter-width-10 > [class^="col"],
.row--gutter-width-10 > [class*=" col"],
.row--gutter-width-10.slick-slider [class^="col"],
.row--gutter-width-10.slick-slider [class*=" col"] {
    padding-left: 5px;
    padding-right: 5px;
}
.row.row--gutter-width-20 {
    margin-left: -10px;
    margin-right: -10px;
}
.row--gutter-width-20 > [class^="col"],
.row--gutter-width-20 > [class*=" col"],
.row--gutter-width-20.slick-slider [class^="col"],
.row--gutter-width-20.slick-slider [class*=" col"] {
    padding-left: 10px;
    padding-right: 10px;
}
.vertical-gutter {
    margin-top: calc(-1 * var(--bs-gutter-y));
}
.vertical-gutter__item {
    margin-top: var(--bs-gutter-y);
}
.vertical-gutter,
.vertical-gutter--1,
.vertical-gutter--2,
.vertical-gutter--3,
.vertical-gutter--4,
.vertical-gutter--5,
.vertical-gutter--6,
.vertical-gutter--24{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    grid-gap: var(--bs-gutter-y);
}
.vertical-gutter--1 {
    --bs-gutter-y: $gutters [1];
}
.vertical-gutter--2 {
    --bs-gutter-y: $gutters [2];
}
.vertical-gutter--3 {
    --bs-gutter-y: $gutters [3];
}
.vertical-gutter--4 {
    --bs-gutter-y: $gutters [4];
}
.vertical-gutter--5 {
    --bs-gutter-y: $gutters [5];
}
.vertical-gutter--6 {
    margin-top: -0.375rem;
}
.vertical-gutter--6 > .vertical-gutter__item,
.vertical-gutter--6 > [class^="col"],
.vertical-gutter--6 > [class*=" col"] {
    margin-top: 0.375rem;
}
.vertical-gutter--24 {
    margin-top: -0.375rem;
}
.vertical-gutter--24 > .vertical-gutter__item,
.vertical-gutter--24 > [class^="col"],
.vertical-gutter--24 > [class*=" col"] {
    margin-top: 1.5rem;
}
.step-nav {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 0 auto;
    max-width: 53.3125rem;
}
.step-nav__counter {
    height: 1.5rem;
    width: 1.5rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align:center;
        -ms-flex-align:center;
            align-items:center;
    font-size: 0.875rem;
    margin: 0 auto;
    text-align:center;
    background-color: #D34442;
    border-radius: 50%;
    -webkit-transition: 0.4s ease border-color, 0.4s ease color, 0.4s ease background-color;
    -o-transition: 0.4s ease border-color, 0.4s ease color, 0.4s ease background-color;
    transition: 0.4s ease border-color, 0.4s ease color, 0.4s ease background-color;
    will-change: border-color, color, background-color;
}
.step-nav__item.active .step-nav__counter {
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s;
    -webkit-transition: 0.4s ease border-color, 0.4s ease color, 0.4s ease background-color;
    -o-transition: 0.4s ease border-color, 0.4s ease color, 0.4s ease background-color;
    transition: 0.4s ease border-color, 0.4s ease color, 0.4s ease background-color;
    -webkit-animation: bounce 2s cubic-bezier(0.280, 0.840, 0.420, 1) forwards;
            animation: bounce 2s cubic-bezier(0.280, 0.840, 0.420, 1) forwards;
    border-color: #D34442;
    background-color: #D34442;
    color: #D34442;
}
.step-nav__item.is-previous .step-nav__counter {
    border-color: #D34442;
    background-color: #D34442;
    color:#fff;
    font-size: 0.5rem;
}
.step-nav__item:not(:last-of-type):after {
    content:'';
    height: 0.25rem;
    background-color: rgba(152, 150, 145, 0.1);
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -ms-flex-item-align: center;
        align-self: center;
    -webkit-transition: 0.2s ease background-color;
    -o-transition: 0.2s ease background-color;
    transition: 0.2s ease background-color;
    will-change: background-color;
}
.step-nav__item.is-previous:not(:last-of-type):after {
    background-color: #D34442;
}
.step-nav.has-error .step-nav__item.is-previous:after  {
    background-color: #DC3545;
}
.step-nav__text {
    font-size: 0.75rem;
    position: absolute;
    left: 0;
    text-align:center;
    width: 9.375rem;
    color: #1D1D1B;
    margin-top: 3.1875rem;
    line-height: 1;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    text-align: center;
    margin-left: 0.75rem;
}
.step-nav__item {
    position: relative;
}
.step-nav__legend {
    -ms-flex-item-align:center;
        align-self:center;
    border-top: 1px solid #BEC2C6;
}
.step-nav__item:last-of-type .step-nav__counter {
    color: rgba(152, 150, 145, 0.1);
    background-color:transparent;
    padding: 0.3125rem;
    font-size: 1rem;
    width: 1.25rem;
    height: 1.25rem;
}
.step-nav__item:not(:last-of-type) > div:nth-child(1) { -ms-grid-column: 1; }
.step-nav__item:not(:last-of-type) > div:nth-child(2) { -ms-grid-column: 2; }
.step-nav__item:not(:last-of-type) {
    display: grid;
    grid-template-columns: 2rem 4.375rem;
}
.step-nav__item:first-of-type {
    grid-template-columns: 2rem 4.375rem;
}
.step-nav__item.active .step-nav__text {
    color: #2D343E;
    font-family: "nunito-sans-extrabold", sans-serif;
    font-weight: 800;
}
.step-nav__item.active:last-of-type .step-nav__counter{
    color: #D34442;
}
.cookie-bar {
    z-index: 1060;
    background-color: #FEFAF1;
}
.cookie-bar__text {
    color: #333331;
}
.cookie-modal {
    color: #343A40;
    z-index: 1070;
}
.cookie-modal__content {
    border-radius: 0;
    padding: 1.25rem;
}
.cookie-modal__header,
.cookie-modal__footer {
    border: none;
    padding: 0;
}
.cookie-modal__body {
    padding: 1rem 0;
}
.cookie-modal__title {
    font-family: "nunito-sans-extrabold", sans-serif;
    text-transform: uppercase;
    font-size: 1.375rem;
}
.cookie-toggle__input:checked + .cookie-toggle__switch-toggle {
    background: #ECD7A3;
}
.cookie-modal__close {
    border: none;
    background: none;
    text-align: left;
    padding: 0;
    position: relative;
    cursor: pointer;
}
.cookie-modal__close:before {
        font-family: iconfont;
        content: var(--icon-x);
        color: #333331;
    }
.cookie-toggle__label {
    font-size: 0.875rem;
}
.detail-grid {
    display: grid;
    gap: 1.5rem;
    grid-template-rows: 1fr auto auto;
}
.detail-grid--acco {
    display: grid;
    gap: 1.5rem;
    grid-template-rows: 1fr auto auto;
}
.detail-grid__wysiwyg-area {
    grid-row: 3 / 4;
}
.detail-grid__card-area {
    grid-row: 2 / 3;
}
.info-grid ~.detail-grid__image-area{

    grid-column:1 / 2;
    grid-row: 1 / 2;

}
.detail-grid__content {

}
.detail-grid__image-areas {
    display: block;
}
.detail-intro__infos {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 0.625rem;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    font-size: 1rem;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    margin-bottom: 0.625rem;
    list-style-type: none;
    padding-left: 0;
}
.detail-intro__info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.detail-intro__location {
    font-weight: 800;
    font-family: "nunito-sans-extrabold", sans-serif;
}
.detail-intro__title {
    font-size: 2rem;
    color: #2D343E;
    letter-spacing: 0;
    margin-bottom: 0.75rem;
}
.detail-intro__sub-title {
    font-weight: 400;
    font-family: "nunito-sans-regular", sans-serif;
    font-size: 1rem;
    margin-bottom: 1.125rem;
}
.detail-intro-image {
    position: relative;
}
.detail-intro-image__decorator {
    position: absolute;
    left: 0;
    bottom: 0;
    height: auto;
    top: inherit;
    width: 100%;
}
.detail-intro-image__decorator-img {
    width: 9.375rem;
}
.detail-intro-image__decorator-text {

    position: absolute;
    font-size: 0.625rem;
    height: 1.5rem;
    color: #fff;
    width: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    left: 0;
    bottom: 0.3125rem;
    line-height: 1;
    font-family: "nunito-sans-extrabold", sans-serif;
    font-weight: 700;
}
.detail-intro-image__decorator-text:before {
        content: var(--icon-card-inklusive);
        font-family: iconfont;
        color: #fff;
        font-size: 1rem;
        margin-left: -0.625rem;
        width: 2.25rem;
    }
.detail-intro__wysiwyg .h3 {
    font-size: 1.375rem;
}
.detail-intro__wysiwyg h2 {
    font-size: 1.5rem;
}
.detail-intro__wysiwyg p {
    margin-bottom: 0.625rem;
}
.detail-intro__wysiwyg ul {
    margin-bottom: 0.625rem;
}
/* Text Helper */
.text-truncate {
    white-space: nowrap;
    overflow: hidden;
    -o-text-overflow: ellipsis;
       text-overflow: ellipsis;
}
/* Text Color Helper */
.text-white { color: #ffffff; }
.text-color-default { color: #333331; }
.text-middle-grey { color: #7F7F7F; }
/* Background Color Helper */
.bg-wildschoenau {
    background-color: #014B94;
}
.bg-skijuwel {
    background-color: #E5332A;
}
.bg-alpbachtal {
    background-color: #ECD7A3;
}
.increased-click-area {
    padding: 0.5rem;
    margin: -0.5rem;
}
.fz12 {
    font-size: 0.75rem;
}
.fz14 {
    font-size: 0.875rem;
}
.fz16 {
    font-size: 1rem;
}
.fz20 {
    font-size: 1.25rem;
}
.fz24 {
    font-size: 1.5rem;
}
.w-20 {
    width: 1.25rem;
}
.w-30 {
    width: 1.875rem;
}
.font-default {
    font-family: "nunito-sans-regular", sans-serif;
}
.font-extra-bold {
    font-family: "nunito-sans-extrabold", sans-serif;
}
.box-shadow {
    -webkit-box-shadow: 1.25rem 1.25rem 2.5rem rgba(black, .24);
            box-shadow: 1.25rem 1.25rem 2.5rem rgba(black, .24);
}
.cursor-pointer {
    cursor: pointer;
}
.cookie-overlay-btn {
    position: absolute;
    z-index: 99;
    height: auto;
    width: auto;
    margin: 0 auto;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}
.text-line-through {
    text-decoration: line-through;
}
.text-shadow {
    text-shadow: 0 0 1.5rem rgba(black, .5);
}
.object-fit-cover {
    -o-object-fit: cover;
       object-fit: cover;
}
html {
    scroll-padding-top: 260px;
}
.bg-light-gray {
    background: #BEC2C6
}
.designstudio-button{
    display: none !important;
}
.filter-form__apply {
    height: 46px;
}
.title-block__title {
    font-size: 1.875rem;
}
h2, .h2 {
    font-size: 1.875rem;
}
h3, .h3 {
    font-size: 1.375rem;
}
h4, .h4 {
    font-size: 1.25rem;
}
h5, .h5 {
    font-size: 1.125rem;
}
h6, .h6 {
    font-size: 0.875rem;
}
.overlay .ribbon__item {
    color: black !important;
}
/* Components */
.form-control {
    border-top: none;
    border-left: none;
    border-right: none;
    font-size: 17px;
    font-family: "nunito-sans-bold", sans-serif;
    font-weight: 700;
}
.form-control:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px white inset !important;
}
/* gray */
.form-control--gray,
.form-control--gray:focus {
    /*todo vars*/
    background-color: #f2f2f2;
    border-top: 1px solid #d8d8d8;
    border-left: 1px solid #d8d8d8;
    border-right: 1px solid #d8d8d8;
}
.form-control--gray:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px #f2f2f2 inset !important;
}
/*Input groups*/
.input-group > .form-control {
    background-color: inherit;
}
/*Gray Input groups*/
.input-group--gray {
    background-color: #f2f2f2;
    border-top: 1px solid #d8d8d8;
    border-left: 1px solid #d8d8d8;
    border-right: 1px solid #d8d8d8;
}
/*input group add ons*/
.input-group-addon {
    border-top: none;
    border-left: none;
    border-right: none;
}
.input-group > .input-group-addon {
    background-color: inherit;
}
.form-control:focus ~ .input-group-addon {
    border-bottom-color: #ECD7A3;
}
.form-control:focus ~ .input-group-addon {
    border-bottom-color: #ECD7A3;
}
.has-error .input-group-addon.input-group-addon {
    border-bottom-color: #DC3545;
}
.has-success .input-group-addon.input-group-addon {
    border-bottom-color: #499F68;
}
.form-select-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
}
.form-select {
    -webkit-appearance: none;
    -moz-appearance: none;
    cursor: pointer;
    background-image: url(data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3E%3C/svg%3E);
    background-repeat: no-repeat;
    background-position: right 1.25rem center;
    background-size: 16px 12px;

}
.form-control, .form-select {
    font-size: 0.875rem;
    padding: 0.6875rem 1.25rem;
    border-radius: 3.3125rem;
    width: 100%;
    line-height: 1.357;
    -webkit-box-shadow: 20px 20px 40px 0 rgb(51 51 49 / 12%);
            box-shadow: 20px 20px 40px 0 rgb(51 51 49 / 12%);
    background-color: #fff;
    border: none;
    height: 48px;
}
.form-select__select-icon {
    position: absolute;
    font-size: 7px;
    right: 14px;
    top: 18px;
}
/*form errors*/
.form-errors {
    color: #DC3545;
}
.form-errors .parsley-errors-list {
    display: block;
    list-style-type: none;
    padding: 0;
    margin: 0;
    text-align: left;
    margin-top: 4px;
    font-size:12px;
}
.form-errors .parsley-errors-list:not(.filled) {
    display: none;
}
.form-label {
    font-weight: var(--font-weight-bold-extra-bold);
    color: #989691;
    text-transform: uppercase;
    letter-spacing: .25rem;
    margin-left: 0.75rem;
    margin-bottom: 0.375rem;
    font-size: .75rem;
    font-family: "nunito-sans-extrabold", sans-serif;

}
textarea.form-control {
    height: auto;
    display:block;
    border-radius: 24px;
}
.nearby-search-form-group__label {
    padding: 1.0625rem 1.25rem 0 1.25rem;
    letter-spacing: 0;
    color: #333331;
    font-size: 0.625rem;
    display: block;
}
.nearby-search-form-group .nearby-search-form-group__input {
    -webkit-box-shadow: none;
            box-shadow: none;
    color: #000;
    margin-left: 1.25rem;
    background-color: transparent;
    font-weight: 700;
    font-size: 0.75rem;
    padding-left: 0;
}
.nearby-search-form-group .nearby-search-form-group__input::-webkit-input-placeholder {
    color: #000;
}
.nearby-search-form-group .nearby-search-form-group__input::-moz-placeholder {
    color: #000;
}
.nearby-search-form-group .nearby-search-form-group__input::-ms-input-placeholder {
    color: #000;
}
.nearby-search-form-group .nearby-search-form-group__input::placeholder {
    color: #000;
}
.btn,
button {
    cursor: pointer;
}
.btn-default {
    color: #333;
    background-color: #E6E6E6;
}
.btn-default.active {
    color: #ffffff;
    background-color: #ECD7A3;
}
.btn-link:hover {
    text-decoration: underline;
}
.btn__icon {
    font-size: 16px;
    vertical-align: -.2em;
}
.btn__icon.demi-icon-arrow-right {
    font-size: 0.94em;
    vertical-align: -0.1em;
}
.btn__icon.demi-icon-facebook {
    font-size: 20px;
}
.btn__icon.demi-icon-person {
    vertical-align: -.15em;
}
.btn__icon.demi-icon-cart {
    vertical-align: -.1em;
}
.btn-white {
    background: white;
    color: #595959;
}
.btn-white:hover,
.btn-white:focus,
.btn-white:active {
    color: black;
}
.icon-btn {
    line-height: 1;
}
.close-btn {
    position: absolute;
    right: 15px;
    top: 15px;
    z-index: 1;
    line-height: 1;
}
.btn-facebook,
.btn-facebook:hover,
.btn-facebook:focus {
    background: #3B5998;
    color: #ffffff;
}
.btn-google,
.btn-google:hover,
.btn-google:focus {
    background: #d34836;
    color: #ffffff;
}
.btn-show-gallery-container {
    position: relative;
}
.btn-show-gallery {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 40px;
    height: 40px;
    background: rgb(255 255 255 / 60%);
    -webkit-backdrop-filter: blur(20px);
            backdrop-filter: blur(20px);
    border-radius: 4px;
    color: #ECD7A3;
    font-size: 16px;
}
.btn-show-gallery__icon {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}
.btn-show-gallery--small {
    width: 30px;
    height: 30px;
    font-size: 16px;
}
.btn-breakable {
    white-space: normal;
}
.btn {
    border-radius: 3.3125rem;
    letter-spacing: 0.125rem;
    padding: 1rem 1.4375rem;
    line-height:1;
    text-transform: uppercase;
    font-size: 0.875rem;
    font-weight: 700;
}
.btn-outline-dark {
    border: 1px solid #000;
    color: #333;
    background-color:#fff;
}
.btn-sm {
    padding-top: .25rem;
    padding-bottom: .25rem;
    font-size: 0.75rem;
    letter-spacing: 1px;
}
.btn-lg {
    padding-top: 20px;
    padding-bottom: 20px;
}
.btn-lg {
    padding-top: 20px;
    padding-bottom: 20px;
}
.btn-xs {
    font-size: 10px;
    letter-spacing: 0.5px;
    padding: 0.625rem;
}
.toggle-btn,
.toggle-btn-wrapper {
    max-width: 100%;
}
.toggle-btn__icon {
    width: 20px;
    text-align: left;
    -webkit-transition: opacity 120ms, -webkit-transform 150ms cubic-bezier(0.16, 0.46, 0.83, 1.36);
    transition: opacity 120ms, -webkit-transform 150ms cubic-bezier(0.16, 0.46, 0.83, 1.36);
    -o-transition: opacity 120ms, transform 150ms cubic-bezier(0.16, 0.46, 0.83, 1.36);
    transition: opacity 120ms, transform 150ms cubic-bezier(0.16, 0.46, 0.83, 1.36);
    transition: opacity 120ms, transform 150ms cubic-bezier(0.16, 0.46, 0.83, 1.36), -webkit-transform 150ms cubic-bezier(0.16, 0.46, 0.83, 1.36);
    -webkit-transform: scale(0);
            transform: scale(0);
    color: #ffffff; /*todo*/
    vertical-align: middle;
}
.toggle-btn--has-icon .toggle-btn__content {
    display: inline-block;
    -webkit-transition: -webkit-transform 120ms;
    transition: -webkit-transform 120ms;
    -o-transition: transform 120ms;
    transition: transform 120ms;
    transition: transform 120ms, -webkit-transform 120ms;
    -webkit-transform: translateX(-10px);
            transform: translateX(-10px);
    vertical-align: middle;
    white-space: normal;
}
.toggle-btn--has-icon.toggle-btn--aligned-left .toggle-btn__content {
    -webkit-transform: translateX(-20px);
            transform: translateX(-20px);
}
/*active button*/
input:checked + .toggle-btn {
    color: #ffffff;
    background-color: #ECD7A3;
}
input[disabled] + .toggle-btn {
    opacity: .6;
}
input:checked + .toggle-btn--has-icon .toggle-btn__icon {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
}
input:checked + .toggle-btn--has-icon .toggle-btn__content {
    -webkit-transform: translateX(0);
            transform: translateX(0);
}
/*hightlighted variant*/
.toggle-btn--highlighted {
    color: #ffffff;
    background-color: #ECD7A3;
}
input:checked + .toggle-btn--highlighted {
    background-color: #499F68;
}
.overlay {
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    padding-top: 0;
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    -webkit-transition: visibility 0.001ms 180ms, opacity 180ms ease, -webkit-transform 180ms ease-out;
    transition: visibility 0.001ms 180ms, opacity 180ms ease, -webkit-transform 180ms ease-out;
    -o-transition: transform 180ms ease-out, visibility 0.001ms 180ms, opacity 180ms ease;
    transition: transform 180ms ease-out, visibility 0.001ms 180ms, opacity 180ms ease;
    transition: transform 180ms ease-out, visibility 0.001ms 180ms, opacity 180ms ease, -webkit-transform 180ms ease-out;
    background: white;
    z-index: 1100;
    overflow: hidden;
    will-change: transform;
    visibility: hidden;
    opacity: 0;
}
/* vertically and horizontally centered overlay*/
.overlay.overlay--centered {
    bottom: auto;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-60%);
            transform: translate(-50%,-60%);
    width: 500px;
}
.overlay.overlay--centered.is-open {
    -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
}
/* horizontally centered overlay*/
.overlay.overlay--centered-top {
    bottom: auto;
    top: 50px;
    left: 50%;
    width: 500px;
    -webkit-transform: translate(-50%,-10%);
            transform: translate(-50%,-10%);
}
.overlay.overlay--centered-top.is-open {
    -webkit-transform: translate(-50%,0);
            transform: translate(-50%,0);
}
.overlay--centered > .overlay__content,
.overlay--centered-top > .overlay__content {
    position: static;
    min-height: 530px;
}
/* all overlays in a centered one must have full width */
.overlay.overlay--centered .overlay,
.overlay.overlay--centered-top .overlay{
    width: 100%;
    left: 0;
    right: 0;
}
.overlay.is-open {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    visibility: visible;
    opacity: 1;
    -webkit-transition: visibility 0.001ms, opacity 180ms ease, -webkit-transform 180ms ease-out;
    transition: visibility 0.001ms, opacity 180ms ease, -webkit-transform 180ms ease-out;
    -o-transition: transform 180ms ease-out, visibility 0.001ms, opacity 180ms ease;
    transition: transform 180ms ease-out, visibility 0.001ms, opacity 180ms ease;
    transition: transform 180ms ease-out, visibility 0.001ms, opacity 180ms ease, -webkit-transform 180ms ease-out;
}
.overlay__content {
    position: absolute;
    left: 0;
    right: 0;
    top: 60px;
    bottom: 0;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}
.overlay__header {
    height: 60px;
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    position: relative;
    z-index: 500;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    font-size: 17px;
    background-color: #ECD7A3;
    color: #fff;
    font-family: "nunito-sans-bold", sans-serif;
    font-weight: 700;
}
.overlay__header-item {
    padding: 20px;
    min-width: 0; /*to allow text-truncate*/
    color: black;
}
.overlay__header-item + .overlay__header-item--bordered {
    border-left: 1px solid #E6E6E6;
}
.overlay__header-submit-btn {
    color: #ECD7A3;
}
.overlay__header-submit-btn:disabled,
.overlay__header-submit-btn.disabled {
    color: #bfbfbf;
}
.form-box {
    background: white;
    text-align: left;
    display: block;
    width: 100%;
    border: none;
    padding: 0;
    line-height: 1;
    position: relative;
    cursor: pointer;
    border-radius: 4px;
}
.form-box .form-control{
    cursor: pointer;
}
.form-box__label, .form-label {
    display: block;
    color: #989691;
    padding: 0.375rem 0.625rem;
    letter-spacing: 0.125rem;
    font-family: "nunito-sans-extrabold", sans-serif;
    font-weight: 800;
    text-transform: uppercase;
    font-size: 0.75rem;
}
.form-box__content{
    display: block;
    font-size: 14px;
    padding: 1rem 1.25rem;
    height: 3rem;
}
.form-box__content,
.form-box__content:focus,
.form-box__content[readonly]{
    background: transparent;
}
.form-box input,
.form-box select {
    padding-left: 10px;
    padding-right: 10px;
}
/*styling adjustments for form-controls & input-groups*/
textarea.form-box__content,
select.form-box__content,
input.form-box__content,
.form-control.form-box__content {
    border-top: none;
    border-right: none;
    border-left: none;
    width: 100%;
    margin-bottom: -1px;
}
select.form-box__content,
select.form-box__content:not([size]):not([multiple]), /*override bootstrap selector*/
input.form-box__content,
.form-box__content.input-group,
.form-box__content.input-group input,
.form-box__content.input-group select,
.form-box__content.input-group select:not([size]):not([multiple]) /*override bootstrap selector*/ {
    height: 36px;
}
select.form-box__content:focus,
select.form-box__content,
.form-box__content select:focus,
.form-box__content select {
    padding-top: 2px;
    padding-bottom: 6px;
    padding-right: 30px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
select.form-box__content::-ms-expand,
.form-box__content select::-ms-expand {
    display: none;
}
.form-box__content.input-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 0;
    background: transparent;
    margin-bottom: -1px;
}
.form-box__content.input-group select {
    padding-top: 6px;
    padding-bottom: 2px;
}
/*icon*/
.form-box__icon {
    position: absolute;
    right: 16px;
    bottom: 16px;
    font-size: 16px;
}
.form-box__icon.demi-icon-chevron-down {
    font-size: 6px;
    bottom: 18px;
}
.form-box__select-icon {
    position: absolute;
    bottom: 15px;
    right: 10px;
    pointer-events: none;
    font-size: 7px;
}
.form-box__select-icon--small {
    font-size: 5px;
}
/*collapsible*/
.form-box--collapsible:not(.collapsed) {
    border-bottom-color: #ECD7A3;
}
.form-box--collapsible:not(.collapsed) .form-box__content {
    color: #ECD7A3;
}
.form-box--collapsible .form-box__icon {
    -webkit-transition: -webkit-transform 120ms ease-out;
    transition: -webkit-transform 120ms ease-out;
    -o-transition: transform 120ms ease-out;
    transition: transform 120ms ease-out;
    transition: transform 120ms ease-out, -webkit-transform 120ms ease-out;
}
.form-box--collapsible:not(.collapsed) .form-box__icon {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}
.form-box__detail {
    background: #ffffff;
}
/*gray*/
.form-box--gray {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.form-box--gray .form-control{
    font-size: .875rem;
    padding: 0.6875rem 1.25rem;
    border-radius: 3.3125rem;
    width: 100%;
    line-height: 1.357;
    -webkit-box-shadow: 20px 20px 40px 0 rgb(51 51 49 / 12%);
    box-shadow: 20px 20px 40px 0 rgb(51 51 49 / 12%);
    background-color: #fff;
    border: none;
    height: 48px;
}
.form-box--gray .form-control:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px #f2f2f2 inset !important;
}
.form-control--datepicker:disabled, .form-control--datepicker[readonly] {
    background-color: #fff;
}
/*readonly*/
.form-box--readonly {
    opacity: 0.6;
}
/*error state*/
.form-box:after {
    content: "\EA5D";
    font-family: "demiIcons";
    color: #DC3545;
    position: absolute;
    top: 9px;
    right: 7px;
    font-size: 12px;

    display: inline-block;
    speak: none;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    -webkit-transform: scale(0);

            transform: scale(0);
    -webkit-transform-origin: center;
            transform-origin: center;
    opacity: 0;
    -webkit-transition: opacity 120ms, -webkit-transform 120ms cubic-bezier(.16,.46,.83,1.36);
    transition: opacity 120ms, -webkit-transform 120ms cubic-bezier(.16,.46,.83,1.36);
    -o-transition: opacity 120ms, transform 120ms cubic-bezier(.16,.46,.83,1.36);
    transition: opacity 120ms, transform 120ms cubic-bezier(.16,.46,.83,1.36);
    transition: opacity 120ms, transform 120ms cubic-bezier(.16,.46,.83,1.36), -webkit-transform 120ms cubic-bezier(.16,.46,.83,1.36);
}
.has-error .form-box:after {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
}
.has-error .form-box__content,
.has-error .input-group .form-control {
    border-color: #DC3545;
}
/*success state*/
.has-success .form-box__content,
.has-error .input-group .form-control {
    border-color: #499F68;
}
.form-box__addon-button {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    line-height: 0;
    border-radius: 50%;
    height: 48px;
    width: 48px;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 0;
    margin: 6px;
    color: #fff;
    font-size: 17px;
}
.form-box--round {
    border-radius: 53px;
    background-color: #F7F7F6;
    -webkit-box-shadow: 0 0 10px 0 rgba(51,51,49,0.24);
            box-shadow: 0 0 10px 0 rgba(51,51,49,0.24);
}
.card .form-control, .card .form-select {
    background-color: rgba(0,0,0,0.06);
    -webkit-box-shadow: none;
            box-shadow: none;
}
.form-box--border {
    border: 1px solid #333331;
    border-radius: 53px;
    background-color: transparent;
}
.form-box--border .form-control {
    background-color: transparent;
    height: 48px !important;
    padding: 15px 20px;
}
.form-box--border .form-box__select-icon {
    bottom: 1.1875rem;
    right: 1rem;
}
.ribbon {
    background: #ECD7A3;
}
.ribbon__item {
    position: relative;
    background: #ECD7A3;
    color: white;
    padding: 11px 12px;

    display: -webkit-box;

    display: -ms-flexbox;

    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
}
.ribbon__title {
    font-size: 18px;
    font-weight: 500;
}
.ribbon__item--has-flag:before {
    content:"";
    bottom: -15px;
    display:block;
    position:absolute;
    left: 0;
    border-width: 15px 0 0 15px;
    border-style: solid;
    border-color: #ECD7A3 transparent transparent transparent;
    z-index:-1;
}
.ribbon__item--flap {
    margin-left: -0.4375rem;
    margin-right: -0.4375rem;
}
.ribbon__item--flap:after, .ribbon__item--flap:before {
    content: "";
    position: absolute;
    bottom: 100%;
    width: 0;
    height: 0;
    border-style: solid;
}
.ribbon__item--flap:before {
    left: 0;
    border-width: 0 0 0.4375rem 0.4375rem;
    border-color: transparent transparent #ECD7A3;
}
.ribbon__item--flap:after {
    right: 0;
    border-width: 0.4375rem 0 0 0.4375rem;
    border-color: transparent transparent transparent #ECD7A3;
}
.custom-checkbox {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: baseline;
        -ms-flex-align: baseline;
            align-items: baseline;
    cursor: pointer;
}
.custom-checkbox__input {
    /* screen reader only*/
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}
.custom-checkbox__box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    border: 1px solid #c4c6c7;
    background: #ffffff;
    width: 16px;
    height: 16px;
    border-radius: 3px;
    /* small vertical alignment adjustment;
     vertical align is not working in flex children */
    position: relative;
    /* vertical align for smaller and bigger font-sizes*/
    top: calc(7px - 0.3em);
}
.custom-checkbox__box:before {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    font-size: 7px;
    color: #ffffff;
    visibility: hidden;
}
.custom-checkbox__input:focus ~ .custom-checkbox__box {
    border-color: #ECD7A3;
}
.custom-checkbox__input:checked ~ .custom-checkbox__box {
    background: #ECD7A3;
    border-color: #ECD7A3;
}
.custom-checkbox__input:checked ~ .custom-checkbox__box.custom-checkbox__box--success,
.custom-checkbox__input:checked ~ .row .custom-checkbox__box.custom-checkbox__box--success {
    background: #499F68;
    border-color: #499F68;
    color: #fff;
}
.custom-checkbox__input:checked ~ .custom-checkbox__box:before {
    visibility: visible;
}
.custom-checkbox__input:checked ~ .row .custom-checkbox__box:before {
    visibility: visible;
}
.custom-checkbox--highlight-text .custom-checkbox__input:checked ~ .custom-checkbox__text {
    color: #ECD7A3;
}
.has-error .custom-checkbox__box {
    border-color: #DC3545
}
.custom-checkbox__text {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 0%;
            flex: 1 1 0%;
    line-height: 1;
    min-width: 0;
}
.custom-radio {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: baseline;
        -ms-flex-align: baseline;
            align-items: baseline;
    cursor: pointer;
}
.custom-radio__input {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}
.custom-radio__box {
    position: relative;
    float: left;
    margin-right: 7px;
    overflow: hidden;
    border: 1px solid #c4c6c7;
    background: #ffffff;
    width: 16px;
    height: 16px;
    margin-top: .1em;
    border-radius: 50%;
}
.custom-radio__box:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 60%;
    height: 60%;
    border-radius: 50%;
    background: #ECD7A3;
    visibility: hidden;
}
.custom-radio__input:checked ~ .custom-radio__box:before {
    visibility: visible;
}
.custom-radio__input:checked ~ .custom-radio__text {
    color: #ECD7A3;
}
.custom-radio__input:focus ~ .custom-radio__box {
    border-color: #ECD7A3;
}
.has-error .custom-radio__box {
    border-color: #DC3545
}
.custom-radio__text {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 0%;
            flex: 1 1 0%;
    line-height: 1;
    min-width: 0;
}
.custom-radio.custom-radio--full-width label{
    display: block;
}
.custom-radio-wrapper label {
    cursor: pointer;
}
.number-spinner {
    display: inline-block;
    border-radius: 4px;
    overflow: hidden;
}
.number-spinner__button {
    background: #ECD7A3;
    color: #fff;
    font-size: 20px;
    padding: 3px 9px;
    line-height: 1;
}
.number-spinner__input,
.input-group .number-spinner__input {
    width: 50px;
    min-width: 0;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 51px;
            flex: 0 0 51px;
    text-align: center;
    -moz-appearance: textfield;
    -webkit-appearance: textfield;
    padding: 0;
}
.number-spinner__input::-webkit-inner-spin-button,
.number-spinner__input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.number-spinner.demi-number-spinner .number-spinner__button,
.number-spinner.demi-number-spinner .number-spinner__input {
    border: none;
    border-bottom: 1px solid #bfbfbf;
}
.number-spinner.demi-number-spinner .number-spinner__button {
    background: white;
}
.number-spinner.demi-number-spinner .number-spinner__input {
    background: transparent;
    margin-left: 5px;
    margin-right: 5px;
}
.number-spinner .number-spinner__input.form-control {
    height: 28px;
    background: #fff;
    border-top: 1px solid #ECD7A3;
    border-bottom: 1px solid #ECD7A3;
}
.datepicker-legend {

}
.datepicker-legend__cell {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    vertical-align: -.1em;
}
.datepicker-legend__cell--available {
    background-color: #4b712c;
}
.datepicker-legend__cell--available-no-arrival {
    background-color: #7fb055;
}
.datepicker-legend__cell--not-available {
    border: 1px dashed #595959;
}
.datepicker-legend__cell--departure-only {
    background-color: #7fb055;
    border-radius: 0 20px 20px 0;
}
/*Datepicker*/
.ui-datepicker:not(.ui-datepicker-inline) {
    background: #ffffff;
    -webkit-box-shadow: 0 2px 3px rgba(0, 0, 0, 0.27);
            box-shadow: 0 2px 3px rgba(0, 0, 0, 0.27);
    z-index: 20000;
}
.ui-datepicker-inline {
    width: auto;
    max-width: 100%;
    margin: auto;
}
.ui-datepicker-inline table {
    font-size: 1em;
}
.ui-datepicker table {
    table-layout: fixed;
}
.ui-datepicker .ui-datepicker-title {
    font-size: 17px;
}
.ui-datepicker th {
    /* todo: color var?*/
    color: #8c8c8c;
    font-weight: normal;
    /*font-family: var(--font-default-medium);*/
    font-size: 13px;
}
.ui-datepicker td {
    padding: 0;
}
.ui-datepicker td {
    border-left: 1px solid white;
    border-bottom: 1px solid white;
}
.ui-datepicker td:first-child {
    border-left: none;
}
.ui-datepicker tr:last-child td {
    border-bottom: none;
}
.ui-datepicker td a,
.ui-datepicker td span {
    position: relative;
    text-align: center;
    background: none;
    padding: 9px 4px;
    /*todo: own color var? */
    color: #333333;
    font-size: 15px;
}
.ui-datepicker .ui-state-active {
    background-color: #ECD7A3;
    border-radius: 50px;
    color: #fff;
}
.datepicker--range .ui-state-active {
    background-color: transparent;
    color: inherit;
}
.ui-datepicker .is-checkin > a,
.ui-datepicker .is-checkin > span {
    background-color: #ECD7A3;
    border-radius: 20px 0 0 20px;
    color: #fff;
}
.ui-datepicker .is-in-range > a {
    background-color: rgba(236, 215, 163, 0.55);
    color: #fff;
}
.ui-datepicker .is-checkout > a {
    background-color: #ECD7A3;
    border-radius: 0 20px 20px 0;
    color: #fff;
}
.ui-datepicker .is-departure {
    position: relative;
}
.ui-datepicker .is-in-hover-range {
    background-color: rgba(236, 215, 163, 0.1);
}
.ui-datepicker .is-hover-range-end:not(.is-checkin) > a {
    background-color: rgba(236, 215, 163, 0.1);
    border-radius: 0 20px 20px 0;
}
.ui-datepicker .is-available > a {
    background-color: #4b712c;
    color: #ffffff;
    border-radius: 0;
}
.ui-datepicker .is-available.is-no-arrival > a {
    background-color: #7fb055;
}
.ui-datepicker .is-not-available > a {
    opacity: .35;
}
.ui-datepicker .is-arrival-only > a,
.ui-datepicker .is-departure-only > a {
    position: relative;
    z-index: 0;
    /*background-color: var(--color-danger);*/
    color: #ffffff;
    opacity: 1;
    background: transparent;
}
.ui-datepicker .is-arrival-only > a:after,
.ui-datepicker .is-departure-only > a:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #7fb055;
    border-radius: 20px 0 0 20px;
    z-index: -1;
}
.ui-datepicker .is-departure-only > a:after {
    border-radius: 0 20px 20px 0;
}
/*.ui-datepicker .ui-state-highlight {*/
/*background: rgba(140,15,0,0.5);*/
/*color: #ffffff;*/
/*}*/
/*.ui-datepicker .ui-state-arrival-date a,*/
/*.ui-datepicker .ui-state-arrival-date span,*/
/*.ui-datepicker .ui-state-hover,*/
.ui-datepicker-calendar .ui-state-disabled {
    opacity: 1;
}
.ui-datepicker-calendar .ui-state-disabled span,
.ui-datepicker-calendar .ui-state-disabled a {
    opacity: .35;
}
.ui-datepicker-other-month.ui-state-disabled {
    visibility: hidden;
}
.ui-datepicker-prev.ui-state-hover,
.ui-datepicker-next.ui-state-hover{
    background: none;
    color: #000000;
}
.ui-datepicker-prev span,
.ui-datepicker-next span{
    display: none!important;
}
.ui-datepicker-next:after {
    display: inline-block;
    speak: none;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
    content: "\EA05";
    font-family: "demiIcons";
}
.ui-datepicker-prev:after{
    display: inline-block;
    speak: none;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\EA05";
    font-family: "demiIcons";
    -webkit-transform: scale(-1, 1);
    transform: scale(-1, 1);
}
.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-next {
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    cursor: pointer;
    width: auto;
    height: auto;
    line-height: 0;
    color: #595959;
}
.ui-datepicker .ui-datepicker-next.ui-state-disabled,
.ui-datepicker .ui-datepicker-prev.ui-state-disabled {
    opacity: .35;
}
.ui-datepicker.ui-datepicker-multi {
    width: auto!important;
    padding: 0 10px;
}
.ui-datepicker .ui-datepicker-today {
    position: relative;
}
.ui-datepicker .ui-datepicker-today:after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    border-bottom: 1px solid #ECD7A3;
}
.date-range-selected > .ui-state-active,
.date-range-selected > .ui-state-default {
    background: none;
    background-color: #d4a29b;
}
.datepicker--group-loading .ui-datepicker-group {
    position: relative;
    z-index: 0;
}
.datepicker--group-loading .ui-datepicker-group:after {
    content: '';
    position: absolute;
    top: 115px;
    left: 50%;
    margin-left: -10px;
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border-top: 2px solid #ECD7A3;
    border-right: 2px solid transparent;
    -webkit-animation: circle-spinner .6s linear infinite;
            animation: circle-spinner .6s linear infinite;
    z-index: -1;
}
.datepicker--group-loading td {
    background: #fff;
}
.datepicker--static .ui-datepicker-calendar a {
    cursor: default;
    border-radius: 4px;
}
.teaser {
    line-height: 1.1;
    padding-right: 15px;
}
.teaser__img {
    background-color: #f2f2f2;
    -webkit-box-flex: 1;
        -ms-flex: auto;
            flex: auto;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}
.teaser__label {
    -webkit-box-flex: 0;
        -ms-flex: none;
            flex: none;
    /*font-size small*/
    font-size: 84.61538%;
    padding: .25rem;
}
.teaser__body {
    position: relative;
    min-height: 83px;
}
.teaser__main-body {
    padding-right: 5px;
}
.teaser__arrow {
    color: #ECD7A3;
    position: absolute;
    top: 50%;
    right: -5px;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    font-size: 16px;
}
.teaser__collapse-arrow {
    color: #ECD7A3;
    position: absolute;
    top: 17px;
    right: -5px;
    font-size: 6px;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    -webkit-transition: 120ms ease-out;
    -o-transition: 120ms ease-out;
    transition: 120ms ease-out;
}
.teaser__collapse-arrow:hover,
.teaser__collapse-arrow:focus {
    text-decoration: none;
}
.teaser__collapse-arrow.collapsed {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
}
.teaser__price {
    font-size: 17px;
}
.teaser--body-padding .teaser__body {
    padding-top: 10px;
    padding-bottom: 10px;
}
.teaser__detail-btn {
    position: relative;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    -webkit-transition: -webkit-transform 100ms ease-out;
    transition: -webkit-transform 100ms ease-out;
    -o-transition: transform 100ms ease-out;
    transition: transform 100ms ease-out;
    transition: transform 100ms ease-out, -webkit-transform 100ms ease-out;
    padding: 0;
    background-color: #D34442; 
    color:#fff;
}
.teaser__detail-btn .demi-icon {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
    color: #fff;
    font-size: 12px;
}
.teaser__detail-btn .demi-icon.demi-icon-chevron-down,
.teaser__detail-btn .demi-icon.demi-icon-chevron-up {
    font-size: 9px;
}
.teaser.collapsed .teaser__detail-btn {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}
.teaser__arrow.demi-icon.demi-icon-chevron-down,
.teaser__arrow.demi-icon.demi-icon-chevron-up {
    font-size: 8px;
}
.teaser__arrow {
    -webkit-transition: -webkit-transform 100ms ease-out;
    transition: -webkit-transform 100ms ease-out;
    -o-transition: transform 100ms ease-out;
    transition: transform 100ms ease-out;
    transition: transform 100ms ease-out, -webkit-transform 100ms ease-out;
}
.teaser.collapsed .teaser__arrow {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}
.teaser__show-on-map {
    font-size:12px;
}
.teaser__show-on-map:hover .media-body {
   text-decoration: underline;
}
.teaser__main-title {
    word-break: break-word;
}
.card-teaser {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}
.card-teaser__title {
    //font-size: 22px;
}
.card-teaser__img {
    background-color: #f2f2f2;
    -webkit-box-flex: 1;
        -ms-flex: auto;
            flex: auto;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}
.card-teaser__label {
    -webkit-box-flex: 0;
        -ms-flex: none;
            flex: none;
    /*font-size small*/
    font-size: 84.61538%;
    padding: .25rem;
}
.card-teaser__price {
    font-size: 17px;
}
.card-teaser__show-on-map {
    color: #ECD7A3;
}
.card-teaser__detail-btn {
    position: relative;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    -webkit-transition: -webkit-transform 100ms ease-out;
    transition: -webkit-transform 100ms ease-out;
    -o-transition: transform 100ms ease-out;
    transition: transform 100ms ease-out;
    transition: transform 100ms ease-out, -webkit-transform 100ms ease-out;
}
.card-teaser__detail-btn .demi-icon {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
    color: #595959;
    font-size: 16px;
}
.rating {
    line-height: 1;
    font-family: "nunito-sans-bold", sans-serif;
    font-weight: 700;
    color: #3498DB;
}
.rating__score {
    position: relative;
    display: inline-block;
    padding: 2px 4px;
    background: #3498DB;
    color: #ffffff;
    margin-right: 2px;
    margin-bottom: 5px;
}
.rating__score:after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 5px 0 0;
    border-color: #3498DB transparent transparent transparent;
    color: #3498DB;
    position: absolute;
    top: 100%;
    left: 0;
    margin-top: -1px;
}
.rating__count {
    margin-left: .25rem;
    font-family: "nunito-sans-regular", sans-serif;
    font-weight: 400;
}
.rating--white {
    color: #ffffff;
}
.rating--md .rating__score {
    width: 45px;
    height: 44px;
    font-size: 22px;
    text-align: center;
    padding: 11px 5px 0;
    vertical-align: top;
    margin-right: 5px;
}
.rating--md .rating__score:after {
    border-width: 6px;
    border-color: #3498DB transparent transparent #3498DB;
}
.rating--md .rating__content {
    display: inline-block;
    vertical-align: top;
}
.rating--md .rating__text {
    color: #505050;
    font-size: 19px;
}
.rating--md .rating__count {
    font-size: 11px;
    margin-left: 0;
}
.rating--md .rating-stars {
    margin-top: 1px;
}
.rating-stars {
    font-size: 0;
}
.rating-star {
    position: relative;
    display: inline-block;
    color: #3498DB;
    font-size: 12px;

    font-family: "demiIcons";
    display: inline-block;
    speak: none;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
}
.rating-star:before {
    content: "\EA52";
}
.rating-star--half,
.rating-star--empty {
    color: #D8D8D8;
}
.rating-star--half:after {
    position: absolute;
    left: 0;
    content: "\EA52";
    color: #3498DB;
    width: 7px;
    width: calc(50% + 1px);
    overflow: hidden;
}
.trustyou-logo {
    width: 96px;
    height: 10px;
    background-image: url(/static/demi/debug/img/trustyou-logo.png);
    background-repeat: no-repeat;

}
.loading-spinner {
    margin-left: auto;
    margin-right: auto;
    width: 70px;
    text-align: center;
}
.loading-spinner__item {
    width: 18px;
    height: 18px;
    background-color: #ECD7A3;

    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
            animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}
.loading-spinner__item--1 {
    -webkit-animation-delay: -0.32s;
            animation-delay: -0.32s;
}
.loading-spinner__item--2 {
    -webkit-animation-delay: -0.16s;
            animation-delay: -0.16s;
}
@-webkit-keyframes sk-bouncedelay {
    0%, 80%, 100% {
        -webkit-transform: scale(0);
                transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1.0);
                transform: scale(1.0);
    }
}
@keyframes sk-bouncedelay {
    0%, 80%, 100% {
        -webkit-transform: scale(0);
                transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1.0);
                transform: scale(1.0);
    }
}
.hide-loading-text .loading-spinner__text{
    display: none;
}
.circle-spinner {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border-top: 2px solid #ECD7A3;
    border-right: 2px solid transparent;
    -webkit-animation: circle-spinner .6s linear infinite;
            animation: circle-spinner .6s linear infinite;
}
.circle-spinner--small {
    width: 14px;
    height: 14px;
}
.circle-spinner--white {
    border-top-color: white;
}
@-webkit-keyframes circle-spinner {
    to {-webkit-transform: rotate(360deg);transform: rotate(360deg);}
}
@keyframes circle-spinner {
    to {-webkit-transform: rotate(360deg);transform: rotate(360deg);}
}
.classification_holder .classification__superior {

}
.card-ribbon {
    position: relative;
    margin-left: -5px;
    margin-right: -5px;
    padding: 13px 20px;
    background: #ECD7A3;
    color: #fff;
}
.card-ribbon:before {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 5px 5px;
    border-color: transparent transparent rgb(134, 134, 134) transparent;
}
.card-ribbon:after {
    content: '';
    position: absolute;
    bottom: 100%;
    right: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 0 0 5px;
    border-color: transparent transparent transparent rgb(134, 134, 134);
}
.card-lightgrey {
    background-color: #f6f6f6;
}
.card__info-row .demi-icon{
    margin-top: 0.125rem;
}
.acco-result-card {
    border: 1px solid #DFDFDF;
    border-radius: 6px;
    padding: 1.25rem;
}
.acco-result-card {
    border: 1px solid #DFDFDF;
    border-radius: 6px;
    padding: 1.25rem;
}
.figure {
    display: block;
    position: relative;
}
.figure__overlay {
    position: absolute;
    bottom: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    max-width: 100%;
    padding: 10px;
    background: #000;
    background: rgba(0,0,0,.6);
    color: #ffffff;
}
.figure__right-overlay {
    position: absolute;
    right: .25rem;
    bottom: .5rem;
    background: #000;
    background-color: rgba(0,0,0,0.5);
    color: #ffffff;
    border-radius: 4px;
    padding: .75rem;
    min-width: 40px;
    text-align: center;
}
/*todo name*/
.figure__item {
    position: absolute;
    top: 0;
    left: 0;
}
.figure__item--right {
    left: auto;
    right: 0;
}
.figure__item--bottom {
    top: auto;
    bottom: 0;
}
.step-progress__bar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.step-progress__bar-item {
    height: 100%;
    -webkit-box-flex: 1;
        -ms-flex: 1 1 0px;
            flex: 1 1 0%;
}
.wysiwyg p:last-child,
.wysiwyg ul:last-child,
.wysiwyg ol:last-child {
    margin-bottom: 0;
}
.wysiwyg ul,
.list-default {
    text-indent: -15px;
    padding-left: 15px;
    list-style-type: none;
}
.wysiwyg ul > li + li,
.list-default > li + li {
    margin-top: 3px;
}
.wysiwyg ul > li:before,
.list-default > li:before {
    content: '';
    display: inline-block;
    width: 5px;
    height: 5px;
    background: #8C8C8C;
    border-radius: 50%;
    padding-left: 0;
    margin-right: 10px;
    list-style-type: none;
    vertical-align: 0.2em;
}
.wysiwyg a:not(.btn) {
    color: #ECD7A3;
}
.wysiwyg a:not(.btn):focus,
.wysiwyg a:not(.btn):active,
.wysiwyg a:not(.btn):hover {
    color: #ECD7A3;
}
.separator {
    text-align: center;
    overflow: hidden;
}
.separator__content {
    position: relative;
    display: inline-block;
    line-height: 1;
}
.separator__hr {
    position: absolute;
    left: -1000px;
    right: 100%;
    top: 50%;
    margin: 0;
    -webkit-transform: translateX(-10px);
            transform: translateX(-10px);
}
.separator__hr:last-child {
    right: -1000px;
    left: 100%;
    -webkit-transform: translateX(10px);
            transform: translateX(10px);
}
.success-icon {
    text-align: center;
    color: #499F68;
}
.success-icon__icon {
    font-size: 60px;
    vertical-align: middle;
}
.success-icon__bubble {
    display: inline-block;
    width: 8px;
    height: 8px;
    background: #499F68;
    border-radius: 50%;
}
.success-icon__bubble,
.success-icon__icon {
    margin-left: 4px;
}
.password__icon:before {
    content: "\EA5C";
    font-family: "demiIcons";
}
.password.is-visible .password__icon:before {
    content: "\EA5B";
    font-family: "demiIcons";
}
.accommodation-map__canvas {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #E6E6E6;
}
.accommodation-map__canvas.is-drawing-active,
.accommodation-map__canvas.is-drawing-active * {
    cursor: crosshair;
}
/* controls */
.accommodation-map__controls {
    position: absolute;
    bottom: 30px;
    right: 20px;
}
.accommodation-map__controls--top-left {
    left: 20px;
    top: 20px;
    right: auto;
    bottom: auto;
}
.accommodation-map__controls--top-right {
    top: 20px;
    bottom: auto;
}
.accommodation-map__control {
    font-size: 12px;
    padding: 12px;
    width: 40px;
    height: 40px;
    border: 0;
    -webkit-backdrop-filter: blur(20px);
            backdrop-filter: blur(20px);
    border-radius: 4px;
    background-color: rgba(255,255,255,0.3);
}
.accommodation-map__control--w-a {
    width: auto;
}
.btn-group-vertical > .accommodation-map__control + .accommodation-map__control.accommodation-map__control {
    margin-top: 6px;
}
/* infobox */
.accommodation-map__infobox {
    background: white;
    -webkit-box-shadow: 0 1px 6px 0 rgba(0,0,0,0.4);
            box-shadow: 0 1px 6px 0 rgba(0,0,0,0.4);
    width: 250px;
}
.map-infobox__body {
    padding: 10px;
    font-size: 16px;
}
.map-infobox__title {
    color: #ECD7A3;
}
.map-infobox__price {
    color: #499F68;
}
/* close button */
.accommodation-map__infobox > img {
    position: absolute !important;
    right: 5px;
    top: 5px;
    z-index: 1;
}
.map-tooltip {
    position: absolute;
    white-space: nowrap;
    background: rgba(255,255,255,0.3);
    padding: 12px 15px;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    top: 50%;
    font-size: 10px;
    -webkit-backdrop-filter: blur(20px);
            backdrop-filter: blur(20px);
    border: none;
    border-radius: 4px;
}
.map-tooltip--left {
    right: 100%;
    margin-right: 15px;
}
.map-tooltip--left:after {
    content: '';
    position: absolute;
    display: block;
    width: 0;
    right: 0;
    top: 50%;
    border: 10px solid transparent;
    border-right: 0;
    border-left: 10px solid rgba(255,255,255,0.3);
    -webkit-transform: translate(100%, -50%);
            transform: translate(100%, -50%);
    -webkit-backdrop-filter: blur(30px);
            backdrop-filter: blur(30px);
}
.nav-border-tabs {
    border-bottom: 1px solid #bfbfbf;
    font-size: 15px;
    font-family: "nunito-sans-bold", sans-serif;
    font-weight: 700;
}
.nav-border-tabs .nav-link {
    position: relative;
    color: #595959;
    padding: .5rem;
}
.nav-border-tabs .nav-link:after {
    content: '';
    display: block;
    position: absolute;
    bottom: -3px;
    right: 0;
    left: 0;
    height: 5px;
    background: #ECD7A3;
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
    opacity: 0;
    -webkit-transition: opacity 140ms, -webkit-transform 120ms ease;
    transition: opacity 140ms, -webkit-transform 120ms ease;
    -o-transition: transform 120ms ease, opacity 140ms;
    transition: transform 120ms ease, opacity 140ms;
    transition: transform 120ms ease, opacity 140ms, -webkit-transform 120ms ease;
}
.nav-border-tabs .nav-link.active {
    color: #ECD7A3
}
.nav-border-tabs .nav-link.active:after {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    opacity: 1;
    -webkit-transition: opacity 100ms, -webkit-transform 120ms ease;
    transition: opacity 100ms, -webkit-transform 120ms ease;
    -o-transition: transform 120ms ease, opacity 100ms;
    transition: transform 120ms ease, opacity 100ms;
    transition: transform 120ms ease, opacity 100ms, -webkit-transform 120ms ease;
}
.nav-tabs--custom {
    background-color:#fff;
    border-radius: 1.875rem;
    -webkit-box-shadow: 0 0 40px 0 rgba(51,51,49,0.12);
            box-shadow: 0 0 40px 0 rgba(51,51,49,0.12);
    border: none;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
}
.nav-tabs--custom .nav-link {
    font-weight: 700;
    font-family: "nunito-sans-extrabold", sans-serif;
    border: none;
    color: #333331;
    padding: 1.1875rem 1.5rem;
}
.nav-tabs--custom .nav-item.show .nav-link, .nav-tabs--custom .nav-link.active {
    border: none;
}
.nav-tabs--custom .nav-item.show .nav-link:after, .nav-tabs--custom .nav-link.active:after {
    content:'';
    width: 100%;
    height: 0.1875rem;
    background-color: #ECD7A3;
    display: block;
}
.static-map {
    position: relative;
}
.static-map:after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    background: url(/static/demi/debug/img/map/marker-active.svg) no-repeat;
    background-size: contain;
    background-position: bottom center;
    -webkit-transform: translate(-50%, -100%);
            transform: translate(-50%, -100%);
    width: 46px;
    height: 55px;
}
.selection-bar {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    color: #ffffff;
    background: #499F68;
    padding: .5rem 1rem;
    line-height: 1.4;
    z-index: 100;
    display: block;
    -webkit-transform: translateY(100%) translateZ(0);
            transform: translateY(100%) translateZ(0);
    -webkit-transition: -webkit-transform 90ms ease;
    transition: -webkit-transform 90ms ease;
    -o-transition: transform 90ms ease;
    transition: transform 90ms ease;
    transition: transform 90ms ease, -webkit-transform 90ms ease;
}
.affiliate .selection-bar {
    display: none;
}
.selection-bar__cart-icon {
    font-size: 16px;
}
.selection-bar__arrow-icon {
    font-size: 10px;
    vertical-align: 0.3em;
    margin-left: 2px;
}
.selection-bar.is-shown {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
}
.body--has-selection-bar {
    padding-bottom: 60px;
}
.corridor__head {
    position: relative;
    z-index: 1;
    background-color: #fff;
    border-radius: 1.875rem;
    -webkit-box-shadow: 0 0 40px 0 rgba(51,51,49,0.12);
            box-shadow: 0 0 40px 0 rgba(51,51,49,0.12);
    overflow: hidden;
}
.corridor-head__item {

}
.corridor__matrix {
    padding: 0 5px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    text-align: center;
}
.corridor__left {
    /*todo name   */
    float: none;
    width: 40px;
}
.corridor__body {
    -webkit-box-flex: 1;
        -ms-flex: auto;
            flex: auto;
    min-width: 0;
}
.corridor__col {
    width: 80px;
}
.corridor__matrix-head {
    height: 48px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    padding-top: 21px;
}
.corridor__cell {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 50px;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 0.25rem;
    font-weight: 800;
}
.corridor__cell-btn {
    padding: 4px 12px;
    border-radius: 6px;
    border: none;
    height: 100%;
    white-space: normal;
    background: none;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    background-color: #fff;
    width: 100%;
    letter-spacing: 0;
}
.corridor__cell-btn.is-selected {
    border: 1px solid #499F68;
}
/*.corridor .slick-track {*/
/*display: inline-block; !*to trigger block formatting context*!*/
/*}*/
.twitter-typeahead {
    width: 100%;
}
.tt-menu {
    padding: 5px 0;
    right: 0;
    background: #ffffff;
    -webkit-box-shadow: 0 0 1px rgba(0,0,0,.2);
            box-shadow: 0 0 1px rgba(0,0,0,.2);
    margin-bottom: 10px;
    max-height: 400px;
    overflow-y: auto;
}
.tt-suggestion.tt-selectable,
.tt-dataset-heading,
.tt-empty-message {
    padding: 6px 12px;
}
.tt-suggestion:hover {
    cursor: pointer;
}
.tt-cursor,
.tt-suggestion:hover{
    background: #f2f2f2;
}
.tt-hint {
    color: #999
}
.tt-dataset-heading {
    font-weight: bold;
    border-bottom: 1px solid #f2f2f2;
}
.nearby-search-autocomplete .tt-suggestion {
    padding-left: 20px;
}
.slick-slider--flex .slick-track {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.affiliate .slick-slider {
    /*ios fix for sliders in iframe (see https://github.com/kenwheeler/slick/issues/2834)*/
    width: 1px;
    min-width: 100%;
}
.payment-details {
    padding: .5rem;
}
.payment-details__item {
    margin-bottom: .5rem;
}
.feature-list__icon {
    min-width: 42px;
}
.star-holder {
    white-space: nowrap;
}
.star-holder .star {
    font-size: .7em;
    margin-left: 1px;
    vertical-align: super;
    position: relative;
    top: 0.25em;
}
.noUi-horizontal {
    height: 6px;
}
.noUi-target {
    background: #bfbfbf;
    -webkit-box-shadow: none;
            box-shadow: none;
    border: none;
}
.noUi-connect {
    background: #ECD7A3;
}
.noUi-horizontal .noUi-handle {
    height: 24px;
    width: 24px;
    left: -12px;
    top: -10px;
    border-radius: 50%;
}
/* Slider size and handle placement;
 */
html:not([dir="rtl"]) .noUi-horizontal .noUi-handle {
    right: -12px;
    left: auto;
}
/* Handle stripes;
 */
.noUi-handle:before {
    content: "\EA07";
    font-family: "demiIcons";
    display: inline-block;
    speak: none;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
    font-size: 10px;
    color: #ECD7A3;

    height: 10px;
    top: 6px;
    left: 6px;
    width: auto;
}
.noUi-handle:after {
    display: none;
}
.noUi-horizontal.noUi-extended.noUi-target {
    padding: 0 12px;
}
.noUi-horizontal.noUi-extended .noUi-base:before,
.noUi-horizontal.noUi-extended .noUi-base:after {
    width: 12px;
    content: "";
    position: absolute;
    top: 0;
    height: 100%;
    display: block;
}
.noUi-horizontal.noUi-extended .noUi-base:before {
    left: -12px;
}
.noUi-horizontal.noUi-extended .noUi-base:after {
    left: 100%;
}
.distance-info {
    color: #ECD7A3;
}
.distance-info__badge {
    background-color: #ECD7A3;
    overflow: visible;
    font-size: 100%;
}
/* popover styling*/
.popover {
  min-width: 280px;
  -webkit-box-shadow: 20px 20px 40px 0 rgb(51 51 49 / 24%);
          box-shadow: 20px 20px 40px 0 rgb(51 51 49 / 24%);
  border-radius: 0.25rem;
  border: none;
}
.popover.popover--md {
  min-width: 350px;
}
.popover-body {
  padding: 0px;
}
.loading-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0,0,0,0.4);
  z-index: 100;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.accordion-button{
    padding: 1rem 1rem 1rem 3.625rem;
    min-height: 4.5rem;
    background-color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    position: relative;
}
.accordion-button:after{
    content: "+";
    font-size: 2rem;
    background-image: none;
    color: #ECD7A3;
    position: absolute;
    left: 1.875rem;
    top: 0.625rem;
    -webkit-transform-origin: center;
            transform-origin: center;
    z-index: 2;
}
.accordion-button:not(.collapsed)::after {
    background-image: none;
    content: "-";
    font-size: 2.25rem;
    top: 0.375rem;
    color: #fff;
    -webkit-transform: none;
            transform: none;
}
.accordion-button:not(.collapsed)::before {
    background-color: #ECD7A3;
}
.accordion-button:before{
    content: "";
    background-color: transparent;
    position: absolute;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    top: 2.25rem;
    left: -0.625rem;
    width: 5.375rem;
    height: 5.375rem;
    border-radius: 3.3125rem;
    -webkit-transition: background-color 0.2s ease-in;
    -o-transition: background-color 0.2s ease-in;
    transition: background-color 0.2s ease-in;
    overflow: hidden;
    z-index: 1;
}
.accordion-item{
    border: none;
    margin-bottom: 0.375rem;
    background-color: transparent;
}
.accordion-item:hover .accordion-button:after{
    color: #fff;
}
.accordion-item:hover .accordion-button:before{
    background-color: #ECD7A3;
    -webkit-transition: background-color 0.2s ease-in;
    -o-transition: background-color 0.2s ease-in;
    transition: background-color 0.2s ease-in;
}
.accordion-item + .accordion-item {
    margin-top: 0.625rem;
}
.accordion-header{
    border-radius: 1.5rem;
    overflow: hidden;
}
.accordion-header__title{
    z-index: 3;
    font-family: "nunito-sans-extrabold", sans-serif;
}
.accordion-body{
    padding: 0.75rem 0;
}
.accordion-body__content{
    margin-bottom: 0.75rem;
}
.sidebar-alpbachtal-card {
    position: relative;
}
.sidebar-alpbachtal-card__content {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    padding: 2.375rem;
}
.sidebar-alpbachtal-card__badge {
    position: absolute;
    top: 0.625rem;
    right: 0;
    text-transform: uppercase;
    color: #D34442;
    padding: 0.75rem;
    -webkit-box-shadow: 20px 20px 40px 0 rgba(51, 51, 49, 0.24);
            box-shadow: 20px 20px 40px 0 rgba(51, 51, 49, 0.24);
    border-radius: 0.375rem 0 0 0.375rem;
    background-color: #fff;
    font-size: 0.75rem;
    letter-spacing: 0.125rem;
    line-height: 1;
    font-weight: 700;
    font-family: "nunito-sans-extrabold", sans-serif;
}
.sidebar-alpbachtal-card__card-img {
   max-width: 9.375rem;
}
.best-offer-card__header {
    background-color: #ECD7A3;
    color: #fff;
}
.expandable-block__content-wrapper {
    position: relative;
    overflow-y: auto;
    max-height: 300px;
}
.expandable-block__content-wrapper--small {
    max-height: 60px;
}
.expandable-block.is-expandable .expandable-block__content-wrapper {
    max-height: none;
}
.expandable-block.is-collapsed .expandable-block__content-wrapper {
    display: block;
    height: 300px;
    padding: 0;
    overflow: hidden;
}
.expandable-block.is-collapsed .expandable-block__content-wrapper--small {
    height: 70px;
}
.expandable-block.is-collapsed .expandable-block__content {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    border: 0;
}
.expandable-block__content-wrapper:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100px;
    visibility: hidden;
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(255,255,255,0)), to(#FFFFFF));
    background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%, #FFFFFF 100%);
    background: -o-linear-gradient(top, rgba(255,255,255,0) 0%, #FFFFFF 100%);
    background: linear-gradient(180deg, rgba(255,255,255,0) 0%, #FFFFFF 100%);
}
.expandable-block__content-wrapper--small:after {
    height: 40px;
}
.expandable-block__more {
    display: none;
}
.expandable-block.is-expandable.is-collapsed .expandable-block__more {
    display: block;
}
.expandable-block.is-expandable.is-collapsed .expandable-block__content-wrapper:after {
    visibility: visible;
}
/*is-not-expandable*/
.expandable-block.is-not-expandable .expandable-block__content-wrapper {
    height: auto;
    max-height: none;
    overflow: visible;
}
.expandable-block.is-not-expandable .expandable-block__content {
    position: static;
    height: auto;
    width: auto;
    max-height: none;
}
.expandable-block.is-not-expandable .expandable-block__more {
    display: none;
}
.lg-backdrop.lg-backdrop.in {
   opacity: 0.8;
}
.lightbox-top-bar {
    background: white;
    height: 80px;
    z-index: 1083;
    position: absolute;
    left: 0;
    right: 0;
    background: white;
    -webkit-box-shadow: 0 1px 3px 0 rgba(0,0,0,0.2);
            box-shadow: 0 1px 3px 0 rgba(0,0,0,0.2);
}
.lg-outer.lg-outer .lg-img-wrap {
    padding-top: 100px;
    padding-bottom: 120px;
}
/*.lg-actions .lg-next, .lg-actions .lg-prev {*/
/*margin-top: calc(80 / 2 - 10px);*/
/*}*/
.lg-outer .lg-thumb-item {
    border: none;
    border-radius: 0;
    position: relative;
}
.lg-outer .lg-thumb-outer {
    background: rgba(0,0,0,.5);
    padding-left: 50px;
    padding-right: 50px;
}
.lg-outer .lg-thumb-item:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: black;
    opacity: 0.5;
    -webkit-transition: opacity 240ms ease;
    -o-transition: opacity 240ms ease;
    transition: opacity 240ms ease;
}
.lg-outer .lg-thumb-item:hover:after {
    opacity: 0.2;
}
.lg-outer .lg-thumb-item.active:after {
    opacity: 0;
}
.lightbox__close {
    position: absolute;
    top: 30px;
    right: 20px;
    font-size: 24px;
}
.lg-actions>.lg-prev:after, .lg-actions>.lg-next:before {
    content: none;
}
.lg-actions .lg-next, .lg-actions .lg-prev {
    background: none;
    color: #fff;
    font-size: 30px;
    text-shadow: 0 1px 3px rgba(0,0,0,0.2);
}
/* affiliate: demi is in iframe, add max-height in case of very high iframes */
.affiliate .lg-outer {
    max-height: 800px;
}
.lg-toolbar .lg-close:after {
    content: "\EA1C";
    font-family: demiIcons;
}
.lg-outer .lg-item {
    /* remove loading gif */
    background-image: none;
}
.lg-icon {
    font-family: inherit;
}
.cookie-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: #FEFAF1FF;
    color: #333331;
    padding: .75rem 1rem;
}
.cookie-bar__text {
    display: inline;
}
.cookie-bar__detail,
a.cookie-bar__detail {
    display: inline;
}
.cookie-bar__detail-btn {
    display: inline;
    text-decoration: underline;
    vertical-align: inherit;
    cursor: pointer;
    margin-right: .25em;
}
.cookie-bar__buttons {
    margin-top: .75rem;
    text-align: right;
}
.cookie-bar__accept {
    margin-left: .75rem;
}
/* modal */
.cookie-modal__item {
    margin-bottom: .75rem;
}
.cookie-modal__item--all {
    margin-bottom: 1rem;
}
.cookie-modal__label {
    font-size: 1.5rem;
//font-weight: bold;
}
.cookie-modal__link {
    display: inline-block;
    margin-left: .5rem;
    text-decoration: underline;
}
/* switch */
.cookie-modal__switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    vertical-align: middle;
    margin-right: .5rem;
}
.cookie-modal__switch-toggle {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s;
    border-radius: 34px;
}
.cookie-modal__switch-toggle:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s;
    border-radius: 34px;
}
input:checked + .cookie-modal__switch-toggle {
    background-color: #ECD7A3;
}
input:focus + .cookie-modal__switch-toggle {
    -webkit-box-shadow: 0 0 2px #ECD7A3;
            box-shadow: 0 0 2px #ECD7A3;
}
input:checked + .cookie-modal__switch-toggle:before {
    -webkit-transform: translateX(26px);
            transform: translateX(26px);
}
.cookie-overlay-btn {
    position: absolute;
    z-index: 99;
    height: auto;
    width: auto;
    margin: 0 auto;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}
.cookie-first__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #1D1D1B;
    color: #FFF;
}
.cookie-first__overlay__inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    height: 100%;
}
.cookie-first__overlay__inner.cookie-first__overlay__inner--map {
    display: block;
    text-align: center;
    margin: 12.5rem 1.25rem;
}
.cookie-first__accept-category {
    font-size: 0.75rem;
    padding: 1rem 0.875rem;
}
.availability-status--high {
    color: #499F68;
}
.availability-status--medium {
    color:  #e1be66;
}
.availability-status--low {
    color: #DC3545;
}
.merchshop__badge {
    display: inline-block;
    padding:.25rem 1.5rem;
    text-align:center;
    color:#fff;
    font-family:"nunito-sans-extrabold", sans-serif;
    background: #ECD7A3;
}
.merchshop__price {
    font-size: 1.875rem;
}
/* slider */
.merch-slider-nav__item {
    cursor: pointer;
    opacity: .7;
}
.merch-slider-nav__item:focus,
.merch-slider-nav__item:hover,
.slick-current .merch-slider-nav__item {
    opacity: 1;
}
.slick-arrow--merch-slider {
    color:#fff;
    text-shadow: 0px 0px 8px rgba(0,0,0,0.6);
    font-size: 1.25rem;
    padding:1rem;
    position: absolute;
    top:50%;
    background: transparent;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    z-index:5;
    opacity: .7;
}
.slick-arrow--merch-slider.slick-prev {
    left:0;
}
.slick-arrow--merch-slider.slick-next {
    right:0;
}
.slick-arrow--merch-slider:hover,
.slick-arrow--merch-slider:focus {
    opacity: 1;
}
/* product size radio buttons */
.merch-size__radio>label,
.merch-size__item{
    width: 3.125rem;
    height: 3.125rem;
    border: 1px solid #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    font-size: 1rem;
    font-family: "nunito-sans-extrabold", sans-serif;
    -webkit-transition: all 200ms ease;
    -o-transition: all 200ms ease;
    transition: all 200ms ease;
    cursor: pointer;
}
.merch-size__radio--disabled {
    pointer-events: none;
}
.merch-size__radio.merch-size__radio--disabled > label{
    pointer-events: none;
    opacity: 0.3;
    cursor: not-allowed;
}
.merch-size__radio > label{
    border-color: grey;
    width: auto;
    padding:0 .5rem;
    height: 1.875rem;
}
.merch-size__input:checked ~ label,
.merch-size__radio:not(.merch-size__radio--disabled) >label:hover{
    color: #fff;
    background-color: #333331;
    border-color: #333331;
}
.merch-size__input {
    display: none;
}
.switch {
    position: relative;
    display: inline-block;
    width: 3rem;
    height: 1.75rem;
    vertical-align: middle;
}
.switch__toggle {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #bfbfbf;
    -webkit-transition: .25s;
    -o-transition: .25s;
    transition: .25s;
    border-radius: 2rem;
}
.switch__toggle:before {
    position: absolute;
    content: "";
    height: 1.25rem;
    width: 1.25rem;
    left: .25rem;
    bottom: .25rem;
    background-color: white;
    -webkit-transition: .25s;
    -o-transition: .25s;
    transition: .25s;
    border-radius: 2rem;
}
input:checked + .switch__toggle {
    background-color: #ECD7A3;
}
input:focus + .switch__toggle {
    -webkit-box-shadow: 0 0 2px #ECD7A3;
            box-shadow: 0 0 2px #ECD7A3;
}
input:checked + .switch__toggle:before {
    -webkit-transform: translateX(1.25rem);
            transform: translateX(1.25rem);
}
.acco-teaser {
    border-radius: 6px;
    -webkit-box-shadow: 20px 20px 40px rgba(51,51,49,0.12);
            box-shadow: 20px 20px 40px rgba(51,51,49,0.12);
    position: relative;
    background-color: #fff;
}
.acco-teaser__title {
    font-size: 0.875rem;
    color:#333331;
}
.acco-teaser__test-acco-notice {
    position: absolute;
    top:0;
    z-index: 1;
    left:0;
}
.prospect-teaser {
    padding: 1.5rem;
    border-radius: 0.375rem;
    background-color: #fff;
    -webkit-box-shadow: 0 0 40px 0 rgba(51,51,49,0.24);
            box-shadow: 0 0 40px 0 rgba(51,51,49,0.24);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1 1 0%;
    height: 100%;
}
.prospect-teaser__title {
    font-size: 1rem;
}
.prospect-teaser__body {
    -webkit-box-flex: 1;
        -ms-flex: auto;
            flex: auto;
    padding-top: 1.5rem;
}
.prospect-teaser__toggle-btn {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 0.25rem;
    position: absolute;
    top: 0.625rem;
    left: 1.5625rem;
    border: 0.5px solid #989691;
    background-color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
input:checked+ .prospect-teaser__toggle-btn {
    background-color: #499F68;
}
.is-active .prospect-teaser {
    border: 1px solid #499F68;
}
.prospect-teaser__toggle-btn-icon {
    font-size: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.prospect-teaser__download{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
            flex-direction: row-reverse;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    white-space: nowrap;
}
/* Utility & Helper Classes (mostly atomic classes; high specificity)*/
.increased-click-area.increased-click-area {
    padding: 10px;
    margin: -10px;
}
.bg-white { background: #ffffff; }
.bg-light-gray { background: #f2f2f2 }
.bg-primary-dark {
    background: #ECD7A3;
    color: #ffffff;
}
.tfoot-gray tr{
    background-color: rgba(0,0,0,.05);
}
.tfoot-gray tr th,
.tfoot-gray tr td{
    border-top: 2px solid white;
}
.text-underline {
    text-decoration: underline;
}
.text-default { color: #333331 }
.text-gray { color: #bfbfbf }
.text-gray-medium { color: #8C8C8C }
.text-success-dark { color: #4b712c }
.text-muted {color: #989691 !important;}
.text-black { color: #000;}
.img-full-width {
    width: 100%;
    height: auto;
}
.isClickable {cursor: pointer;}
.box-shadow { -webkit-box-shadow: 0 1px 3px 0 rgba(0,0,0,0.2); box-shadow: 0 1px 3px 0 rgba(0,0,0,0.2); }
.bordered { border: 1px solid #bfbfbf; }
.border-left {border-left: 1px solid #D8D8D8;}
.border-right {border-right: 1px solid #D8D8D8;}
.order-1 {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
}
.order-12 {
    -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
            order: 12;
}
.z-1 {
    z-index: 1;
}
.mb30 {
    margin-bottom: 30px;
}
.flex-auto { -webkit-box-flex:1; -ms-flex:auto; flex:auto }
.lazy-img-placeholder:not([src]) {
    height: 0;
    padding-top: 75%;
    background-color: #bfbfbf;
}
.lazy-img {
    -webkit-transition: opacity 120ms ease-out;
    -o-transition: opacity 120ms ease-out;
    transition: opacity 120ms ease-out;
}
.lazy-img:not([src]) {
    opacity: 0;
}
.overflow-hidden {
    overflow: hidden;
}
/* Negative margins */
.mx--4 {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
}
.container-break {
    margin-left: -15px;
    margin-right: -15px;
}
/*
.show-content-tracking [data-track-content] {
    outline: 2px solid lightskyblue;
}
.show-content-tracking [data-content-name]:before {
    content: attr(data-content-name);
    background: lightskyblue;
}
*/
.flex-fill {
    -webkit-box-flex: 1;
        -ms-flex: auto;
            flex: auto;
}
.fz9 {
    font-size: 0.5625rem;
}
.fz12 {
    font-size: 0.75rem;
}
.rounded-circle {
    border-radius: 50%;
    -webkit-box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
            box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
}
.w-30 {
    width: 1.875rem;
}
.gap--10 {
    gap: 0.625rem;
}
.lh1 {
    line-height:1;
}
.h-100 {
    height: 100%;
}
.object-fit-cover {
    -o-object-fit: cover;
       object-fit: cover;
}
.font-default {
    font-family: "nunito-sans-regular", sans-serif;
    font-weight: 400;
}
.font-medium {
    font-family: "nunito-sans-bold", sans-serif;
    font-weight: 700;
}
.font-bold {
    font-family: "nunito-sans-extrabold", sans-serif;
    font-weight: 800;
}
.lh0 { line-height:0; }
.fz11 { font-size: 11px; }
.fz14 { font-size: 14px; }
.fz15 { font-size: 15px; }
.fz16 { font-size: 16px; }
.fz17 { font-size: 17px; }
.fz18 { font-size: 18px; }
.fz20 { font-size: 20px; }
.fz23 { font-size: 23px; }
/*debug only*/
/*@import './style/showStructure.css';*/
/* Global variables and settings */
:root {

    /*--color-primary--dark: color(var(--color-primary) shade(19%));*/
    /*--color-success--dark: color(var(--color-success) shade(20%));*/
}
@media screen and (min-width: 576px) {}
@media (min-width: 576px) {}
@media screen and (min-width: 768px) {
.main-navbar-nav {
        padding: 0.9375rem 0;
        min-height: 7.5rem
}
.main-navbar--shadow .main-navbar__brand--brixlegg {
            width: 13.5rem;
            height: auto
    }
.main-navbar__container {
        margin: 0 0.9375rem;
        grid-template-columns: auto 1fr 10% 45%;
        grid-template-areas: "area-menu-search area-meta-nav area-logo area-regions-shop";
        grid-template-rows: 1fr;
        width: 100vw
}
.main-navbar__brand--skijuwel {
        width: 13.5rem;
        height: auto
}
.main-navbar__brand--wildschoenau {
        width: 7.5rem;
        height: 4.375rem
}
.main-navbar__brand--cca {
        width: 12.5rem;
        height: auto
}
.main-navbar__brand--brixlegg {
        width: 13.5rem;
        height: auto
}
.main-navbar__area-meta-nav {
        margin-left: 1.25rem
}
.main-navbar__area-regions-shop {
        -webkit-column-gap: 1rem;
           -moz-column-gap: 1rem;
                column-gap: 1rem
}
.main-navbar__area-menu-search {
        -webkit-column-gap: 0.625rem;
           -moz-column-gap: 0.625rem;
                column-gap: 0.625rem
}
.main-navbar__shop-link--btn {
        padding: 0.9375rem 1.5rem;
        background-color: #D34442;
        color: white;
        border-radius: 3.3125rem;
        -webkit-transition: background-color 300ms ease;
        -o-transition: background-color 300ms ease;
        transition: background-color 300ms ease
}
.main-navbar__shop-link--pull-right {
        margin-right: -2.25rem
}
.main-navbar__shop-link--btn-white {
        padding: 0.9375rem 1.5rem
}
.main-navbar__area-contact-login {
        -webkit-column-gap: 0.5rem;
           -moz-column-gap: 0.5rem;
                column-gap: 0.5rem
}
.main-navbar__link--btn {
        padding: 0.9375rem 1.5rem;
        background-color: #D34442;
        color: white;
        border-radius: 3.3125rem;
        -webkit-transition: background-color 300ms ease;
        -o-transition: background-color 300ms ease;
        transition: background-color 300ms ease
}
.main-navbar--checkout {
        padding: 0.9375rem 3.75rem
}
.main-navbar__account-menu {
        padding: 2rem
}
    .main-nav__search-overlay__input:focus {
        outline: none;
        border-width: 0.125rem;
    }
    .main-nav__search-overlay__input:focus + .main-nav__search-overlay__btn {
        -webkit-transform: translateX(26rem) rotate(260deg);
                transform: translateX(26rem) rotate(260deg);
        -webkit-box-shadow: 0 0 0.625rem 0.3125rem rgba(0,0,0,0.5);
                box-shadow: 0 0 0.625rem 0.3125rem rgba(0,0,0,0.5);
    }
    .main-nav__search-overlay__input::-webkit-input-placeholder {
        -webkit-transform: translateX(3.125rem);
                transform: translateX(3.125rem);
        -webkit-transition: -webkit-transform 0.2s ease;
        transition: -webkit-transform 0.2s ease;
        -o-transition: transform 0.2s ease;
        transition: transform 0.2s ease;
        transition: transform 0.2s ease, -webkit-transform 0.2s ease;
    }
    .main-nav__search-overlay__input::-moz-placeholder {
        transform: translateX(3.125rem);
        -webkit-transition: -webkit-transform 0.2s ease;
        transition: -webkit-transform 0.2s ease;
        -o-transition: transform 0.2s ease;
        transition: transform 0.2s ease;
        transition: transform 0.2s ease, -webkit-transform 0.2s ease;
    }
    .main-nav__search-overlay__input::-ms-input-placeholder {
        transform: translateX(3.125rem);
        -webkit-transition: -webkit-transform 0.2s ease;
        transition: -webkit-transform 0.2s ease;
        -o-transition: transform 0.2s ease;
        transition: transform 0.2s ease;
        transition: transform 0.2s ease, -webkit-transform 0.2s ease;
    }
    .main-nav__search-overlay__input::placeholder {
        -webkit-transform: translateX(3.125rem);
                transform: translateX(3.125rem);
        -webkit-transition: -webkit-transform 0.2s ease;
        transition: -webkit-transform 0.2s ease;
        -o-transition: transform 0.2s ease;
        transition: transform 0.2s ease;
        transition: transform 0.2s ease, -webkit-transform 0.2s ease;
    }
    .main-nav__search-overlay__input:focus::-webkit-input-placeholder {
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }
    .main-nav__search-overlay__input:focus::-moz-placeholder {
        transform: translateX(0);
    }
    .main-nav__search-overlay__input:focus::-ms-input-placeholder {
        transform: translateX(0);
    }
    .main-nav__search-overlay__input:focus::placeholder {
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }
        .nav-overlay:before {
            content: '';
            position: absolute;
            left: 0;
            z-index: -2;
            height: 100vh;
            width: 50%;
            background: #FEFAF1;
            display: block;
        }
    .mega-nav--level-0 {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-flex: 1;
            -ms-flex: auto;
                flex: auto;
        height: 100%;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
    }
    .mega-nav__list--level-0 {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        width: 30rem;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        padding-left: 4rem;
    }
    .mega-nav__item--level-0 {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-flex: 1;
            -ms-flex: auto;
                flex: auto;
        position: static;
    }
    .mega-nav__item--level-0.is-active {
        color: #333331;
    }
    .mega-nav__item--level-0 + .mega-nav__item--level-0 {
        margin-top: 3.75rem;
    }
    .mega-nav__item-content {
        -webkit-transition: -webkit-transform 0.2s ease;
        transition: -webkit-transform 0.2s ease;
        -o-transition: transform 0.2s ease;
        transition: transform 0.2s ease;
        transition: transform 0.2s ease, -webkit-transform 0.2s ease;
        background-color: transparent;
        border: none;
        -webkit-box-shadow: none;
                box-shadow: none;
        color: #333331;
        padding:0;
        line-height:1;
    }
    .mega-nav__item-content--level-0 {
        //opacity:0.2;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-transform: translateX(0);
                transform: translateX(0);
        -webkit-transition: opacity 0.2s ease, -webkit-transform .2s ease;
        transition: opacity 0.2s ease, -webkit-transform .2s ease;
        -o-transition: opacity 0.2s ease, transform .2s ease;
        transition: opacity 0.2s ease, transform .2s ease;
        transition: opacity 0.2s ease, transform .2s ease, -webkit-transform .2s ease;
        position: relative;
        -webkit-box-flex: 1;
            -ms-flex: auto;
                flex: auto;
        font-size: 1.5rem;
        line-height: 1.33333;
        font-family: "nunito-sans-extrabold", sans-serif;
        font-weight: 800;
        -webkit-box-pack: start;
            -ms-flex-pack: start;
                justify-content: flex-start;
    }
    .mega-nav__item-content--level-0:hover,
    .mega-nav__item-content--level-0:focus,
    .mega-nav__item-content--level-0:active,
    .mega-nav__item.is-open > .mega-nav__item-content--level-0,
    .mega-nav__item.is-open--only-desktop > .mega-nav__item-content--level-0 {
        opacity: 1;
        outline:none;
        -webkit-transition: opacity 0.2s ease, -webkit-transform .2s ease;
        transition: opacity 0.2s ease, -webkit-transform .2s ease;
        -o-transition: opacity 0.2s ease, transform .2s ease;
        transition: opacity 0.2s ease, transform .2s ease;
        transition: opacity 0.2s ease, transform .2s ease, -webkit-transform .2s ease;
        -webkit-transform: translateX(1.5rem);
                transform: translateX(1.5rem);
    }
    .mega-nav__collapse-icon--level-0 {
        z-index: -1;
        margin-left: 1.625rem;
        font-size: 0.875rem;
    }
        .mega-nav__collapse-icon--level-0:after {
            content: "";
            position: absolute;
            top: -1.875rem;
            right: -1.9375rem;
            width: 4.5rem;
            height: 4.5rem;
            background-color: #ECD7A3;
            border-radius: 50%;
            z-index: -2;
            opacity: 0;
            -webkit-transition: opacity .3s ease;
            -o-transition: opacity .3s ease;
            transition: opacity .3s ease;
        }
    .mega-nav__item--level-0.is-open .mega-nav__collapse-icon--level-0,
    .mega-nav__item--level-0.is-open--only-desktop .mega-nav__collapse-icon--level-0 {
        opacity: 1;
        color: white;
        margin-left: 0.75rem;
    }
        .mega-nav__item--level-0.is-open .mega-nav__collapse-icon--level-0:after, .mega-nav__item--level-0.is-open--only-desktop .mega-nav__collapse-icon--level-0:after {
            opacity: 1;
        }
    .mega-nav--level-1 {
        color: #989691;
        z-index: 10;
        position: absolute;
        right: auto;
        visibility: hidden;
        opacity: 0;
        min-width: 20rem;
        width: auto;
        max-width: 50%;
        left: 50%;
        top:0;
        height: 100vh;
        -webkit-transform: translateX(-50px);
                transform: translateX(-50px);
        -webkit-transition: visibility 0.001ms 300ms, opacity 300ms ease, -webkit-transform 300ms ease;
        transition: visibility 0.001ms 300ms, opacity 300ms ease, -webkit-transform 300ms ease;
        -o-transition: transform 300ms ease, visibility 0.001ms 300ms, opacity 300ms ease;
        transition: transform 300ms ease, visibility 0.001ms 300ms, opacity 300ms ease;
        transition: transform 300ms ease, visibility 0.001ms 300ms, opacity 300ms ease, -webkit-transform 300ms ease;
        padding: 3.75rem 1.875rem;
        background-color: rgba(254,250,241, .9);
    }
    .mega-nav__item-content--level-1:hover .mega-nav__collapse-icon {
        opacity: 1;
    }
    .mega-nav__list--level-1 {
        display: block;
        overflow-y: auto;
        overflow-x: hidden;
        max-height: 100%;
        min-height: 50vh;
    }
    .mega-nav__item.is-open > .mega-nav--level-1,
    .mega-nav__item.is-open--only-desktop > .mega-nav--level-1 {
        opacity: 1;
        visibility: visible;
        -webkit-transform: translateX(0);
                transform: translateX(0);
        -webkit-transition: visibility 300ms 0.001ms, opacity 300ms ease, -webkit-transform 300ms ease;
        transition: visibility 300ms 0.001ms, opacity 300ms ease, -webkit-transform 300ms ease;
        -o-transition: transform 300ms ease, visibility 300ms 0.001ms, opacity 300ms ease;
        transition: transform 300ms ease, visibility 300ms 0.001ms, opacity 300ms ease;
        transition: transform 300ms ease, visibility 300ms 0.001ms, opacity 300ms ease, -webkit-transform 300ms ease;
    }
    .mega-nav--level-1__go-back {
        display:none;
    }
    .mega-nav__title-arrow {
        font-size: 0.75rem;
        margin-left: 0.75rem;
    }
    .mega-nav__title--level-1 {
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-flex: 0;
            -ms-flex: 0 0 100%;
                flex: 0 0 100%;
        font-family: "nunito-sans-extrabold", sans-serif;
        color: #333331;
        font-size: 1.375rem;
        margin-bottom: 0.3125rem;
    }
    .mega-nav__title-link--level-1,
    .mega-nav__item-content--level-1 {
        display: block;
    }
    .mega-nav__title-link--level-1 {
        font-family: "nunito-sans-extrabold", sans-serif;
        font-weight: 800;
        font-size: 1.125rem;
        line-height: 1.5rem;
        margin-bottom: 3.25rem;
        -webkit-transition: -webkit-transform 300ms ease;
        transition: -webkit-transform 300ms ease;
        -o-transition: transform 300ms ease;
        transition: transform 300ms ease;
        transition: transform 300ms ease, -webkit-transform 300ms ease;
    }
    .mega-nav__item-content--level-1 {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        font-size: 1.25rem;
        line-height: 1.25rem;
        padding: 1rem 0;
        background-color: transparent;
        border: none;
        -webkit-box-shadow: none;
                box-shadow: none;
        font-family: "nunito-sans-extrabold", sans-serif;
        color: #333331;
        font-weight: 800;
        -webkit-transition: -webkit-transform 300ms ease;
        transition: -webkit-transform 300ms ease;
        -o-transition: transform 300ms ease;
        transition: transform 300ms ease;
        transition: transform 300ms ease, -webkit-transform 300ms ease;
    }
    .mega-nav__item--level-1 {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-flex: 1;
            -ms-flex-positive: 1;
                flex-grow: 1;
        width: 100%;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-align: start;
            -ms-flex-align: start;
                align-items: flex-start;
    }
    .mega-nav__title-link--level-1:hover,
    .mega-nav__title-link--level-1:focus,
    .mega-nav__title-link--level-1:active,
    .mega-nav__item-content--level-1:hover,
    .mega-nav__item-content--level-1:focus,
    .mega-nav__item-content--level-1:active {
        color: #333331;
        outline:none;
        -webkit-transform: translateX(0.25rem);
                transform: translateX(0.25rem);
    }
    .mega-nav--level-2 {
        margin: 0 0 2.25rem 1.5rem;
    }
    .mega-nav__item-content--level-2,  .mega-nav__title-link--level-2{
        color: #333331;
        font-size: 1.2rem;
        line-height: 1.5rem;
        text-transform: none;
        margin-bottom: 1.5rem;
        letter-spacing: 0;
        display: block;
        -webkit-transition: color 0.2s ease;
        -o-transition: color 0.2s ease;
        transition: color 0.2s ease;
    }
    .mega-nav__item-content--level-2.is-active {
        font-weight: 700;
    }
    .mega-nav--level-3 {
        margin-left: 1rem;
    }
    .mega-nav__item-content--level-3,  .mega-nav__title-link--level-3 {
        color: #333331;
        font-size: 1.2rem;
        line-height: 1.5rem;
        text-transform: none;
        margin-bottom: 1.5rem;
        letter-spacing: 0;
        display: block;
        -webkit-transition: color 0.2s ease;
        -o-transition: color 0.2s ease;
        transition: color 0.2s ease;
    }
    .mega-nav__item-content--level-3.is-active {
        font-weight: 700;
    }
.container--breadcrumbs {
        padding-left: 3.75rem;
        padding-right: 3.75rem
}
.title-block__top-title {
        margin-bottom: 0.75rem
}
.title-block__title {
        margin-bottom: 2.25rem
}
.title-block__sub-title {
        font-size: 1.5rem;
        line-height: 1.75
}
.prospect-grid {
        grid-template-columns: 66.191% 1fr;
        gap: 0 2.5rem
}
.card-body {
        padding: clamp(1.7rem, 2.4vw, 3rem)
}
.card-top {
        padding: 1.5rem 2.25rem 0 1.5rem
}
.card-price__amount {
        font-size: 1.5rem
}
.card-price__from {
        font-size: 0.875rem
}
.card-price__text {
        font-size: 0.875rem
}
.language-dropdown--nav-overlay {
        margin-top: 6.75rem
}
.region-dropdown__btn {
        font-size: 0.875rem
}
.image-teaser {
        width: 25.125rem;
        -webkit-box-shadow: 0 1.875rem 1.25rem -0.75rem rgba(black, .4);
                box-shadow: 0 1.875rem 1.25rem -0.75rem rgba(black, .4)
}
.image-teaser--full-width {
        width: 100%
}
.image-teaser__title {
        font-size: 1.5rem
}
.image-teaser__title--big {
        font-size: 2rem;
        left: 3rem;
        bottom: 5.375rem;
        max-width: 80%
}
.footer-top.container{
        padding-bottom: 6.25rem
}
.footer-top__header{
        margin-bottom: 6.25rem
}
.footer-top__subtitle{
        font-size: 1rem;
        line-height: 1.375rem;
        margin-bottom: 0.75rem
}
.footer-top__header-title{
        font-size: 6.75rem;
        line-height: 6.75rem
}
.footer-top__header-icon{
        margin-top: 0.75rem;
        margin-bottom: 1.25rem
}
.footer-top__title{
        font-size: 1.5rem;
        line-height: 1.5rem
}
.footer-top__title--big{
        font-size: 2.25rem;
        line-height: 2.25rem;
        margin-bottom: 3rem
}
.footer-top__social-media{
        margin-top: 0.75rem;
        width: 100%
}
.footer-top__contact{
        margin-top: 1.625rem
}
.footer-top__contact-item {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-column-gap: 0.75rem;
           -moz-column-gap: 0.75rem;
                column-gap: 0.75rem
}
.footer-bottom{
        height: 8.25rem
}
.footer-bottom__wrapper{
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
        height: 100%;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center
}
.footer-bottom__list li {
        font-size: clamp(10px, 0.8vw, 14px)
}
.footer-bottom__list li + li {
        padding-left: 3rem
}
.footer-bottom__elements{
        width: 9.25rem;
        height: 1rem
}
.footer-bottom__tyrol{
        margin-right: 2.25rem
}
.content-block + .content-block,
.content-block + iframe + .content-block,
.pimcore_area_content + iframe + .content-block,
.content-block + .pimcore_area_content > .content-block,
.pimcore_area_content + iframe + .pimcore_area_content > .content-block,
.pimcore_area_content + .pimcore_area_content > .content-block,
.pimcore_area_content + .footer-top,
.parallax-area__content > .title-block.content-block {
        margin-top: 5rem
}
.content-block + .content-block--sm,
.content-block + .pimcore_area_content > .content-block--sm,
.content-block--sm + .content-block--sm,
.pimcore_area_content + .pimcore_area_content > .content-block--sm {
        margin-top: 5rem
}
.content-block + .content-block--xs,
.content-block + .pimcore_area_content > .content-block--xs,
.content-block--xs + .content-block--xs,
.pimcore_area_content + .pimcore_area_content > .content-block--xs {
        margin-top: 3.75rem
}
.content-block + .content-block--xl,
.content-block + .pimcore_area_content > .content-block--xl,
.content-block--xl + .content-block--xl,
.pimcore_area_content + .pimcore_area_content > .content-block--xl {
        margin-top: 7.5rem
}
.main-content > .content-block:last-child.content-block--no-footer-margin,
.pimcore_area_content:last-child .content-block.content-block--no-footer-margin {
        margin-bottom: -7.5rem
}
    .row-gutter--5 {
        margin-left: -1.5rem;
        margin-right: -1.5rem;
    }
    .row-gutter--5 > [class^="col"],
    .row-gutter--5 > [class*=" col"],
    .row-gutter--5.slick-slider [class^="col"],
    .row-gutter--5.slick-slider [class*=" col"] {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
.step-nav__item:not(:last-of-type) {
        grid-template-columns: 1.5rem minmax(9.375rem,16.25rem)
}
.step-nav__item:first-of-type {
        grid-template-columns: 1.5rem minmax(9.375rem,32.5rem)
}
.detail-grid {
        grid-template-columns: 58.69% 1fr;
        grid-template-rows: 1fr auto auto;
        gap: 2.5rem
}
.detail-grid__intro-area {
        grid-column: 1 / 2;
        grid-row: 1 / 2
}
.detail-grid__wysiwyg-area {
        grid-column: 1 / 2;
        grid-row: 3 / 4
}
.detail-grid__card-area {
        grid-column: 2 / 3;
        grid-row: 1 / 4
}
.info-grid ~.detail-grid__card-area{
        grid-column: 2 / 3;
        grid-row: 1 / 4

}
.info-grid ~.detail-grid__image-area{
        grid-column: 2 / 3;
        grid-row: 1 / 4

}
.detail-grid__image-area {
        grid-column: 1 / 2;
        grid-row: 2 / 3;
        margin-bottom: 4.5rem
}
.detail-intro__infos {
        gap: 0.625rem 1.5rem
}
.detail-intro__title {
        margin-bottom: 2.25rem;
        font-size: clamp(1.75rem, 2.5vw, 3rem)
}
.detail-intro__sub-title {
        margin-bottom: 3rem;
        font-size: 1.5rem
}
.detail-intro-image__decorator-img {
        width: 16.625rem
}
.detail-intro-image__decorator-text:before {
            font-size: 1.3125rem;
            width: 3.25rem
    }
.detail-intro-image__decorator-text {
        font-size: 1rem;
        bottom: 1rem
}
.detail-intro__wysiwyg p {
        margin-bottom: 1.875rem
}
.detail-intro__wysiwyg ul {
        margin-bottom: 1.875rem
}
    .ribbon__item.ribbon__item--date,
    .ribbon__item.ribbon__item--nights {
        padding: 11px 12px;
    }
.ribbon__item--flap {
        margin-left: -0.625rem;
        margin-right: -0.625rem;
        font-size: 1.25rem
}
.ribbon__item--flap:before {
        border-width: 0 0 0.625rem 0.625rem
}
.ribbon__item--flap:after {
        border-width: 0.625rem 0 0 0.625rem
}
    .teaser__img {
        min-height: 10rem;
    }
    .corridor__cell-btn {
        text-align: left;
    }
    .corridor__cell,
    .corridor__matrix-head {
        padding: 0.125rem;
    }

    .corridor__cell {
        min-height: 58px;
        padding: 0.0625rem;
    }

    .corridor__matrix {
        padding: 0;
    }

    .corridor__left {
        white-space: nowrap;
        width: auto;
        min-width: 108px;
    }

    .corridor__left .corridor__cell {
        padding-left: 15px;
        padding-right: 15px;
    }

    .corridor__matrix-head {
        height: 48px;
        padding: 6px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        -webkit-box-align:center;
            -ms-flex-align:center;
                align-items:center;
    }

    .corridor__matrix-head-day {
        font-size: 14px;
        line-height: 1;
    }

    .corridor__cell-btn.is-selected {
        color: #499F68;
    }

    .corridor__from {
        display: block;
        letter-spacing: 0;
        color: #595959;
        font-size: 0.625rem;
    }

    .is-selected .corridor__from {
        color: #499F68;
    }
.accordion-body{
        padding: 3rem
}
.accordion-body__headline{
        margin-bottom: 1.5rem
}
.accordion-body__content{
        margin-bottom: 1.625rem
}
    .cookie-bar {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: baseline;
            -ms-flex-align: baseline;
                align-items: baseline;
    }
    .cookie-bar__text {
        display: block;
        -webkit-box-flex: 0;
            -ms-flex: 0 1 auto;
                flex: 0 1 auto;
    }
    .cookie-bar__detail {
        -webkit-box-flex: 1;
            -ms-flex: auto;
                flex: auto;
        margin-left: 1rem;
    }
    .cookie-bar__buttons {
        -webkit-box-flex: 0;
            -ms-flex: none;
                flex: none;
        margin-left: 1rem;
    }
.fz32--desktop {
        font-size: 2rem !important
}}
@media (min-width: 768px) {
    body,
    .popover {
        font-size: 16px;
    }
    h1, .h1 {
        font-size: 36px;
        line-height: 0.9;
    }
    h2, .h2 {
        font-size: 24px;
        line-height: 1.16667;
    }
    h3, .h3 {
        font-size: 20px;
    }
    h4, .h4 {
        font-size: 18px;
        line-height: 1.33333;
    }
    h5, .h5 {
        font-size: 16px;
        line-height: 1.25;
    }
    h6, .h6 {
        font-size: 1em; /*todo*/
    }
    .row--gutter-width-40 {
        margin-left: -20px;
        margin-right: -20px;
    }
    .row--gutter-width-40 > [class^="col"],
    .row--gutter-width-40 > [class*=" col"] {
        padding-left: 20px;
        padding-right: 20px;
    }

    .row--gutter-width-60 {
        margin-left: -30px;
        margin-right: -30px;
    }
    .row--gutter-width-60 > [class^="col"],
    .row--gutter-width-60 > [class*=" col"] {
        padding-left: 30px;
        padding-right: 30px;
    }
.content-cca--small {
        padding: 0 6.875rem
}
    .overlay {
        width: 400px;
        left: auto;
        -webkit-box-shadow: 0 1px 3px rgba(0,0,0,0.3);
                box-shadow: 0 1px 3px rgba(0,0,0,0.3);
    }

    .overlay__content {
        font-size: 14px;
    }

    .list-with-map-container #search-overlay {
        position: absolute;
    }
.ribbon__item {
        padding: 20px 30px
}
    .ui-datepicker .ui-datepicker-title {
        text-transform: uppercase;
        font-family: "nunito-sans-extrabold", sans-serif;
        font-weight: 800;
    }
    .teaser {
        background-color: #fff;
        -webkit-transition: background-color 320ms ease;
        -o-transition: background-color 320ms ease;
        transition: background-color 320ms ease;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .teaser:hover,
    .teaser:focus {
        background-color: #f7f7f7;
    }

    .teaser__acco-title{
        font-size: 22px;
    }

    .teaser__price {
        font-size: 24px;
    }

    .teaser__price-body--big.teaser__price-body--big {
        border-left: 1px solid #D8D8D8;
        padding-left: 20px;
    }

    .teaser__label {
        /*position: absolute;*/
        /*top: -10px;*/
        /*left: -5px;*/
        /*padding: 8px 10px;*/
        font-size: 16px;
    }

    /*.teaser__label:before {*/
        /*content:"";*/
        /*bottom: -10px;*/
        /*display:block;*/
        /*position:absolute;*/
        /*left: 0;*/
        /*border-width: 10px 0 0 10px;*/
        /*border-style: solid;*/
        /*border-color: #CC6114 transparent transparent transparent;*/
        /*z-index:-1;*/
    /*}*/
    .card-body {
        padding: 2.5rem;
        display: block;
    }

    .card-ribbon {
        margin-left: -10px;
        margin-right: -10px;
    }

    .card-ribbon:before {
        border-width: 0 0 10px 10px;
    }

    .card-ribbon:after {
        border-width: 10px 0 0 10px;
    }

    .step-progress__icon-inner {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
    }

    .step-progress__icon-number {
        font-size: 16px;
    }

    .step-progress__icon {
        font-size: 14px;
        vertical-align: -.1em;
        background: #E6E6E6;
        border-radius: 50%;
        height: 30px;
        width: 30px;
        position: relative;
        margin: 0 auto;
        z-index: 1;
        font-weight: 700;
    }

    .step-progress__bar-item {
        position: relative;
        text-align: center;
    }

    .step-progress__bar-item:after {
        content: "";
        background-color: #E6E6E6;
        height: 3px;
        width: 100%;
        position: absolute;
        right: 50%;
        top: 14px;
    }

    .step-progress__bar-item.is-done .step-progress__icon {
        background-color: #D34442;
    }
    .step-progress__bar-item.is-done .step-progress__text {
        color: #D34442;
    }

    .step-progress__bar-item.is-active .step-progress__icon {
        background-color: #ECD7A3;
        color: white;
    }
    .step-progress__bar-item.is-active .step-progress__text{
        color: #ECD7A3;
    }

    .step-progress__bar-item.is-done:after {
        background-color: #499F68;
    }

    .step-progress__bar-item.is-active:after {
        background: -webkit-gradient(linear, left top, right top, from(#499F68), to(#ECD7A3));
        background: -webkit-linear-gradient(left, #499F68 0%, #ECD7A3 100%);
        background: -o-linear-gradient(left, #499F68 0%, #ECD7A3 100%);
        background: linear-gradient(90deg, #499F68 0%, #ECD7A3 100%);
    }

    .step-progress__bar-item:first-child:after {
        display: none;
    }

    .step-progress__text {
        font-size: 16px;
        margin-top: 10px;
        font-weight: 700;
        color: #8C8C8C;
    }
    .accommodation-map__detail:not(.is-open) {
        display: none;
    }
.nav-border-tabs {
        border-bottom-color: #D8D8D8
}
    .nav-border-tabs .nav-link:after {
        height: 3px;
        bottom: 0;
    }

    .nav-border-tabs {
        font-size: 16px;
        text-transform: uppercase;
    }

    .nav-border-tabs .nav-link {
        padding: 14px 20px;
    }

    .nav-border-tabs .nav-link:before {
        content: '';
        display: block;
        position: absolute;
        right: 0;
        width: 1px;
        height: 50%;
        top: 20%;
        background: #D8D8D8;
    }
    .nav-border-tabs .nav-item:last-child .nav-link:before {
        display: none;
    }
    .nearby-search-autocomplete {
        width: 210px;
    }
    .payment-details {
        padding: 1rem;
    }

    .payment-details__item {
        margin-bottom: 1rem;
    }

    .payment-card-icon {
        font-size: 32px;
    }
  .room-occupancy-compact__room-title {
    margin-left: .5rem;
    margin-top: 1.5rem;
  }

.screen-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    width: 100%;
    min-height: 100vh;
    padding-top: 120px;
}

.list-wrapper {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1 1 0%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    max-width: 100%;
}

.list__items-container {
    position: relative;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    width: 700px;
    max-width: calc(100vw - 295px);
}

    @media (max-width: 991px) {

.list__items-container {
        width: 100%;
        max-width: none
}
    }
.list__items-container.is-full-width {
    width: auto;
    -webkit-box-flex: 1;
        -ms-flex: auto;
            flex: auto;
    resize: none;
    max-width: none;
}


.accommodation-map {
    position: relative;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1 1 0%;
}


.list-with-map-container{
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1 1 0%;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    min-height: 0;
    overflow: hidden;
}

.detail-header--full {
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
    height: 300px;
    position: relative;
}

.gallery-buttons {
    position: absolute;
    left: 30px;
    bottom: 30px;
}

.gallery-button {
    color: white;
    text-align: center;
    padding: 15px;
    background-color: rgba(255,255,255,0.5);
    margin-right: 20px;
    position: relative;
    z-index: 1;
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
    border: none;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    gap: 10px;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    border-radius: 0.375rem;
}

.gallery-button:hover {
    color: #f2f2f2;
    text-decoration: none;
}

.gallery-button:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    z-index: -1;
    -webkit-transition: opacity 120ms ease-in-out;
    -o-transition: opacity 120ms ease-in-out;
    transition: opacity 120ms ease-in-out;
    -webkit-box-shadow: 0 1px 3px 0 rgba(0,0,0,0.2);
            box-shadow: 0 1px 3px 0 rgba(0,0,0,0.2);
}

.gallery-button:hover:after {
    opacity: 1;
}

.gallery-button__icon {
    font-size: 22px;
}

.detail-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    position: relative;
}

.thumb {
    margin: 0;
    border-right: 1px solid white;
    -ms-flex-negative: 0;
        flex-shrink: 0;
}

.thumb img {
    max-width: 100%;
    height: 300px;
}


/*detail-header--multi-images*/
.detail-header--multi-images {
    position: relative;
    height: 0;
    padding-top: 21.05263%;
    overflow: hidden;
}
.detail-header__img-bar {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    background: #000;
}
.detail-header__img-bar-item {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 0px;
            flex: 1 1 0%;
    height: 100%;
    min-width: 0;
    position: relative;
    overflow: hidden;
    background: #000;
    cursor: pointer;
}
.detail-header__img {
    height: 100%;
    min-width: 0;
    background-size: cover;
    background-position: center center;
    overflow: hidden;
    -webkit-transition: opacity 150ms ease-out, -webkit-transform 190ms ease-in;
    transition: opacity 150ms ease-out, -webkit-transform 190ms ease-in;
    -o-transition: opacity 150ms ease-out, transform 190ms ease-in;
    transition: opacity 150ms ease-out, transform 190ms ease-in;
    transition: opacity 150ms ease-out, transform 190ms ease-in, -webkit-transform 190ms ease-in;
    -webkit-transform: none;
            transform: none;
    -webkit-transform-origin: center center;
            transform-origin: center center;
}
.detail-header:hover .detail-header__img {
    opacity: .7;
}
.detail-header .detail-header__img:hover {
    opacity: 1;
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
}

.paging {

}

.paging__btn {
    width: 30px;
    height: 30px;
    display:-webkit-inline-box;
    display:-ms-inline-flexbox;
    display:inline-flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: centeR;
        -ms-flex-align: centeR;
            align-items: centeR;
    padding-left: 3px;
    padding-right: 3px;
    text-align: center;
}

.filter-bar {
    background-color: #FEFAF1;
    overflow: hidden;
}

/* always show filters in filter bar on tablet*/

.filter-button {
    position: relative;
    width: 210px;
    border-right: 1px solid rgba(255, 255, 255, 0.5);
    color: #1D1D1B;
    background-color: #FEFAF1;
    padding: 15px;
    font-size: 13px;
    cursor: pointer;
    -ms-flex-negative: 0;
        flex-shrink: 0;
}

.filter-button--has-filter:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 3px;
    background-color: #1D1D1B;
}

.filter-button__content {
    font-size: 16px;
    position: relative;
    padding-right: 30px;
    opacity: 0.7;
    margin-top: 3px;

    overflow: hidden;
    -o-text-overflow: ellipsis;
       text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
}


.filter-button--has-filter .filter-button__content {
    opacity: 1;
}

.filter-button__icon {
    font-size: 6px;
    position: absolute;
    top: 50%;
    right: 0;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}

.filter-button__text {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    -o-text-overflow: ellipsis;
       text-overflow: ellipsis;
}

.filter-bar__more-button {
    margin: auto 15px;
    font-size: 16px;
}

.filter-bar__more-button__icon {
    font-size: 5px;
    vertical-align: middle;
}

.filter-box__content {
    padding: 20px;
}
.filter-box__action {
    -webkit-box-shadow: inset 0 1px 0 0 #D8D8D8;
            box-shadow: inset 0 1px 0 0 #D8D8D8;
    text-align: right;
    font-size: 17px;
    font-family: "nunito-sans-bold", sans-serif;
    font-weight: 700;
}

.filter-box__action-item {
    padding: 20px;
}

.filter-box__action-item:not(:first-child) {
    margin-left: 10px;
}

.sorting-bar {
    background: white;
    border-bottom: 1px solid #D8D8D8;
    padding: 0 15px;
    font-size: 16px;
    color: #595959;
}

.sorting-bar-btn {
    position: relative;
    display: inline-block;
    padding: 10px 0;
    margin-left: 12px;
    margin-right: 12px;
    cursor: pointer;
    line-height: 28px;
}

.sorting-bar-btn--has-icon {
    padding-right: 20px;
}

.sorting-bar-btn__icon {
    font-size: 6px;
    position: absolute;
    top: 50%;
    right: 4px;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    color: #ECD7A3;
}

/*active button*/
input:checked + .sorting-bar-btn__content {
    color: #ECD7A3;
}

input:checked + .sorting-bar-btn__content:after {
    content: "";
    background: #ECD7A3;
    height: 3px;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
}

.sorting-bar__order-icon {
    font-size: 12px;
}

.sorting-order-btn input:checked ~ .sorting-order-btn__content--unchecked {
    display: none;
}

.sorting-order-btn input:not(:checked) ~ .sorting-order-btn__content--checked {
    display: none;
}

.search-info {
    background-color:#fff;
    z-index: 1;
}

.search-info__data {
    padding: 11px 38px;
}

.search-info-item {
    border-right: 1px solid rgba(236, 215, 163, 0.46);
    text-align: center;
    font-weight: bold;
    font-size: 16px;
}

.search-info__back-btn {
    height: 100%;
    background-color: #f2f2f2;
    color: #8C8C8C;
    padding: 0 19px;
    border-right: 1px solid #D8D8D8;
}

.search-info__from-to-icon {
    font-size: 12px;
}

.search-info__hotline {
    margin-left: auto;
    margin-right: 30px;
}

.search-info__hotline__link {
    color: #ECD7A3;
    font-size: 18px;
}

.search-info__hotline__image {
    max-height: 68px;
    width: auto;
}
.sidebar {
    display: block;
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    width: 320px;
    padding-top: 0;
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    -webkit-transition: visibility 0.001ms 180ms, -webkit-transform 180ms ease-out;
    transition: visibility 0.001ms 180ms, -webkit-transform 180ms ease-out;
    -o-transition: transform 180ms ease-out, visibility 0.001ms 180ms;
    transition: transform 180ms ease-out, visibility 0.001ms 180ms;
    transition: transform 180ms ease-out, visibility 0.001ms 180ms, -webkit-transform 180ms ease-out;
    background: white;
    z-index: 1100;
    overflow: hidden;
    will-change: transform;
    visibility: hidden;
    -webkit-box-shadow: 0 1px 3px 0 rgba(0,0,0,0.2);
            box-shadow: 0 1px 3px 0 rgba(0,0,0,0.2);
}
.sidebar.is-open {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    visibility: visible;
    -webkit-transition: visibility 0.001ms, -webkit-transform 180ms ease-out;
    transition: visibility 0.001ms, -webkit-transform 180ms ease-out;
    -o-transition: transform 180ms ease-out, visibility 0.001ms;
    transition: transform 180ms ease-out, visibility 0.001ms;
    transition: transform 180ms ease-out, visibility 0.001ms, -webkit-transform 180ms ease-out;
}
.sidebar__content {
    position: absolute;
    left: 0;
    right: 0;
    top: 60px;
    bottom: 0;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    padding: 20px 30px;
}
.sidebar__header {
    height: 60px;
    position: relative;
    z-index: 500;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    font-size: 17px;
    font-family: "nunito-sans-bold", sans-serif;
    font-weight: 700;
    background: #ECD7A3;
    color: white;
    padding-left: 30px;
}
.sidebar__header-item {
    padding: 20px;
    min-width: 0; /*to allow text-truncate*/
}
.sidebar__header .sidebar__header-item:first-child {
    padding-left: 0;
}

.sidebar-open:after {
    content: "";
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0,0,0,.7);
}
.list-toggle {
    position: fixed;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    bottom: 20px;
}
body.has-cookie-bar .list-toggle {
    bottom: 120px;
}

.list-toggle__btn {
    color: white;
    background-color: #595959;
}

.btn-group.list-toggle>.btn:last-child:not(:first-child) {
    border-left-color: #8C8C8C;
}
.room-selection-box {
    font-size: 14px;
}
.room-selection-box__body {
    padding:20px;
}
.room-selection-box .room-selection-box__headline {
    font-size: 18px;
}
.room-selection-box .btn-show-gallery {
    right: 5px;
    bottom: 5px;
}

.room-row__image {
    min-height: 140px;
    width: 11.25rem;
}

.room-row__price {
    font-size: 24px;
    line-height:1;
}

.room-row__select-room-btn,
.room-row__book-now-btn {
    font-size: 12px;
    width: 100%;
}

.room-row__border-left {
    border-left: 1px solid #D8D8D8;
}

.collapse-arrow {
    font-size: 6px;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    -webkit-transition: 120ms ease-out;
    -o-transition: 120ms ease-out;
    transition: 120ms ease-out;
}
.collapsed .collapse-arrow {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
}

.facility-list__content {
    padding-top: 36px;
    padding-bottom: 30px;
}

.facility-list__item:last-child .facility-list__content {
    padding-bottom: 0;
}

.facility-list__content--bordered {
    border-top: 1px solid #D8D8D8;
}
.facility-list {
    padding-left: 1.5625rem;
}

.offer-box__title {
    font-size: 18px;
}

.offer-box__img {
    min-height: 70px;
    width: 70px;
}

.offer-box__price {
    font-size: 24px;
    line-height: 1;
}

.detail-gallery__row {
    height: 100%;
}

.detail-gallery__img {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1 1 0%;
    background: #f2f2f2;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}
.offer-teaser {
    border: 1px solid #D8D8D8;
    background: #fff;
}
.offer-teaser .offer-teaser {
    padding: .5rem 1rem;
    border: none;
    margin-bottom: 1rem;
}

ul li:not(:last-child) .offer-teaser {
    border-bottom: 0;
}
.cookie-first__accept-category {
        font-size: 0.875rem;
        padding: 1rem 1.875rem
}
    .additional-service__block {
        margin-top:1.5rem;
        padding-bottom:1.5rem;
        border-bottom:1px solid;
        border-bottom-color: #D8D8D8;
    }
    .additional-service__hl {
        margin-bottom:1rem;
        color:#ECD7A3;
    }
    .additional-offer-card--sticky {
        position: sticky;
        top:1rem;
        right:0;
        z-index:50;
    }
    .bordered { border: 1px solid #D8D8D8; }}
@media screen and (min-width: 992px) {
.main-navbar__item--search {
        margin-left: 2.5rem
}
    .mega-nav--level-0 {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
        -webkit-box-align: start;
            -ms-flex-align: start;
                align-items: flex-start;
    }
    .mega-nav__item-content--level-0 {
        font-size: 1rem;
    }
    .mega-nav__list--level-0 {
        width: 21.875rem;
        padding-left: 0;
    }
    .mega-nav__collapse-icon--level-0 {
        margin-left: 1.25rem;
        font-size: 0.75rem;
    }
    .mega-nav--level-1 {
        left: 20rem;
        padding: 3.75rem 4.375rem;
    }
    .mega-nav__title-link--level-1 {
        font-size: 1rem;
    }
    .mega-nav__item-content--level-1 {
        font-size: 1rem;
        padding: 0.75rem 0;
    }
    .mega-nav--level-2 {
        margin: 0 0 1.5rem 1.5rem;
    }
    .mega-nav__item-content--level-2,  .mega-nav__title-link--level-2{
        font-size: 1rem;
        line-height: 1.375;
        margin-bottom: 0.75rem;
    }
    .mega-nav--level-3 {
        margin-left: 1rem;
    }
    .mega-nav__item-content--level-3,  .mega-nav__title-link--level-3 {
        font-size: 0.75rem;
        line-height: 1.33333;
        margin-bottom: 0.75rem;
    }
.footer-top.container{
        padding-bottom: 9rem
}
.footer-top__social-media{
        margin-top: 2.25rem
}
.detail-grid--acco {
        grid-template-columns: 58.69% 1fr;
        grid-template-rows: 1fr auto auto;
        gap: 2.5rem
}}
@media (min-width: 992px) {
    .order-lg-1 {
        -webkit-box-ordinal-group: 2;
            -ms-flex-order: 1;
                order: 1;
    }

    .order-lg-12 {
        -webkit-box-ordinal-group: 13;
            -ms-flex-order: 12;
                order: 12;
    }}
@media screen and (min-width: 1200px) {
.main-navbar__container {
        margin: 0 1.875rem
}
.main-navbar__area-meta-nav {
        margin-left: 2.5rem
}
.main-navbar__area-regions-shop {
        -webkit-column-gap: 1.875rem;
           -moz-column-gap: 1.875rem;
                column-gap: 1.875rem
}
.main-navbar__area-menu-search {
        -webkit-column-gap: 1.25rem;
           -moz-column-gap: 1.25rem;
                column-gap: 1.25rem
}
.main-navbar__list {
        -webkit-column-gap: 1.5rem;
           -moz-column-gap: 1.5rem;
                column-gap: 1.5rem
}
.main-navbar__area-contact-login {
        -webkit-column-gap: 0.9375rem;
           -moz-column-gap: 0.9375rem;
                column-gap: 0.9375rem
}
        .nav-overlay:before {
            width: 34.58333%;
        }
.mega-nav__container {
        padding-top: 22.59259vh;
        padding-left: 2.5rem
}
.mega-nav__image-teaser {
        top: 3.75rem;
        right: 2.5rem
}
        .mega-nav__image-teaser .image-teaser {
            width: 20.9375vw;
        }
    .mega-nav--level-1 {
        left: 34.58333%;
    }
        .mega-nav--level-1.mag-nav--has-image-teaser  {
            width: auto;
            max-width: unset;
            right: 0;
        }
.footer-top__links{
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center
}}
@media (min-width: 1200px) {
    .row--gutter-width-xl-60 {
        margin-left: -30px;
        margin-right: -30px;
    }
    .row--gutter-width-xl-60 > [class^="col"],
    .row--gutter-width-xl-60 > [class*=" col"] {
        padding-left: 30px;
        padding-right: 30px;
    }
    .map-tooltip {
        font-size: 13px
    }}
@media screen and (min-width: 1400px) {
.main-navbar__container {
        margin: 0 3.75rem
}
.main-navbar__area-meta-nav {
        margin-left: 3.75rem
}
.main-navbar__area-regions-shop {
        -webkit-column-gap: 3rem;
           -moz-column-gap: 3rem;
                column-gap: 3rem
}
.main-navbar__area-menu-search {
        -webkit-column-gap: 3.75rem;
           -moz-column-gap: 3.75rem;
                column-gap: 3.75rem
}
.main-navbar__list {
        -webkit-column-gap: 2.25rem;
           -moz-column-gap: 2.25rem;
                column-gap: 2.25rem
}
.main-navbar__area-contact-login {
        -webkit-column-gap: 1.5rem;
           -moz-column-gap: 1.5rem;
                column-gap: 1.5rem
}
.mega-nav__container {
        padding-left: 3.75rem
}
    .mega-nav__item-content--level-0 {
        font-size: 1.125rem;
        line-height: 1.375rem;
    }
    .mega-nav__list--level-0 {
        width: 25rem;
    }
    .mega-nav__collapse-icon--level-0 {
        margin-left: 1.375rem;
        font-size: 0.8125rem;
    }
    .mega-nav__title-link--level-1 {
        font-size: 1.25rem;
    }
    left: 43.625rem;
    .mega-nav__item-content--level-2,  .mega-nav__title-link--level-2{
        font-size: 1rem;
        line-height: 1.25rem;
    }
    .mega-nav__item-content--level-3,  .mega-nav__title-link--level-3 {
        font-size: 0.75rem;
        line-height: 1.33333;
    }
.footer-top__contact{
        margin-top: 3rem;
        row-gap: 1.5rem
}
.footer-top__links .btn{
        font-size: clamp(10rem/16, 0.8vw, 16rem/16);
        padding-left: clamp(8rem/16,1.7vw,36rem/16);
        padding-right: clamp(8rem/16,7vw,36rem/16)
}
.content-block + .content-block,
.content-block + iframe + .content-block,
.pimcore_area_content + iframe + .content-block,
.content-block + .pimcore_area_content > .content-block,
.pimcore_area_content + iframe + .pimcore_area_content > .content-block,
.pimcore_area_content + .pimcore_area_content > .content-block,
.pimcore_area_content + .footer-top,
.parallax-area__content > .title-block.content-block {
        margin-top: 5rem
}
.content-block + .content-block--sm,
.content-block + .pimcore_area_content > .content-block--sm,
.content-block--sm + .content-block--sm,
.pimcore_area_content + .pimcore_area_content > .content-block--sm {
        margin-top: 5rem
}
.content-block + .content-block--xs,
.content-block + .pimcore_area_content > .content-block--xs,
.content-block--xs + .content-block--xs,
.pimcore_area_content + .pimcore_area_content > .content-block--xs {
        margin-top: 4.5rem
}
.content-block + .content-block--xl,
.content-block + .pimcore_area_content > .content-block--xl,
.content-block--xl + .content-block--xl,
.pimcore_area_content + .pimcore_area_content > .content-block--xl {
        margin-top: 11.25rem
}}
@media (min-width: 1400px) {}
@media screen and (min-width: 1600px) {
.mega-nav__container {
        padding-left: 7.5rem
}
.card-list-item {
        padding-top: 1.5rem;
        font-size: 1rem;
        padding-bottom: 1.5rem
}}
@media (min-width: 1600px) {}
@media screen and (min-width: 1660px) {}
@media screen and (min-width: 768px) and (max-width: 991px) {
    body {
        width: 982px;
    }
    .container {
        width: 100%;
    }
.main-navbar--shadow .main-navbar__brand {
            width: 4.5rem;
            height: 5rem
    }
.main-navbar__brand {
        width: 3.9375rem;
        height: 4.375rem
}
.main-navbar__list {
        -webkit-column-gap: 1.5rem;
           -moz-column-gap: 1.5rem;
                column-gap: 1.5rem
}
.main-navbar__overlay-close {
        font-size: 1.25rem;
        top: 6.25rem
}
.main-navbar__overlay-close__icon {
        font-size: 1rem
}
.main-navbar__overlay-title {
        text-align: center;
        font-size: 1.5rem;
        margin-bottom: 1rem
}
    .mega-nav__cta-button-wrapper {
        top: auto;
        bottom: 2rem;
        right: 0;
        left: 0;
        display: grid;
        grid-template-rows: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
        grid-gap: 1rem;
        padding: 0 1rem;
    }
    .mega-nav__cta-button {
        padding: 1.5rem;
        width: auto;
        height: auto;
        min-height: 8rem;
        margin: 0;
    }
    .mega-nav__cta-button-title{
        font-size: 1.35rem;
        letter-spacing: 0.0625rem;
        line-height: 2rem;
    }
    .mega-nav__cta-button-icon {
        font-size: 1.75rem;
    }
    .accommodation-map:not(.accommodation-map--full) .accommodation-map__controls {
        display: none;
    }
}
@media screen and (min-width:768px) {
.main-content--no-hero {
        padding-top: 6.875rem
}
.title-block__title {
        font-size: 3rem
}
h2, .h2 {
        font-size: 2.5rem
}
h3, .h3 {
        font-size: 1.75rem
}
h4, .h4 {
        font-size: 1.5rem
}
h5, .h5 {
        font-size: 1.25rem
}
h6, .h6 {
        font-size: 1rem
}
    }
@media screen and (min-width: 1700px) {
.main-navbar__item--search {
        margin-left: 3.75rem
}
.mega-nav__image-teaser {
        top: 12.5rem;
        right: 7.5rem
}
    .mega-nav__item-content--level-0 {
        font-size: 1.25rem;
        line-height: 1.5rem;
    }
    .mega-nav__list--level-0 {
        width: 25rem;
    }
    .mega-nav__collapse-icon--level-0 {
        margin-left: 2.25rem;
        font-size: 0.75rem;
    }
    }
@media screen and (min-width: 768px) and (max-width: 1199px) {
.main-navbar__item-icon {
        margin-right: 0.375rem;
        font-size: 0.75rem
}
    }
@media screen and (max-width: 1599px) {
.main-navbar__shop-link-text {
        display: none
}
.main-navbar__link-text {
        display: none
}
    }
@media screen and (max-width: 1199px) {
.main-navbar__overlay {
        background: white;
        overflow-y: auto;
        overflow-x: hidden;
        bottom:0;
        height: 100%
}
.mega-nav__image-teaser {
        display: none
}
.footer-top__links{
        -webkit-column-gap: 0.5rem;
           -moz-column-gap: 0.5rem;
                column-gap: 0.5rem
}
    }
@media screen and (max-width: 991px) {
.main-navbar__toggle__text {
        display: none
}
.vertical-gutter--24-xs {
        margin-top: -0.75rem
}
.vertical-gutter--24-xs > .vertical-gutter__item,
.vertical-gutter--24-xs > [class^="col"],
.vertical-gutter--24-xs > [class*=" col"] {
        margin-top: 1.5rem
}
.vertical-gutter--48-xs {
        margin-top: -1.5rem
}
.vertical-gutter--48-xs > .vertical-gutter__item,
.vertical-gutter--48-xs > [class^="col"],
.vertical-gutter--48-xs > [class*=" col"] {
        margin-top: 3rem
}
    }
@media (hover: none) {
    .mega-nav__item.has-children > .simple-nav__item-content--link {
        display: none;
    }
    .mega-nav__item.has-no-children > .simple-nav__item-content--toggle {
        display: none;
    }
    .mega-nav__item-content--level-0 {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }
}
@media screen and (min-width:1700px) {
    .mega-nav--level-1 {
        padding-top: 12.75rem;
        padding-left: 7.5rem;
        left: 34.58333%;
    }
    .mega-nav__title--level-1 {
        font-size: 1.375rem;
    }
}
@media screen and (min-width: 1500px) {
.prospect-grid {
        gap: 0 9.375rem
}
.detail-grid {
        gap: 2.5rem 9.375rem
}
.detail-grid--acco {
        gap: 2.5rem clamp(3rem, 8vw, 9.375rem)
}
    }
@media screen and (max-width: 768px) {
.card-body {
        font-size: 0.875rem
}
.image-teaser__title--big {
        bottom: 3rem
}
.image-teaser__subtitle {
        bottom: 1.5rem;
        left: 1.5rem
}
    }
@media screen and (max-width: 1600px) {
.card-title {
        margin-bottom: 0.9375rem
}
    }
@media screen and (min-width: 922px){
.footer-top__header{
        margin-bottom: 5rem
}
    }
@media screen and (min-width: 992px) and (max-width: 1199px) {
.footer-top__links .btn{
        font-size: clamp(10rem/16, 0.8vw, 16rem/16);
        padding-left: 1.25rem;
        padding-right: 1.25rem;

        row-gap: 0.75rem
}
    }
@media screen and (min-width: 1200px) and (max-width: 1399px) {
.footer-top__links .btn{
        font-size: clamp(10rem/16, 0.8vw, 16rem/16);
        padding-left: 1rem;
        padding-right: 1rem
}
  	}
@media screen and(min-width: 768px) {
.cookie-modal__content {
        padding: 1.875rem
}
.cookie-modal__title {
        font-size: 1.625rem
}
    }
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    /*IE 11 nested overlay fix*/
    .overlay .overlay {
        position: absolute;
    }
.corridor__body {
        /*IE 11 fix*/
        -ms-flex-preferred-size: 100%;
            flex-basis: 100%
}
}
@media screen and (min-width: 767px) {
    .form-box__label {
        font-size: 10px;
    }

}
@media (max-width: 1439px) {
    .accommodation-map__controls--drawing {
        margin-top: 10px;
    }

    .map-tooltip--bottom-left-md-down {
        right: 0;
        top: 100%;
        margin-top: 15px;
        margin-right: 0;
        -webkit-transform: none;
                transform: none;
    }

    .map-tooltip--bottom-left-md-down:after {
        content: '';
        position: absolute;
        display: block;
        width: 0;
        right: 10px;
        top: 0;
        border: 10px solid transparent;
        border-top: 0;
        border-bottom: 10px solid white;
        -webkit-transform: translate(0,-100%);
                transform: translate(0,-100%);
    }
}
@media (max-width: 1199px) {
    .accommodation-map__clear-btn {
        position: absolute;
        top: 100%;
        right: 0;
        margin-right: 0 !important;
        margin-top: 10px;
    }
    
}
@media (min-width: 1199px) {
    .nearby-search-autocomplete {
        width: 250px;
    }
}
@media (min-width: 1300px) {
    .nearby-search-autocomplete {
        width: 320px;
    }
}
@media (max-width: 991px) {
    @media (min-width: 768px) {

.list__items-container {
        width: 100%;
        max-width: none
}
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .accommodation-map {
        display: none;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
    .accommodation-map.is-visible {
        display: block;
    }
}
@media (min-width: 768px) and (min-width: 992px) {
    .detail-header--full {
        height: 500px;
    }
    .thumb img {
        height: 350px;
    }
}
@media (min-width: 768px) and (min-width: 1200px) {
    .detail-header--full {
        height: 540px;
    }
    .thumb img {
        height: 400px;
    }
}
@media (min-width: 768px) and (max-width: 992px) {
    .filter-bar-alternative .filter-block {
        display: block;
    }
}
@media (min-width: 768px) and screen and (max-width: 1199px) {
    .search-info-item {
        font-size: 17px;
    }

    .search-info__data {
        padding: 11px;
    }

    .search-info__hotline {
        margin-right: 15px;
    }
}
@media screen and (min-width: 768px) and (max-width: 992px){
.merch-size__radio>label,
.merch-size__item{
        width: 2.25rem;
        font-size: 0.875rem
}
    }