.title-block__top-title {
    color: var(--color-primary);
    text-transform: uppercase;
    letter-spacing: calc(8rem/16);
    margin-bottom: calc(6rem/16);
    font-size: calc(16rem/16);
    line-height: calc(22rem/16);
    font-family: var(--font-default-extra-bold);
    @media screen and (min-width: 768px) {
        margin-bottom: calc(12rem/16);
    }
}
.title-block__title {
    color: var(--color-text-default);
    margin-bottom: calc(24rem/16);
    @media screen and (min-width: 768px) {
        margin-bottom: calc(36rem/16);
    }
}
.title-block--centered {
    text-align: center;
}
.title-block__sub-title {
    font-size: calc(16rem/16);
    line-height: calc(22/16);
    font-family: var(--font-default);
    font-weight: var(--font-weight-default);

    @media screen and (min-width: 768px) {
        font-size: calc(24rem/16);
        line-height: calc(42/24);
    }
}
.title-block__image-divider {
    width: calc(24rem/16);
    height: auto;
    margin-bottom: calc(12rem/16);
}