.acco-teaser {
    border-radius: 6px;
    box-shadow: 20px 20px 40px rgba(51,51,49,0.12);
    position: relative;
    background-color: #fff;
}
.acco-teaser__title {
    font-size: calc(14rem/16);
    color:#333331;
}
.acco-teaser__test-acco-notice {
    position: absolute;
    top:0;
    z-index: 1;
    left:0;
}