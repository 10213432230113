.main-navbar {
    width: 100%;
    z-index: 10;
    position: relative;
    @media screen and (max-width: 767px) {
        z-index: 12;
    }
}

.main-navbar-nav {
    background: var(--color-main-nav-bg);
    backdrop-filter: blur(10px);
    display: flex;
    align-items: center;
    @media screen and (min-width: 768px) {
        padding: calc(15rem/16) 0;
        min-height: calc(120rem/16);
    }
}
.main-navbar--fixed {
    position: fixed;
    left:0;
    right:0;
    top:0;
}
.main-navbar--shadow {
    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        pointer-events: none;
        height: calc(264rem/16);
        background: linear-gradient(to bottom, black, transparent);
        opacity: .3;
    }

    .main-navbar-nav {
        background-color: transparent;
        backdrop-filter: none;
        color: white;
        min-height: unset;
    }

    .main-navbar__brand {
        width: calc(107rem/16);
        height: calc(120rem/16);
        @media screen and (max-width: 767px) {
            width: calc(53rem/16);
            height: calc(60rem/16);
        }
        @media screen and (min-width: 768px) and (max-width: 991px) {
            width: calc(72rem/16);
            height: calc(80rem/16);
        }
    }

    .main-navbar__brand--skijuwel {
        width: calc(270rem/16);
        height: auto;
        aspect-ratio: 220 / 96;
        position: relative;
        @media screen and (max-width: 767px) {
            width: calc(146rem/16);
            right: calc(-9rem/16);
        }
    }

    .main-navbar__brand--wildschoenau {
        width: calc(165rem/16);
        height: calc(100rem/16);
        @media screen and (max-width: 767px) {
            width: calc(79rem/16);
            height: calc(48rem/16);
        }
    }

    .main-navbar__brand--cca {
        width: calc(300rem/16);
        height: auto;
        aspect-ratio: 254 / 90;
        padding-top: 0.5rem;

        @media screen and (max-width: 767px) {
            width: calc(120rem/16);
        }
    }

    .main-navbar__brand--brixlegg {
        width: calc(150rem/16);
        height: auto;

        @media screen and (min-width: 768px) {
            width: calc(216rem/16);
            height: auto;
        }
    }

    .main-navbar__area-meta-nav {
        @media screen and (max-width: 767px) {
            background-color: rgba(#333331, .4);
        }
    }
}

.main-navbar--show-meta-nav-mobile {
    @media screen and (max-width: 767px) {
        .main-navbar__area-meta-nav {
            transform: translateY(0);
            transition: transform .3s ease;
            visibility: visible;
        }
        .main-navbar__container {
            z-index: 10;
            position: relative;
            width: 100%;
            display: grid;
            grid-template-rows: auto 1fr;
            grid-template-columns: repeat(3, minmax(0, 1fr));
            grid-template-areas:
        "area-meta-nav area-meta-nav area-meta-nav"
        "area-menu-search area-logo area-regions-shop";
        }
        .main-navbar__brand {
            width: calc(53rem / 16);
            height: calc(60rem / 16);
        }
        .main-navbar__brand--skijuwel {
            width: calc(150rem/16);
            height: auto;
            aspect-ratio: 220 / 96;
        }
        .main-navbar__brand--wildschoenau {
            width: calc(80rem/16);
            height: calc(48rem/16);
        }
        .main-navbar__brand--cca {
            width: calc(140rem/16);
            height: auto;
            aspect-ratio: 254 / 90;
        }
        .main-navbar__brand--brixlegg {
            width: calc(150rem/16);
            height: auto;
        }
    }
}

.main-navbar__container {
    z-index: 10;
    position: relative;
    width: 100%;
    display: grid;
    grid-template-rows: 0 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas:
    "area-meta-nav area-meta-nav area-meta-nav"
    "area-menu-search area-logo area-regions-shop";
    @media screen and (min-width: 768px) {
        margin: 0 calc(15rem/16);
        grid-template-columns: auto 1fr 10% 45%;
        grid-template-areas: "area-menu-search area-meta-nav area-logo area-regions-shop";
        grid-template-rows: 1fr;
        width: 100vw;
    }
    @media screen and (min-width: 1200px) {
        margin: 0 calc(30rem/16);
    }
    @media screen and (min-width: 1400px) {
        margin: 0 calc(60rem/16);
    }
}
.main-navbar__brand {
    width: calc(80rem/16);
    height: calc(90rem/16);
    object-fit: cover;
    transition: width .3s ease, height .3s ease;
    @media screen and (max-width: 767px) {
        width: calc(53rem/16);
        height: calc(60rem/16);
    }
    @media screen and (min-width: 768px) and (max-width: 991px) {
        width: calc(63rem/16);
        height: calc(70rem/16);
    }
}

.main-navbar__brand--skijuwel {
    width: calc(150rem/16);
    height: auto;
    aspect-ratio: 220 / 96;
    @media screen and (max-width: 767px) {
        width: calc(150rem/16);
        height: auto;
    }
    @media screen and (min-width: 768px) {
        width: calc(216rem/16);
        height: auto;
    }
}

.main-navbar__brand--wildschoenau {
    @media screen and (max-width: 767px) {
        width: calc(80rem/16);
        height: calc(48rem/16);
    }
    @media screen and (min-width: 768px) {
        width: calc(120rem/16);
        height: calc(70rem/16);
    }
}

.main-navbar__brand--cca {
    width: calc(160rem/16);
    height: auto;
    aspect-ratio: 254 / 90;
    @media screen and (max-width: 767px) {
        width: calc(120rem/16);
        height: auto;
    }
    @media screen and (min-width: 768px) {
        width: calc(200rem/16);
        height: auto;
    }
}

.main-navbar__brand--brixlegg {
    width: calc(150rem/16);
    height: auto;

    @media screen and (min-width: 768px) {
        width: calc(216rem/16);
        height: auto;
    }
}

.main-navbar__area-logo, .main-navbar__area-toggle {
    display: flex;
    align-items: center;
}
.main-navbar__area-logo {
    grid-area: area-logo;
    display: flex;
    justify-content: center;
    opacity: 1;
    transition: opacity 300ms ease;
    @media screen and (max-width: 767px) {
        padding: calc(8rem/16) 0;
    }
    .body--freezed & {
        opacity: 0;
    }
}
.main-navbar__area-toggle {
    grid-area: area-toggle;
}
.main-navbar__area-nav-list {
    display: flex;
    grid-area: area-nav-list;
    align-items:center;
    justify-self: end;
}
.main-navbar__area-lang-switch {
    grid-area: area-lang-switch;
    justify-self: flex-end;
}
.main-navbar__area-meta-nav {
    display: flex;
    align-items: center;
    grid-area: area-meta-nav;
    opacity: 1;
    transition: opacity 300ms ease;
    @media screen and (max-width: 767px) {
        justify-content: center;
        margin-bottom: calc(4rem/16);
        transform: translateY(-100%);
        visibility: hidden;
        padding: calc(12rem/16) 0;
        min-height: calc(49rem/16);
    }
    @media screen and (min-width: 768px) {
        margin-left: calc(20rem/16);
    }
    @media screen and (min-width: 1200px) {
        margin-left: calc(40rem/16);
    }
    @media screen and (min-width: 1400px) {
        margin-left: calc(60rem/16);
    }
    .body--freezed & {
        opacity: 0;
    }
}
.main-navbar__area-regions-shop {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    grid-area: area-regions-shop;
    opacity: 1;
    transition: opacity 300ms ease;
    @media screen and (max-width: 767px) {
        margin-right: calc(38rem/16);
        column-gap: calc(24rem/16);
    }
    @media screen and (min-width: 768px) {
        column-gap: calc(16rem/16);
    }
    @media screen and (min-width: 1200px) {
        column-gap: calc(30rem/16);
    }
    @media screen and (min-width: 1400px) {
        column-gap: calc(48rem/16);
    }
    .body--freezed & {
        opacity: 0;
    }
}
.main-navbar__area-menu-search {
    display: flex;
    grid-area: area-menu-search;
    @media screen and (max-width: 767px) {
        margin-left: calc(38rem/16);
    }
    @media screen and (min-width: 768px) {
        column-gap: calc(10rem/16);
    }
    @media screen and (min-width: 1200px) {
        column-gap: calc(20rem/16);
    }
    @media screen and (min-width: 1400px) {
        column-gap: calc(60rem/16);
    }
}


.main-navbar__list {
    display: flex;
    column-gap: calc(36rem/16);
    @media screen and (min-width: 768px) and (max-width: 991px) {
        column-gap: calc(24rem/16);
    }
    @media screen and (min-width: 1200px) {
        column-gap: calc(24rem/16);
    }
    @media screen and (min-width: 1400px) {
        column-gap: calc(36rem/16);
    }
}


.main-navbar__item__link {
    font-size: calc(14rem/16);
    line-height: calc(19/14);
    display: flex;
    align-items: center;
    background-color: transparent;
    border: none;
    box-shadow: none;
    overflow: hidden;
    position: relative;
    z-index: 1;
    padding: calc(3rem/16) 0;
}
.main-navbar__item__link::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: calc(1rem/16);
    z-index: -1;
    transition: width 0.3s ease;
    background-color: var(--color-secondary);
}
.main-navbar__item__link:hover {
    color: var(--color-secondary);
}
.main-navbar__item__link:hover::before {
    width: 100%;
}
.main-navbar__item__link.is-active {
    color: var(--color-secondary);
}
.main-navbar__item:not(.main-navbar__item--search) {
    transition: transform 0.2s ease;
    -webkit-backface-visibility: hidden;
}
.main-navbar__item--search {
    margin-left: calc(60rem/16);
    color: var(--color-text-muted);
    @media screen and (min-width: 992px) {
        margin-left: calc(40rem/16);
    }
    @media screen and (min-width: 1700px) {
        margin-left: calc(60rem/16);
    }
}
.main-navbar__item-icon {
    font-size: calc(16rem/16);
    font-weight: 400;
    margin-right: calc(6rem/16);
    @media screen and (min-width: 768px) and (max-width: 1199px) {
        margin-right: calc(6rem/16);
        font-size: calc(12rem/16);
    }
}
.main-navbar__item-icon--small {
    font-size: calc(10rem/16);
}
.main-navbar__shop-link {
    display: flex;
    align-items: center;
    column-gap: calc(12rem/16);
    color: var(--color-secondary);
    transition: color 300ms ease;
    .main-navbar--shadow & {
        color: white;
    }
    .body--freezed & {
        @media screen and (max-width: 767px) {
            display: none;
        }
    }
}
.main-navbar__shop-link--btn {
    @media screen and (min-width: 768px) {
        padding: calc(15rem/16) calc(24rem/16);
        background-color: var(--color-secondary);
        color: white;
        border-radius: calc(53rem/16);
        transition: background-color 300ms ease;
    }
    .main-navbar--shadow & {
        background-color: transparent;
    }
    &:hover {
        color: white;
    }
}
.main-navbar__shop-link--pull-right {
    @media screen and (min-width: 768px) {
        margin-right: calc(-36rem/16)
    }
}
.main-navbar__shop-link--btn-white {
    background-color: white;
    color: var(--color-secondary);
    border-radius: calc(53rem/16);
    transition: background-color 300ms ease;
    @media screen and (min-width: 768px) {
        padding: calc(15rem/16) calc(24rem/16);
    }
    .main-navbar--shadow & {
        background-color: transparent;
    }
    &:hover {
        color: var(--color-secondary);
        background-color: #F5F5F5;
    }
}
.main-navbar__shop-link-text {
    text-transform: uppercase;
    letter-spacing: calc(2rem/16);
    font-size: calc(14rem/16);
    font-weight: var(--font-weight-extra-bold);
    @media screen and (max-width: 1599px) {
        display: none;
    }
}
.main-navbar__shop-link-icon {
    font-size: calc(18rem/16);
    @media screen and (max-width: 767px) {
        font-size: calc(16rem/16);
    }
}

.main-navbar__area-contact-login {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    grid-area: area-regions-shop;
    opacity: 1;
    transition: opacity 300ms ease;
    @media screen and (max-width: 767px) {
        margin-right: calc(38rem/16);
        column-gap: calc(12rem/16);
    }
    @media screen and (min-width: 768px) {
        column-gap: calc(8rem/16);
    }
    @media screen and (min-width: 1200px) {
        column-gap: calc(15rem/16);
    }
    @media screen and (min-width: 1400px) {
        column-gap: calc(24rem/16);
    }
    .body--freezed & {
        opacity: 0;
    }
}
.main-navbar__link {
    display: flex;
    align-items: center;
    column-gap: calc(12rem/16);
    color: var(--color-secondary);
    transition: color 300ms ease;

    .main-navbar--shadow & {
        color: white;
    }

    .body--freezed & {
        @media screen and (max-width: 767px) {
            display: none;
        }
    }
}
.main-navbar__link--btn {
    @media screen and (min-width: 768px) {
        padding: calc(15rem / 16) calc(24rem / 16);
        background-color: var(--color-secondary);
        color: white;
        border-radius: calc(53rem / 16);
        transition: background-color 300ms ease;
    }
}
.main-navbar__link-text {
    text-transform: uppercase;
    letter-spacing: calc(2rem/16);
    font-size: calc(14rem/16);
    font-weight: var(--font-weight-extra-bold);
    @media screen and (max-width: 1599px) {
        display: none;
    }
}
.main-navbar__link-icon {
    font-size: calc(18rem/16);
    @media screen and (max-width: 767px) {
        font-size: calc(16rem/16);
    }
}

.main-navbar--checkout {
    padding: calc(15rem/16) calc(15rem/16);
    @media screen and (min-width: 768px) {
        padding: calc(15rem/16) calc(60rem/16);
    }
}
.main-navbar--demi .affix-navbar__placeholder {
    @media screen and (max-width: 767px) {
        min-height: calc(60rem/16) !important;
    }
}

.main-navbar__account-menu {
    padding: 1.5rem;
    text-align: center;
    background-color: rgba(255, 255, 255, .8);
    border: none;
    box-shadow: 0 0 2rem rgba(0, 0, 0, .5);
    margin-top: 2rem;
    @media screen and (min-width: 768px) {
        padding: 2rem;
    }
}

.main-navbar__account-menu-btn {
    margin-bottom: 2rem;
}

.main-navbar__account-menu-title {
    margin-bottom: .5rem;
}

.main-navbar__account-menu-link {
    font-size: .75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: calc(4rem/16);
    & .icon {
        font-size: .5rem;
    }
}