.btn-no-styling {
    border: none;
    background: none;
    text-align: left;
    padding: 0;
    color: inherit;
    font-family: inherit;
    font-weight: inherit;
    cursor: pointer;
}

.btn {
    letter-spacing: calc(2rem / 16);
    text-transform: uppercase;
    box-shadow: calc(20rem / 16) calc(20rem / 16) calc(40rem / 16) rgba(black, .12);
    display: inline-flex;
    column-gap: calc(12rem / 16);
    align-items: center;
    justify-content: center;
}

.btn-sm {
    line-height: 1;
    letter-spacing: calc(2rem / 16);
    font-family: var(--font-default-medium);
    font-weight: var(--font-weight-medium);
}

.btn-booking {
    background-color: var(--color-secondary);
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn__icon {
    font-size: calc(19rem / 16);
}

.btn__icon--small {
    font-size: calc(10rem / 16);
}

.btn-white {
    background-color: white;

    &:focus, &:active {
        color: black;
    }
}



.btn-sm--mobile {
    @media screen and (max-width: 767px) {
        padding: 0.75rem;
        font-size: .75rem;
        line-height: 1;
    }
}

.btn-back {
    font-family: var(--font-default-extra-bold);
    font-weight: var(--font-weight-extra-bold);
    color: var(--color-middle-grey);
    font-size: calc(14rem / 16);
    letter-spacing: calc(2rem / 16);
    text-transform: uppercase;
}

.btn-back--margin-bottom {
    margin-bottom: calc(24rem / 16);
}

.btn-link {
    color: var(--color-text-default);
    text-transform: uppercase;
    letter-spacing: calc(2rem / 16);
    font-size: calc(14rem / 16);
    font-family: var(--font-default-bold);
    font-weight: var(--font-weight-bold);
    text-decoration: none;

    &:hover {
        color: var(--color-secondary);
    }
}

.btn-no-shadow {
    box-shadow: none;
}

.btn-alpbachtal {
    background-color: var(--color-alpbachtal);
    color: var(--color-text-default);
}

.btn-wildschoenau {
    background-color: var(--color-wildschoenau);
    color: white;
}

.btn-skijuwel {
    background-color: #008BD0;
    color: white;
}