body {
    color: var(--color-text-default);
    font-family: var(--font-default);
    font-weight: var(--font-default-weight);
    font-size: var(--font-size-default);
}
@media (min-width: 768px) {
    body,
    .popover {
        font-size: var(--font-size-default-desktop);
    }
}

b,
strong,
.strong {
    font-family: var(--font-default-bold);
    font-weight: var(--font-default-bold-weight);
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    margin-top: 0;
    display: block;
    font-family: var(--font-default-medium);
    font-weight: var(--font-default-medium-weight);
}

h1, .h1 {
    font-size: 24px;
    line-height: calc(28 / 24);
}
h2, .h2 {
    font-size: 20px;
    line-height: calc(22 / 20);
    color: var(--color-text-default) !important;
}
h3, .h3 {
    font-size: 17px;
}
h4, .h4 {
    font-size: 15px;
    line-height: calc(17 / 15);
}
h5, .h5 {
    font-size: 13px;
}
h6, .h6 {
    font-size: 1em; /*todo*/
}

@media (min-width: 768px) {
    h1, .h1 {
        font-size: 36px;
        line-height: calc(36 / 40);
    }
    h2, .h2 {
        font-size: 24px;
        line-height: calc(28 / 24);
    }
    h3, .h3 {
        font-size: 20px;
    }
    h4, .h4 {
        font-size: 18px;
        line-height: calc(24 / 18);
    }
    h5, .h5 {
        font-size: 16px;
        line-height: calc(20 / 16);
    }
    h6, .h6 {
        font-size: 1em; /*todo*/
    }

}


small, .small {
    font-size: calc(11 / 13 * 100%);
}

.main-content--no-hero {
    padding-top: calc(40rem/16);
    @media screen and (min-width:768px) {
        padding-top: calc(110rem/16);
    }
}
