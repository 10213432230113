@media (hover: none) {
    .mega-nav__item.has-children > .simple-nav__item-content--link {
        display: none;
    }
    .mega-nav__item.has-no-children > .simple-nav__item-content--toggle {
        display: none;
    }
}

.mega-nav__item-content--toggle {
    display: none;
}
.mega-nav__teaser-list {
    margin-top: calc(20rem/16);
    margin-bottom: calc(20rem/16);
    @media screen and (max-width: 767px) {
        margin-top: calc(0rem/16);
        justify-content:center;
    }
}
.mega-nav__container {
    padding-top: calc(140/1080 * 100vh);
    height: 100%;
    @media screen and (min-width: 1200px) {
        padding-top: calc(244/1080 * 100vh);
        padding-left: calc(40rem/16);
    }
    @media screen and (min-width: 1400px) {
        padding-left: calc(60rem/16);
    }
    @media screen and (min-width: 1600px) {
        padding-left: calc(120rem/16);
    }
}
.mega-nav__item-subtext {
    font-size: calc(16rem/16);
    line-height: calc(22/16);
    letter-spacing: calc(8rem/16);
    color: var(--color-primary);
    text-transform: uppercase;
    margin-top: calc(6rem/16);
    width: 100%;
    text-align: left;
}
.mega-nav__item--opacity-toggle {
    opacity: .2;
}

.mega-nav__image-teaser {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    row-gap: calc(32rem/16);
    @media screen and (max-width: 1199px) {
        display: none;
    }
    @media screen and (min-width: 1200px) {
        top: calc(60rem/16);
        right: calc(40rem/16);
        & .image-teaser {
            width: calc(402/1920*100vw);
        }
    }
    @media screen and (min-width: 1700px) {
        top: calc(200rem/16);
        right: calc(120rem/16);
    }
}