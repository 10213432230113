.selection-bar {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    color: #ffffff;
    background: var(--color-success);
    padding: .5rem 1rem;
    line-height: 1.4;
    z-index: 100;
    display: block;
    transform: translateY(100%) translateZ(0);
    transition: transform 90ms ease;
    @media (max-width: 767px) {
        width: 100%;
        border-top: 1px solid var(--color-success--dark );
    }
}
.affiliate .selection-bar {
    display: none;
}
.selection-bar__cart-icon {
    font-size: 16px;
}
.selection-bar__arrow-icon {
    font-size: 10px;
    vertical-align: 0.3em;
    margin-left: 2px;
}

.selection-bar.is-shown {
    transform: translateZ(0);
}
.body--has-selection-bar {
    padding-bottom: 60px;
}