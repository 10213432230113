.paging {

}

.paging__btn {
    width: 30px;
    height: 30px;
    display:inline-flex;
    justify-content: center;
    align-items: centeR;
    padding-left: 3px;
    padding-right: 3px;
    text-align: center;
}