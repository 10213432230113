.figure {
    display: block;
    position: relative;
}
.figure__overlay {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    max-width: 100%;
    padding: 10px;
    background: #000;
    background: rgba(0,0,0,.6);
    color: #ffffff;
}
.figure__right-overlay {
    position: absolute;
    right: .25rem;
    bottom: .5rem;
    background: #000;
    background-color: rgba(0,0,0,0.5);
    color: #ffffff;
    border-radius: 4px;
    padding: .75rem;
    min-width: 40px;
    text-align: center;
}
/*todo name*/
.figure__item {
    position: absolute;
    top: 0;
    left: 0;
}
.figure__item--right {
    left: auto;
    right: 0;
}
.figure__item--bottom {
    top: auto;
    bottom: 0;
}