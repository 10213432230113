.footer-top.container{
    padding-bottom: calc(48rem/16);
    @media screen and (min-width: 768px){
        padding-bottom: calc(100rem/16);
    }

    @media screen and (min-width: 992px){
        padding-bottom: calc(144rem/16);
    }
}

.footer-top__header{
    margin-bottom: calc(48rem/16);
    color: var(--color-primary);

    @media screen and (min-width: 768px){
        margin-bottom: calc(100rem/16);
    }

    @media screen and (min-width: 922px){
        margin-bottom: calc(80rem/16);
    }
}

.footer-top__subtitle{
    font-size: calc(14rem/16);
    line-height: calc(19rem/16);
    letter-spacing: calc(8rem/16);
    margin-bottom: calc(6rem/16);
    text-transform: uppercase;
    color: var(--color-primary);

    @media screen and (min-width: 768px){
        font-size: calc(16rem/16);
        line-height: calc(22rem/16);
        margin-bottom: calc(12rem/16);
    }
}

.footer-top__text-muted{
    color: var(--color-text-muted);
}

.footer-top__header-title{
    font-size: calc(42rem/16);
    line-height: calc(42rem/16);
    font-family: var(--font-default-extra-bold);

    @media screen and (min-width: 768px){
        font-size: calc(108rem/16);
        line-height: calc(108rem/16);
    }
}

.footer-top__header-icon{
    margin-top: calc(12rem/16);
    margin-bottom: calc(12rem/16);
    @media screen and (min-width: 768px){
        margin-top: calc(12rem/16);
        margin-bottom: calc(20rem/16);
    }
}

.footer-top__title{
    font-size: calc(16rem/16);
    line-height: calc(16rem/16);
    font-family: var(--font-default-extra-bold);
    margin-bottom: calc(24rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(24rem/16);
        line-height: calc(24rem/16);
    }
}

.footer-top__title--big{
    font-size: calc(24rem/16);
    line-height: calc(24rem/16);
    font-family: var(--font-default-extra-bold);
    margin-bottom: calc(24rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(36rem/16);
        line-height: calc(36rem/16);
        margin-bottom: calc(48rem/16);
    }
}

.footer-top__newsletter-btn{
    width: fit-content;
}

.footer-top__service-chat {
    display: flex;
    align-items: center;
    column-gap: calc(12rem/16);
}

.footer-top__service-chat-text{
    font-family: var(--font-default);
}

.footer-top__social-media{
    display: flex;
    column-gap: calc(30rem/16);
    @media screen and (min-width: 768px){
        margin-top: calc(12rem/16);
        width: 100%;
    }
    @media screen and (min-width: 992px){
        margin-top: calc(36rem/16);
    }
}

.footer-top__social-media .icon{
    font-size: calc(30rem/16);
    @media screen and (max-width: 767px) {
        font-size: calc(24rem/16);
    }
}

.footer-top__contact{
    margin-top: calc(24rem/16);
    font-family: var(--font-default-extra-bold);
    display: flex;
    flex-direction: column;
    row-gap: calc(12rem/16);
    @media screen and (min-width: 768px){
        margin-top: calc(26rem/16);
    }
    @media screen and (min-width: 1400px) {
        margin-top: calc(48rem/16);
        row-gap: calc(24rem/16);
    }
}

.footer-top__contact-item {
    cursor: pointer;
    @media screen and (min-width: 768px) {
        display: flex;
        align-items: center;
        column-gap: calc(12rem/16);
    }
}

.footer-top__links{
    display: flex;
    @media screen and (max-width: 1199px) {
        column-gap: calc(8rem/16);
    }
    @media screen and (min-width: 1200px){
        flex-direction: column;
        justify-content: center;
    }
   @media screen and (max-width: 767px) {
       flex-wrap:wrap;
       row-gap: calc(16rem/16)
   }
}

.footer-top__links .btn{
    @media screen and (max-width: 767px) {
        margin-bottom: 0px;
        white-space: nowrap;
        row-gap: 16px
    }
    @media screen and (min-width: 992px) and (max-width: 1199px) {
        font-size: clamp(10rem/16, 0.8vw, 16rem/16);
        padding-left: calc(20rem/16);
        padding-right: calc(20rem/16);

        row-gap: calc(12rem/16);
    }
  	@media screen and (min-width: 1200px) and (max-width: 1399px) {
        font-size: clamp(10rem/16, 0.8vw, 16rem/16);
        padding-left: calc(16rem/16);
        padding-right: calc(16rem/16);
  	}
    @media screen and (min-width: 1400px){
        font-size: clamp(10rem/16, 0.8vw, 16rem/16);
        padding-left: clamp(8rem/16,1.7vw,36rem/16);
        padding-right: clamp(8rem/16,7vw,36rem/16);
    }
}

.footer-top__links .icon{
    @media screen and (max-width: 767px) {
        font-size: calc(10rem/16);
    }
    font-size: calc(12rem/16);
    transform: rotate(-45deg);
}

.footer-top__image-divider {
    max-height: calc(24rem/16);
    object-fit: contain;
}


.footer-bottom{
    background-color: #fff;
    @media screen and (max-width: 767px) {
        padding: calc(20rem/16) 0;
    }
    @media screen and (min-width: 768px) {
        height: calc(132rem/16);
    }
}

.footer-bottom__chat{
    position: fixed;
    bottom: 0;
    right: 0;
    background-color: #fcf8f2;
    margin-right: 20px;
    z-index: 100003;
    .icon::before{
         font-size: 20px;

    }


}

.footer-bottom__chat--animation{

    animation: 0.3s ease-out 0s 1 slideInFromDown;

}
.footer-bottom__chat--disappear{
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 0.1s, opacity 0.1s linear;
}

@keyframes slideInFromDown {
     0% {
         transform: translateY(100%);
     }
     100% {
         transform: translateY(0);
     }
 }
.btn.footer-bottom__chat{
    box-shadow: 0 0 0.688em 0 rgba(0,0,0,0.5);
}

#warning-static-release{
    display: none;
}

.sf-minitoolbar{
    display:none;
}
.footer-bottom__wrapper{
    @media screen and (min-width: 768px){
        display: flex;
        justify-content: space-between;
        height: 100%;
        align-items: center;
    }
}

.footer-bottom__list{
    display: flex;
    flex-wrap: wrap;
    @media screen and (max-width: 767px) {
        margin-left: calc(-8rem/16);
    }
}

.footer-bottom__list li {
    transition: .25s;
    font-size: calc(12rem/16);

    @media screen and (min-width: 768px){
        font-size: clamp(10px, 0.8vw, 14px);
    }

    &:hover{
        color: var(--color-primary);
    }
}
.footer-bottom__list li + li {
    @media screen and (min-width: 768px){
        padding-left: calc(48rem/16);
    }
}
.footer-bottom__list li {
    @media screen and (max-width: 767px){
        padding: .5rem;
    }
}

.footer-bottom__elements{
    width: calc(111rem/16);
    height: calc(12rem/16);

    @media screen and (min-width: 768px){
        width: calc(148rem/16);
        height: calc(16rem/16);
    }
}

.footer-bottom__tyrol{
    margin-right: calc(12rem/16);
    @media screen and (min-width: 768px) {
        margin-right: calc(36rem / 16);
    }
}

.footer-bottom__copyright{
    font-size: clamp(12rem/16, 2vw, 14rem/16);
    color: var(--color-text-muted);
}