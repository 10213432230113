.region-dropdown {
    @media screen and (max-width: 767px) {
        margin-top: calc(12rem/16);
    }
}
.region-dropdown__btn {
    background-color: transparent;
    text-align: left;
    font-size: calc(12rem/16);
    box-shadow: none;
    border: none;
    display: flex;
    align-items: center;
    padding-left: 0;
    @media screen and (min-width: 768px) {
        font-size: calc(14rem/16);
    }
    .main-navbar--shadow & {
        color: white;
    }
}
.region-dropdown__btn::after {
    content: var(--icon-chevron);
    font-family: iconfont;
    border: none;
    font-size: calc(5rem/16);
    margin-left: calc(6rem/16);
    @media screen and (max-width: 767px) {
        font-size: calc(4rem/16);
    }
}
.region-dropdown__icon {
    font-size: calc(16rem/16);
    margin-right: calc(6rem/16);
}