@media screen and (min-width: 768px) and (max-width: 991px) {
    body {
        width: 982px;
    }
    .container {
        width: 100%;
    }
}

a,
a:hover,
a:focus {
    color: inherit;
    text-decoration: none;
}

a[data-toggle="collapse"]:hover,
a[data-toggle="collapse"]:focus {
    text-decoration: none;
}

address, dl, ol, ul, label, dd, figure {
    margin-bottom: 0;
}

dt {
    font-weight: inherit;
}

textarea {
    resize: none;
}

abbr, abbr[title] {
    text-decoration: none;
}
mark {
    background: none;
    font-family: var(--font-default-bold);
    padding: 0;
}

/*tood move*/
.btn-no-styling {
    border: none;
    background: none;
    text-align: left;
    padding: 0;
    color: inherit;
    font-family: inherit;
    font-weight: inherit;
}
.btn-no-styling.d-block {
    width: 100%;
}