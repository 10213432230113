 @font-face {
     font-family: "demiIcons";
     src: url('/cache-buster-1741682608952/static/demi/debug/icons/font/demiIcons.eot?#iefix') format('eot'),
         url('/cache-buster-1741682608952/static/demi/debug/icons/font/demiIcons.woff') format('woff'),
         url('/cache-buster-1741682608952/static/demi/debug/icons/font/demiIcons.ttf') format('ttf');
     font-weight: normal;
     font-style: normal;
 }

.demi-icon {
    display: inline-block;
    font-family: "demiIcons";
    speak: none;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
}

.demi-icon-lg {
    font-size: 1.3333333333333333em;
    line-height: 0.75em;
    vertical-align: -15%;
}
.demi-icon-2x { font-size: 2em; }
.demi-icon-3x { font-size: 3em; }
.demi-icon-4x { font-size: 4em; }
.demi-icon-5x { font-size: 5em; }
.demi-icon-fw {
    width: 1.2857142857142858em;
    text-align: center;
}

.demi-icon-ul {
    padding-left: 0;
    margin-left: 2.14285714em;
    list-style-type: none;
}
.demi-icon-ul > li {
    position: relative;
}
.demi-icon-li {
    position: absolute;
    left: -2.14285714em;
    width: 2.14285714em;
    top: 0.14285714em;
    text-align: center;
}
.demi-icon-li.demi-icon-lg {
    left: -1.85714286em;
}

.demi-icon-rotate-90 {
    transform: rotate(90deg);
}
.demi-icon-rotate-180 {
    transform: rotate(180deg);
}
.demi-icon-rotate-270 {
    transform: rotate(270deg);
}
.demi-icon-flip-horizontal {
    transform: scale(-1, 1);
}
.demi-icon-flip-vertical {
    transform: scale(1, -1);
}
:root .demi-icon-rotate-90,
:root .demi-icon-rotate-180,
:root .demi-icon-rotate-270,
:root .demi-icon-flip-horizontal,
:root .demi-icon-flip-vertical {
    filter: none;
}


 :root {
 --demi-icon-add-circle: "\EA01";
     --demi-icon-add: "\EA02";
     --demi-icon-arrow-from-to: "\EA03";
     --demi-icon-arrow-left: "\EA04";
     --demi-icon-arrow-right: "\EA05";
     --demi-icon-assignment: "\EA06";
     --demi-icon-bars: "\EA07";
     --demi-icon-bed: "\EA08";
     --demi-icon-bell: "\EA09";
     --demi-icon-buildings: "\EA0A";
     --demi-icon-business: "\EA0B";
     --demi-icon-calendar-insert: "\EA0C";
     --demi-icon-call: "\EA0D";
     --demi-icon-camping: "\EA0E";
     --demi-icon-cancel: "\EA0F";
     --demi-icon-cart: "\EA10";
     --demi-icon-check-circle: "\EA11";
     --demi-icon-check: "\EA12";
     --demi-icon-chevron-down: "\EA13";
     --demi-icon-chevron-left: "\EA14";
     --demi-icon-chevron-right: "\EA15";
     --demi-icon-chevron-up: "\EA16";
     --demi-icon-child-friendly: "\EA17";
     --demi-icon-classification-blume: "\EA18";
     --demi-icon-classification-edelweiss: "\EA19";
     --demi-icon-classification-star: "\EA1A";
     --demi-icon-close-circle: "\EA1B";
     --demi-icon-close: "\EA1C";
     --demi-icon-closed: "\EA1D";
     --demi-icon-delete: "\EA1E";
     --demi-icon-download: "\EA1F";
     --demi-icon-draw-on-map: "\EA20";
     --demi-icon-edit: "\EA21";
     --demi-icon-education: "\EA22";
     --demi-icon-elements: "\EA23";
     --demi-icon-email: "\EA24";
     --demi-icon-euro: "\EA25";
     --demi-icon-facebook: "\EA26";
     --demi-icon-filter: "\EA27";
     --demi-icon-fitness: "\EA28";
     --demi-icon-flickr: "\EA29";
     --demi-icon-flower-alpbachtal: "\EA2A";
     --demi-icon-foursquare: "\EA2B";
     --demi-icon-fullscreen-exit: "\EA2C";
     --demi-icon-fullscreen: "\EA2D";
     --demi-icon-globe: "\EA2E";
     --demi-icon-group: "\EA2F";
     --demi-icon-image: "\EA30";
     --demi-icon-info-circle-outline: "\EA31";
     --demi-icon-info-circle: "\EA32";
     --demi-icon-info-outline: "\EA33";
     --demi-icon-info: "\EA34";
     --demi-icon-instagram: "\EA35";
     --demi-icon-landscape: "\EA36";
     --demi-icon-link: "\EA37";
     --demi-icon-live-cam: "\EA38";
     --demi-icon-location-on: "\EA39";
     --demi-icon-map: "\EA3A";
     --demi-icon-marker: "\EA3B";
     --demi-icon-minus: "\EA3C";
     --demi-icon-mobile-phone-2: "\EA3D";
     --demi-icon-more-horiz: "\EA3E";
     --demi-icon-navigation: "\EA3F";
     --demi-icon-near-me: "\EA40";
     --demi-icon-nights: "\EA41";
     --demi-icon-pano: "\EA42";
     --demi-icon-payment: "\EA43";
     --demi-icon-person: "\EA44";
     --demi-icon-pets: "\EA45";
     --demi-icon-pinterest: "\EA46";
     --demi-icon-play-circle-fill: "\EA47";
     --demi-icon-play-circle: "\EA48";
     --demi-icon-plus: "\EA49";
     --demi-icon-pool: "\EA4A";
     --demi-icon-remove-circle: "\EA4B";
     --demi-icon-restaurant: "\EA4C";
     --demi-icon-search: "\EA4D";
     --demi-icon-share: "\EA4E";
     --demi-icon-sort-asc: "\EA4F";
     --demi-icon-sort-desc: "\EA50";
     --demi-icon-sort: "\EA51";
     --demi-icon-star: "\EA52";
     --demi-icon-target: "\EA53";
     --demi-icon-tel: "\EA54";
     --demi-icon-thumb-up: "\EA55";
     --demi-icon-ticket-cta: "\EA56";
     --demi-icon-tractor: "\EA57";
     --demi-icon-tumbler: "\EA58";
     --demi-icon-twitter: "\EA59";
     --demi-icon-vimeo: "\EA5A";
     --demi-icon-visibility-off: "\EA5B";
     --demi-icon-visibility: "\EA5C";
     --demi-icon-warning: "\EA5D";
     --demi-icon-wellness: "\EA5E";
     --demi-icon-youtube: "\EA5F";
     
 --demi-icon-add-circle-font-family: "demiIcons";
     --demi-icon-add-font-family: "demiIcons";
     --demi-icon-arrow-from-to-font-family: "demiIcons";
     --demi-icon-arrow-left-font-family: "demiIcons";
     --demi-icon-arrow-right-font-family: "demiIcons";
     --demi-icon-assignment-font-family: "demiIcons";
     --demi-icon-bars-font-family: "demiIcons";
     --demi-icon-bed-font-family: "demiIcons";
     --demi-icon-bell-font-family: "demiIcons";
     --demi-icon-buildings-font-family: "demiIcons";
     --demi-icon-business-font-family: "demiIcons";
     --demi-icon-calendar-insert-font-family: "demiIcons";
     --demi-icon-call-font-family: "demiIcons";
     --demi-icon-camping-font-family: "demiIcons";
     --demi-icon-cancel-font-family: "demiIcons";
     --demi-icon-cart-font-family: "demiIcons";
     --demi-icon-check-circle-font-family: "demiIcons";
     --demi-icon-check-font-family: "demiIcons";
     --demi-icon-chevron-down-font-family: "demiIcons";
     --demi-icon-chevron-left-font-family: "demiIcons";
     --demi-icon-chevron-right-font-family: "demiIcons";
     --demi-icon-chevron-up-font-family: "demiIcons";
     --demi-icon-child-friendly-font-family: "demiIcons";
     --demi-icon-classification-blume-font-family: "demiIcons";
     --demi-icon-classification-edelweiss-font-family: "demiIcons";
     --demi-icon-classification-star-font-family: "demiIcons";
     --demi-icon-close-circle-font-family: "demiIcons";
     --demi-icon-close-font-family: "demiIcons";
     --demi-icon-closed-font-family: "demiIcons";
     --demi-icon-delete-font-family: "demiIcons";
     --demi-icon-download-font-family: "demiIcons";
     --demi-icon-draw-on-map-font-family: "demiIcons";
     --demi-icon-edit-font-family: "demiIcons";
     --demi-icon-education-font-family: "demiIcons";
     --demi-icon-elements-font-family: "demiIcons";
     --demi-icon-email-font-family: "demiIcons";
     --demi-icon-euro-font-family: "demiIcons";
     --demi-icon-facebook-font-family: "demiIcons";
     --demi-icon-filter-font-family: "demiIcons";
     --demi-icon-fitness-font-family: "demiIcons";
     --demi-icon-flickr-font-family: "demiIcons";
     --demi-icon-flower-alpbachtal-font-family: "demiIcons";
     --demi-icon-foursquare-font-family: "demiIcons";
     --demi-icon-fullscreen-exit-font-family: "demiIcons";
     --demi-icon-fullscreen-font-family: "demiIcons";
     --demi-icon-globe-font-family: "demiIcons";
     --demi-icon-group-font-family: "demiIcons";
     --demi-icon-image-font-family: "demiIcons";
     --demi-icon-info-circle-outline-font-family: "demiIcons";
     --demi-icon-info-circle-font-family: "demiIcons";
     --demi-icon-info-outline-font-family: "demiIcons";
     --demi-icon-info-font-family: "demiIcons";
     --demi-icon-instagram-font-family: "demiIcons";
     --demi-icon-landscape-font-family: "demiIcons";
     --demi-icon-link-font-family: "demiIcons";
     --demi-icon-live-cam-font-family: "demiIcons";
     --demi-icon-location-on-font-family: "demiIcons";
     --demi-icon-map-font-family: "demiIcons";
     --demi-icon-marker-font-family: "demiIcons";
     --demi-icon-minus-font-family: "demiIcons";
     --demi-icon-mobile-phone-2-font-family: "demiIcons";
     --demi-icon-more-horiz-font-family: "demiIcons";
     --demi-icon-navigation-font-family: "demiIcons";
     --demi-icon-near-me-font-family: "demiIcons";
     --demi-icon-nights-font-family: "demiIcons";
     --demi-icon-pano-font-family: "demiIcons";
     --demi-icon-payment-font-family: "demiIcons";
     --demi-icon-person-font-family: "demiIcons";
     --demi-icon-pets-font-family: "demiIcons";
     --demi-icon-pinterest-font-family: "demiIcons";
     --demi-icon-play-circle-fill-font-family: "demiIcons";
     --demi-icon-play-circle-font-family: "demiIcons";
     --demi-icon-plus-font-family: "demiIcons";
     --demi-icon-pool-font-family: "demiIcons";
     --demi-icon-remove-circle-font-family: "demiIcons";
     --demi-icon-restaurant-font-family: "demiIcons";
     --demi-icon-search-font-family: "demiIcons";
     --demi-icon-share-font-family: "demiIcons";
     --demi-icon-sort-asc-font-family: "demiIcons";
     --demi-icon-sort-desc-font-family: "demiIcons";
     --demi-icon-sort-font-family: "demiIcons";
     --demi-icon-star-font-family: "demiIcons";
     --demi-icon-target-font-family: "demiIcons";
     --demi-icon-tel-font-family: "demiIcons";
     --demi-icon-thumb-up-font-family: "demiIcons";
     --demi-icon-ticket-cta-font-family: "demiIcons";
     --demi-icon-tractor-font-family: "demiIcons";
     --demi-icon-tumbler-font-family: "demiIcons";
     --demi-icon-twitter-font-family: "demiIcons";
     --demi-icon-vimeo-font-family: "demiIcons";
     --demi-icon-visibility-off-font-family: "demiIcons";
     --demi-icon-visibility-font-family: "demiIcons";
     --demi-icon-warning-font-family: "demiIcons";
     --demi-icon-wellness-font-family: "demiIcons";
     --demi-icon-youtube-font-family: "demiIcons";
     
 }

.demi-icon-add-circle:before {
    content: var(--demi-icon-add-circle);
     font-family: var(--demi-icon-add-circle-font-family);
}
    .demi-icon-add:before {
    content: var(--demi-icon-add);
     font-family: var(--demi-icon-add-font-family);
}
    .demi-icon-arrow-from-to:before {
    content: var(--demi-icon-arrow-from-to);
     font-family: var(--demi-icon-arrow-from-to-font-family);
}
    .demi-icon-arrow-left:before {
    content: var(--demi-icon-arrow-left);
     font-family: var(--demi-icon-arrow-left-font-family);
}
    .demi-icon-arrow-right:before {
    content: var(--demi-icon-arrow-right);
     font-family: var(--demi-icon-arrow-right-font-family);
}
    .demi-icon-assignment:before {
    content: var(--demi-icon-assignment);
     font-family: var(--demi-icon-assignment-font-family);
}
    .demi-icon-bars:before {
    content: var(--demi-icon-bars);
     font-family: var(--demi-icon-bars-font-family);
}
    .demi-icon-bed:before {
    content: var(--demi-icon-bed);
     font-family: var(--demi-icon-bed-font-family);
}
    .demi-icon-bell:before {
    content: var(--demi-icon-bell);
     font-family: var(--demi-icon-bell-font-family);
}
    .demi-icon-buildings:before {
    content: var(--demi-icon-buildings);
     font-family: var(--demi-icon-buildings-font-family);
}
    .demi-icon-business:before {
    content: var(--demi-icon-business);
     font-family: var(--demi-icon-business-font-family);
}
    .demi-icon-calendar-insert:before {
    content: var(--demi-icon-calendar-insert);
     font-family: var(--demi-icon-calendar-insert-font-family);
}
    .demi-icon-call:before {
    content: var(--demi-icon-call);
     font-family: var(--demi-icon-call-font-family);
}
    .demi-icon-camping:before {
    content: var(--demi-icon-camping);
     font-family: var(--demi-icon-camping-font-family);
}
    .demi-icon-cancel:before {
    content: var(--demi-icon-cancel);
     font-family: var(--demi-icon-cancel-font-family);
}
    .demi-icon-cart:before {
    content: var(--demi-icon-cart);
     font-family: var(--demi-icon-cart-font-family);
}
    .demi-icon-check-circle:before {
    content: var(--demi-icon-check-circle);
     font-family: var(--demi-icon-check-circle-font-family);
}
    .demi-icon-check:before {
    content: var(--demi-icon-check);
     font-family: var(--demi-icon-check-font-family);
}
    .demi-icon-chevron-down:before {
    content: var(--demi-icon-chevron-down);
     font-family: var(--demi-icon-chevron-down-font-family);
}
    .demi-icon-chevron-left:before {
    content: var(--demi-icon-chevron-left);
     font-family: var(--demi-icon-chevron-left-font-family);
}
    .demi-icon-chevron-right:before {
    content: var(--demi-icon-chevron-right);
     font-family: var(--demi-icon-chevron-right-font-family);
}
    .demi-icon-chevron-up:before {
    content: var(--demi-icon-chevron-up);
     font-family: var(--demi-icon-chevron-up-font-family);
}
    .demi-icon-child-friendly:before {
    content: var(--demi-icon-child-friendly);
     font-family: var(--demi-icon-child-friendly-font-family);
}
    .demi-icon-classification-blume:before {
    content: var(--demi-icon-classification-blume);
     font-family: var(--demi-icon-classification-blume-font-family);
}
    .demi-icon-classification-edelweiss:before {
    content: var(--demi-icon-classification-edelweiss);
     font-family: var(--demi-icon-classification-edelweiss-font-family);
}
    .demi-icon-classification-star:before {
    content: var(--demi-icon-classification-star);
     font-family: var(--demi-icon-classification-star-font-family);
}
    .demi-icon-close-circle:before {
    content: var(--demi-icon-close-circle);
     font-family: var(--demi-icon-close-circle-font-family);
}
    .demi-icon-close:before {
    content: var(--demi-icon-close);
     font-family: var(--demi-icon-close-font-family);
}
    .demi-icon-closed:before {
    content: var(--demi-icon-closed);
     font-family: var(--demi-icon-closed-font-family);
}
    .demi-icon-delete:before {
    content: var(--demi-icon-delete);
     font-family: var(--demi-icon-delete-font-family);
}
    .demi-icon-download:before {
    content: var(--demi-icon-download);
     font-family: var(--demi-icon-download-font-family);
}
    .demi-icon-draw-on-map:before {
    content: var(--demi-icon-draw-on-map);
     font-family: var(--demi-icon-draw-on-map-font-family);
}
    .demi-icon-edit:before {
    content: var(--demi-icon-edit);
     font-family: var(--demi-icon-edit-font-family);
}
    .demi-icon-education:before {
    content: var(--demi-icon-education);
     font-family: var(--demi-icon-education-font-family);
}
    .demi-icon-elements:before {
    content: var(--demi-icon-elements);
     font-family: var(--demi-icon-elements-font-family);
}
    .demi-icon-email:before {
    content: var(--demi-icon-email);
     font-family: var(--demi-icon-email-font-family);
}
    .demi-icon-euro:before {
    content: var(--demi-icon-euro);
     font-family: var(--demi-icon-euro-font-family);
}
    .demi-icon-facebook:before {
    content: var(--demi-icon-facebook);
     font-family: var(--demi-icon-facebook-font-family);
}
    .demi-icon-filter:before {
    content: var(--demi-icon-filter);
     font-family: var(--demi-icon-filter-font-family);
}
    .demi-icon-fitness:before {
    content: var(--demi-icon-fitness);
     font-family: var(--demi-icon-fitness-font-family);
}
    .demi-icon-flickr:before {
    content: var(--demi-icon-flickr);
     font-family: var(--demi-icon-flickr-font-family);
}
    .demi-icon-flower-alpbachtal:before {
    content: var(--demi-icon-flower-alpbachtal);
     font-family: var(--demi-icon-flower-alpbachtal-font-family);
}
    .demi-icon-foursquare:before {
    content: var(--demi-icon-foursquare);
     font-family: var(--demi-icon-foursquare-font-family);
}
    .demi-icon-fullscreen-exit:before {
    content: var(--demi-icon-fullscreen-exit);
     font-family: var(--demi-icon-fullscreen-exit-font-family);
}
    .demi-icon-fullscreen:before {
    content: var(--demi-icon-fullscreen);
     font-family: var(--demi-icon-fullscreen-font-family);
}
    .demi-icon-globe:before {
    content: var(--demi-icon-globe);
     font-family: var(--demi-icon-globe-font-family);
}
    .demi-icon-group:before {
    content: var(--demi-icon-group);
     font-family: var(--demi-icon-group-font-family);
}
    .demi-icon-image:before {
    content: var(--demi-icon-image);
     font-family: var(--demi-icon-image-font-family);
}
    .demi-icon-info-circle-outline:before {
    content: var(--demi-icon-info-circle-outline);
     font-family: var(--demi-icon-info-circle-outline-font-family);
}
    .demi-icon-info-circle:before {
    content: var(--demi-icon-info-circle);
     font-family: var(--demi-icon-info-circle-font-family);
}
    .demi-icon-info-outline:before {
    content: var(--demi-icon-info-outline);
     font-family: var(--demi-icon-info-outline-font-family);
}
    .demi-icon-info:before {
    content: var(--demi-icon-info);
     font-family: var(--demi-icon-info-font-family);
}
    .demi-icon-instagram:before {
    content: var(--demi-icon-instagram);
     font-family: var(--demi-icon-instagram-font-family);
}
    .demi-icon-landscape:before {
    content: var(--demi-icon-landscape);
     font-family: var(--demi-icon-landscape-font-family);
}
    .demi-icon-link:before {
    content: var(--demi-icon-link);
     font-family: var(--demi-icon-link-font-family);
}
    .demi-icon-live-cam:before {
    content: var(--demi-icon-live-cam);
     font-family: var(--demi-icon-live-cam-font-family);
}
    .demi-icon-location-on:before {
    content: var(--demi-icon-location-on);
     font-family: var(--demi-icon-location-on-font-family);
}
    .demi-icon-map:before {
    content: var(--demi-icon-map);
     font-family: var(--demi-icon-map-font-family);
}
    .demi-icon-marker:before {
    content: var(--demi-icon-marker);
     font-family: var(--demi-icon-marker-font-family);
}
    .demi-icon-minus:before {
    content: var(--demi-icon-minus);
     font-family: var(--demi-icon-minus-font-family);
}
    .demi-icon-mobile-phone-2:before {
    content: var(--demi-icon-mobile-phone-2);
     font-family: var(--demi-icon-mobile-phone-2-font-family);
}
    .demi-icon-more-horiz:before {
    content: var(--demi-icon-more-horiz);
     font-family: var(--demi-icon-more-horiz-font-family);
}
    .demi-icon-navigation:before {
    content: var(--demi-icon-navigation);
     font-family: var(--demi-icon-navigation-font-family);
}
    .demi-icon-near-me:before {
    content: var(--demi-icon-near-me);
     font-family: var(--demi-icon-near-me-font-family);
}
    .demi-icon-nights:before {
    content: var(--demi-icon-nights);
     font-family: var(--demi-icon-nights-font-family);
}
    .demi-icon-pano:before {
    content: var(--demi-icon-pano);
     font-family: var(--demi-icon-pano-font-family);
}
    .demi-icon-payment:before {
    content: var(--demi-icon-payment);
     font-family: var(--demi-icon-payment-font-family);
}
    .demi-icon-person:before {
    content: var(--demi-icon-person);
     font-family: var(--demi-icon-person-font-family);
}
    .demi-icon-pets:before {
    content: var(--demi-icon-pets);
     font-family: var(--demi-icon-pets-font-family);
}
    .demi-icon-pinterest:before {
    content: var(--demi-icon-pinterest);
     font-family: var(--demi-icon-pinterest-font-family);
}
    .demi-icon-play-circle-fill:before {
    content: var(--demi-icon-play-circle-fill);
     font-family: var(--demi-icon-play-circle-fill-font-family);
}
    .demi-icon-play-circle:before {
    content: var(--demi-icon-play-circle);
     font-family: var(--demi-icon-play-circle-font-family);
}
    .demi-icon-plus:before {
    content: var(--demi-icon-plus);
     font-family: var(--demi-icon-plus-font-family);
}
    .demi-icon-pool:before {
    content: var(--demi-icon-pool);
     font-family: var(--demi-icon-pool-font-family);
}
    .demi-icon-remove-circle:before {
    content: var(--demi-icon-remove-circle);
     font-family: var(--demi-icon-remove-circle-font-family);
}
    .demi-icon-restaurant:before {
    content: var(--demi-icon-restaurant);
     font-family: var(--demi-icon-restaurant-font-family);
}
    .demi-icon-search:before {
    content: var(--demi-icon-search);
     font-family: var(--demi-icon-search-font-family);
}
    .demi-icon-share:before {
    content: var(--demi-icon-share);
     font-family: var(--demi-icon-share-font-family);
}
    .demi-icon-sort-asc:before {
    content: var(--demi-icon-sort-asc);
     font-family: var(--demi-icon-sort-asc-font-family);
}
    .demi-icon-sort-desc:before {
    content: var(--demi-icon-sort-desc);
     font-family: var(--demi-icon-sort-desc-font-family);
}
    .demi-icon-sort:before {
    content: var(--demi-icon-sort);
     font-family: var(--demi-icon-sort-font-family);
}
    .demi-icon-star:before {
    content: var(--demi-icon-star);
     font-family: var(--demi-icon-star-font-family);
}
    .demi-icon-target:before {
    content: var(--demi-icon-target);
     font-family: var(--demi-icon-target-font-family);
}
    .demi-icon-tel:before {
    content: var(--demi-icon-tel);
     font-family: var(--demi-icon-tel-font-family);
}
    .demi-icon-thumb-up:before {
    content: var(--demi-icon-thumb-up);
     font-family: var(--demi-icon-thumb-up-font-family);
}
    .demi-icon-ticket-cta:before {
    content: var(--demi-icon-ticket-cta);
     font-family: var(--demi-icon-ticket-cta-font-family);
}
    .demi-icon-tractor:before {
    content: var(--demi-icon-tractor);
     font-family: var(--demi-icon-tractor-font-family);
}
    .demi-icon-tumbler:before {
    content: var(--demi-icon-tumbler);
     font-family: var(--demi-icon-tumbler-font-family);
}
    .demi-icon-twitter:before {
    content: var(--demi-icon-twitter);
     font-family: var(--demi-icon-twitter-font-family);
}
    .demi-icon-vimeo:before {
    content: var(--demi-icon-vimeo);
     font-family: var(--demi-icon-vimeo-font-family);
}
    .demi-icon-visibility-off:before {
    content: var(--demi-icon-visibility-off);
     font-family: var(--demi-icon-visibility-off-font-family);
}
    .demi-icon-visibility:before {
    content: var(--demi-icon-visibility);
     font-family: var(--demi-icon-visibility-font-family);
}
    .demi-icon-warning:before {
    content: var(--demi-icon-warning);
     font-family: var(--demi-icon-warning-font-family);
}
    .demi-icon-wellness:before {
    content: var(--demi-icon-wellness);
     font-family: var(--demi-icon-wellness-font-family);
}
    .demi-icon-youtube:before {
    content: var(--demi-icon-youtube);
     font-family: var(--demi-icon-youtube-font-family);
}
    